import {
  DISTRICT_LOADING,
  GET_DISTRICT,
  GET_DISTRICT_SUCCESS,
  GET_DISTRICT_FAIL,
  ADD_DISTRICT_SUCCESS,
  ADD_DISTRICT_FAIL,
  DELETE_DISTRICT_SUCCESS,
  DELETE_DISTRICT_FAIL,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DISTRICT_FAIL
} from "./actionType";

const INIT_STATE = {
  districts: [],
  error: {},
};

const District = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISTRICT_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DISTRICT:
          return {
            ...state,
            districts: action.payload.data,
            isDistrictCreated: false,
            isDistrictSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_DISTRICT_FAIL:
      switch (action.payload.actionType) {
        case GET_DISTRICT_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDistrictCreated: false,
            isDistrictSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DISTRICT: {
      return {
        ...state,
        isDistrictCreated: false,
        loading: true,
      };
    }

    case DISTRICT_LOADING: {
      return {
        ...state,
        isDistrictCreated: false,
        loading: true,
      };
    }

    case ADD_DISTRICT_SUCCESS:
      return {
        ...state,
        isDistrictCreated: true,
        loading: false,
        districts: [...state.districts, action.payload.data],
      };

    case ADD_DISTRICT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DISTRICT_SUCCESS:
      return {
        ...state,
        loading: false,
        districts: state.districts.filter(
          district => district.id.toString() !== action.payload.district.id.toString()
        ),
      };

    case DELETE_DISTRICT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DISTRICT_SUCCESS:
      return {
        ...state,
        loading: false,
        districts: state.districts.map(district =>
          district.id.toString() === action.payload.data.id.toString() ? { ...district, ...action.payload.data } : district
        ),
      };

    case UPDATE_DISTRICT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default District;