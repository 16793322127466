import {
  BANK_LOADING,
  GET_BANK,
  GET_BANK_SUCCESS,
  GET_BANK_FAIL,
  ADD_BANK_SUCCESS,
  ADD_BANK_FAIL,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAIL,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_FAIL
} from "./actionType";

const INIT_STATE = {
  banks: [],
  error: {},
};

const Bank = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANK_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BANK:
          return {
            ...state,
            banks: action.payload.data,
            isBankCreated: false,
            isBankSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_BANK_FAIL:
      switch (action.payload.actionType) {
        case GET_BANK_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isBankCreated: false,
            isBankSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_BANK: {
      return {
        ...state,
        isBankCreated: false,
        loading: true,
      };
    }

    case BANK_LOADING: {
      return {
        ...state,
        isBankCreated: false,
        loading: true,
      };
    }

    case ADD_BANK_SUCCESS:
      return {
        ...state,
        isBankCreated: true,
        loading: false,
        banks: [...state.banks, action.payload.data],
      };

    case ADD_BANK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: state.banks.filter(
          bank => bank.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BANK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: state.banks.map(bank =>
          bank.id.toString() === action.payload.data.id.toString() ? { ...bank, ...action.payload.data } : bank
        ),
      };

    case UPDATE_BANK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Bank;