import {
  ASSETLOCATION_LOADING,
  GET_ASSETLOCATION,
  GET_ASSETLOCATION_SUCCESS,
  GET_ASSETLOCATION_FAIL,
  ADD_ASSETLOCATION_SUCCESS,
  ADD_ASSETLOCATION_FAIL,
  DELETE_ASSETLOCATION_SUCCESS,
  DELETE_ASSETLOCATION_FAIL,
  UPDATE_ASSETLOCATION_SUCCESS,
  UPDATE_ASSETLOCATION_FAIL,
  ASSETLOCATION_RESET
} from "./actionType";

const INIT_STATE = {
  assetLocations: [],
  error: {},
};

const AssetLocation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETLOCATION_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ASSETLOCATION:
          return {
            ...state,
            assetLocations: action.payload.data,
            isAssetLocationCreated: false,
            isAssetLocationSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ASSETLOCATION_FAIL:
      switch (action.payload.actionType) {
        case GET_ASSETLOCATION_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAssetLocationCreated: false,
            isAssetLocationSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ASSETLOCATION: {
      return {
        ...state,
        isAssetLocationCreated: false,
        loading: true,
      };
    }

    case ASSETLOCATION_LOADING: {
      return {
        ...state,
        isAssetLocationCreated: false,
        loading: true,
      };
    }

    case ADD_ASSETLOCATION_SUCCESS:
      return {
        ...state,
        isAssetLocationCreated: true,
        loading: false,
        assetLocations: [...state.assetLocations, action.payload.data],
      };

    case ADD_ASSETLOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ASSETLOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        assetLocations: state.assetLocations.filter(
          assetLocation => assetLocation.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ASSETLOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ASSETLOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        assetLocations: state.assetLocations.map(assetLocation =>
          assetLocation.id.toString() === action.payload.data.id.toString() ? { ...assetLocation, ...action.payload.data } : assetLocation
        ),
      };

    case UPDATE_ASSETLOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ASSETLOCATION_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default AssetLocation;