import {
  GET_PAYMENT,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_FAIL,
  DELETE_PAYMENT,
  DELETE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAIL,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  ADD_NEW_PAYMENT,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_FAIL,
  PAYMENT_LOADING,
  PAYMENT_RESET,
} from "./actionType";

export const resetPaymentState = (actionType) => ({
  type: PAYMENT_RESET,
  payload: { actionType },
});

export const getPaymentSuccess = (actionType, data) => ({
  type: GET_PAYMENT_SUCCESS,
  payload: { actionType, data },
});

export const getPaymentFail = (actionType, error) => ({
  type: GET_PAYMENT_FAIL,
  payload: { actionType, error },
});

export const getPayment = data => ({
  type: GET_PAYMENT,
  payload: data,
});

export const getPaymentLoading = () => ({
  type: PAYMENT_LOADING
});

export const deletePayment = data => ({
  type: DELETE_PAYMENT,
  payload: data,
});

export const deletePaymentSuccess = data => ({
  type: DELETE_PAYMENT_SUCCESS,
  payload: data,
});

export const deletePaymentFail = error => ({
  type: DELETE_PAYMENT_FAIL,
  payload: error,
});

export const updatePayment = data => ({
  type: UPDATE_PAYMENT,
  payload: data,
});

export const updatePaymentFail = error => ({
  type: UPDATE_PAYMENT_FAIL,
  payload: error,
});

export const updatePaymentSuccess = data => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload: data,
});

export const addNewPayment = data => ({
  type: ADD_NEW_PAYMENT,
  payload: data,
});

export const addPaymentSuccess = data => ({
  type: ADD_PAYMENT_SUCCESS,
  payload: data,
});

export const addPaymentFail = error => ({
  type: ADD_PAYMENT_FAIL,
  payload: error,
});