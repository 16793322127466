import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_DISTRICT,
  DELETE_DISTRICT,
  UPDATE_DISTRICT,
  ADD_NEW_DISTRICT,
} from "./actionType";

import {
  getDistrictSuccess,
  getDistrictFail,
  deleteDistrictSuccess,
  deleteDistrictFail,
  updateDistrictSuccess,
  updateDistrictFail,
  addDistrictSuccess,
  addDistrictFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getDistrictApi,
  updateDistrictApi,
  deleteDistrictApi,
  addNewDistrictApi
} from "../../helpers/backend_helper";

function* getDistrict({ payload: district }) {
  try {
    const response = yield call(getDistrictApi, district);
    yield put(getDistrictSuccess(GET_DISTRICT, response.data));
  } catch (error) {
    yield put(getDistrictFail(GET_DISTRICT, error));
  }
}

function* onUpdateDistrict({ payload: district }) {
  try {
    const response = yield call(updateDistrictApi, district);
    yield put(updateDistrictSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateDistrictFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDistrict({ payload: district }) {
  try {
    const response = yield call(deleteDistrictApi, district);
    yield put(deleteDistrictSuccess({ district, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDistrictFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDistrict({ payload: district }) {
  try {
    const response = yield call(addNewDistrictApi, district);
    yield put(addDistrictSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDistrictFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetDistrict() {
  yield takeEvery(GET_DISTRICT, getDistrict);
}

export function* watchUpdateDistrict() {
  yield takeEvery(UPDATE_DISTRICT, onUpdateDistrict);
}

export function* watchDeleteDistrict() {
  yield takeEvery(DELETE_DISTRICT, onDeleteDistrict);
}

export function* watchAddNewDistrict() {
  yield takeEvery(ADD_NEW_DISTRICT, onAddNewDistrict);
}

function* districtSaga() {
  yield all([
    fork(watchGetDistrict),
    fork(watchDeleteDistrict),
    fork(watchUpdateDistrict),
    fork(watchAddNewDistrict)
  ]);
}

export default districtSaga;
