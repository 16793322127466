import {
  IDENTITY_LOADING,
  GET_IDENTITY,
  GET_IDENTITY_SUCCESS,
  GET_IDENTITY_FAIL,
  ADD_IDENTITY_SUCCESS,
  ADD_IDENTITY_FAIL,
  DELETE_IDENTITY_SUCCESS,
  DELETE_IDENTITY_FAIL,
  UPDATE_IDENTITY_SUCCESS,
  UPDATE_IDENTITY_FAIL
} from "./actionType";

const INIT_STATE = {
  identities: [],
  error: {},
};

const Identity = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IDENTITY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_IDENTITY:
          return {
            ...state,
            identities: action.payload.data,
            isIdentityCreated: false,
            isIdentitySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_IDENTITY_FAIL:
      switch (action.payload.actionType) {
        case GET_IDENTITY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isIdentityCreated: false,
            isIdentitySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_IDENTITY: {
      return {
        ...state,
        isIdentityCreated: false,
        loading: true,
      };
    }

    case IDENTITY_LOADING: {
      return {
        ...state,
        isIdentityCreated: false,
        loading: true,
      };
    }

    case ADD_IDENTITY_SUCCESS:
      return {
        ...state,
        isIdentityCreated: true,
        loading: false,
        identities: [...state.identities, action.payload.data],
      };

    case ADD_IDENTITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_IDENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        identities: state.identities.filter(
          identity => identity.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_IDENTITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_IDENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        identities: state.identities.map(identity =>
          identity.id.toString() === action.payload.data.id.toString() ? { ...identity, ...action.payload.data } : identity
        ),
      };

    case UPDATE_IDENTITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Identity;