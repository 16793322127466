import {
  PAYMENTMETHOD_LOADING,
  GET_PAYMENTMETHOD,
  GET_PAYMENTMETHOD_SUCCESS,
  GET_PAYMENTMETHOD_FAIL,
  ADD_PAYMENTMETHOD_SUCCESS,
  ADD_PAYMENTMETHOD_FAIL,
  DELETE_PAYMENTMETHOD_SUCCESS,
  DELETE_PAYMENTMETHOD_FAIL,
  UPDATE_PAYMENTMETHOD_SUCCESS,
  UPDATE_PAYMENTMETHOD_FAIL,
  PAYMENTMETHOD_RESET
} from "./actionType";

const INIT_STATE = {
  paymentMethods: [],
  error: {},
};

const PaymentMethod = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTMETHOD_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PAYMENTMETHOD:
          return {
            ...state,
            paymentMethods: action.payload.data,
            isPaymentMethodCreated: false,
            isPaymentMethodSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_PAYMENTMETHOD_FAIL:
      switch (action.payload.actionType) {
        case GET_PAYMENTMETHOD_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isPaymentMethodCreated: false,
            isPaymentMethodSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_PAYMENTMETHOD: {
      return {
        ...state,
        isPaymentMethodCreated: false,
        loading: true,
      };
    }

    case PAYMENTMETHOD_LOADING: {
      return {
        ...state,
        isPaymentMethodCreated: false,
        loading: true,
      };
    }

    case ADD_PAYMENTMETHOD_SUCCESS:
      return {
        ...state,
        isPaymentMethodCreated: true,
        loading: false,
        paymentMethods: [...state.paymentMethods, action.payload.data],
      };

    case ADD_PAYMENTMETHOD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PAYMENTMETHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        isPaymentMethodCreated: true,
        paymentMethods: state.paymentMethods.filter(
          paymentMethod => paymentMethod.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PAYMENTMETHOD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PAYMENTMETHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        isPaymentMethodCreated: true,
        paymentMethods: state.paymentMethods.map(paymentMethod =>
          paymentMethod.id.toString() === action.payload.data.id.toString() ? { ...paymentMethod, ...action.payload.data } : paymentMethod
        ),
      };

    case UPDATE_PAYMENTMETHOD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTMETHOD_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default PaymentMethod;