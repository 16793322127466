export const ASSETLOCATION_LOADING = "ASSETLOCATION_LOADING";
export const ASSETLOCATION_RESET = "ASSETLOCATION_RESET";
export const GET_ASSETLOCATION = "GET_ASSETLOCATION";
export const GET_ASSETLOCATION_SUCCESS = "GET_ASSETLOCATION_SUCCESS";
export const GET_ASSETLOCATION_FAIL = "GET_ASSETLOCATION_FAIL";
export const DELETE_ASSETLOCATION = "DELETE_ASSETLOCATION"
export const DELETE_ASSETLOCATION_SUCCESS = "DELETE_ASSETLOCATION_SUCCESS"
export const DELETE_ASSETLOCATION_FAIL = "DELETE_ASSETLOCATION_FAIL"
export const UPDATE_ASSETLOCATION = "UPDATE_ASSETLOCATION"
export const UPDATE_ASSETLOCATION_SUCCESS = "UPDATE_ASSETLOCATION_SUCCESS"
export const UPDATE_ASSETLOCATION_FAIL = "UPDATE_ASSETLOCATION_FAIL"
export const ADD_NEW_ASSETLOCATION = "ADD_NEW_ASSETLOCATION"
export const ADD_ASSETLOCATION_SUCCESS = "ADD_ASSETLOCATION_SUCCESS"
export const ADD_ASSETLOCATION_FAIL = "ADD_ASSETLOCATION_FAIL"