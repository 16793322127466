export const IDENTITY_LOADING = "IDENTITY_LOADING";
export const GET_IDENTITY = "GET_IDENTITY";
export const GET_IDENTITY_SUCCESS = "GET_IDENTITY_SUCCESS";
export const GET_IDENTITY_FAIL = "GET_IDENTITY_FAIL";
export const DELETE_IDENTITY = "DELETE_IDENTITY"
export const DELETE_IDENTITY_SUCCESS = "DELETE_IDENTITY_SUCCESS"
export const DELETE_IDENTITY_FAIL = "DELETE_IDENTITY_FAIL"
export const UPDATE_IDENTITY = "UPDATE_IDENTITY"
export const UPDATE_IDENTITY_SUCCESS = "UPDATE_IDENTITY_SUCCESS"
export const UPDATE_IDENTITY_FAIL = "UPDATE_IDENTITY_FAIL"
export const ADD_NEW_IDENTITY = "ADD_NEW_IDENTITY"
export const ADD_IDENTITY_SUCCESS = "ADD_IDENTITY_SUCCESS"
export const ADD_IDENTITY_FAIL = "ADD_IDENTITY_FAIL"