import {
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAIL,
  DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  ADD_NEW_BANNER,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAIL,
  BANNER_LOADING,
  BANNER_RESET,
} from "./actionType";

export const resetBannerState = (actionType) => ({
  type: BANNER_RESET,
  payload: { actionType },
});

export const getBannerSuccess = (actionType, data) => ({
  type: GET_BANNER_SUCCESS,
  payload: { actionType, data },
});

export const getBannerFail = (actionType, error) => ({
  type: GET_BANNER_FAIL,
  payload: { actionType, error },
});

export const getBanner = data => ({
  type: GET_BANNER,
  payload: data,
});

export const getBannerLoading = () => ({
  type: BANNER_LOADING
});

export const deleteBanner = data => ({
  type: DELETE_BANNER,
  payload: data,
});

export const deleteBannerSuccess = data => ({
  type: DELETE_BANNER_SUCCESS,
  payload: data,
});

export const deleteBannerFail = error => ({
  type: DELETE_BANNER_FAIL,
  payload: error,
});

export const updateBanner = data => ({
  type: UPDATE_BANNER,
  payload: data,
});

export const updateBannerFail = error => ({
  type: UPDATE_BANNER_FAIL,
  payload: error,
});

export const updateBannerSuccess = data => ({
  type: UPDATE_BANNER_SUCCESS,
  payload: data,
});

export const addNewBanner = data => ({
  type: ADD_NEW_BANNER,
  payload: data,
});

export const addBannerSuccess = data => ({
  type: ADD_BANNER_SUCCESS,
  payload: data,
});

export const addBannerFail = error => ({
  type: ADD_BANNER_FAIL,
  payload: error,
});