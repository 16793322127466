export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const PAYMENT_RESET = "PAYMENT_RESET";
export const GET_PAYMENT = "GET_PAYMENT";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_FAIL = "GET_PAYMENT_FAIL";
export const DELETE_PAYMENT = "DELETE_PAYMENT"
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"
export const DELETE_PAYMENT_FAIL = "DELETE_PAYMENT_FAIL"
export const UPDATE_PAYMENT = "UPDATE_PAYMENT"
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL"
export const ADD_NEW_PAYMENT = "ADD_NEW_PAYMENT"
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS"
export const ADD_PAYMENT_FAIL = "ADD_PAYMENT_FAIL"