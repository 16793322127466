import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_PROVINCE,
  DELETE_PROVINCE,
  UPDATE_PROVINCE,
  ADD_NEW_PROVINCE,
} from "./actionType";

import {
  getProvinceSuccess,
  getProvinceFail,
  deleteProvinceSuccess,
  deleteProvinceFail,
  updateProvinceSuccess,
  updateProvinceFail,
  addProvinceSuccess,
  addProvinceFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getProvinceApi,
  updateProvinceApi,
  deleteProvinceApi,
  addNewProvinceApi
} from "../../helpers/backend_helper";

function* getProvince({ payload: province }) {
  try {
    const response = yield call(getProvinceApi, province);
    yield put(getProvinceSuccess(GET_PROVINCE, response.data));
  } catch (error) {
    yield put(getProvinceFail(GET_PROVINCE, error));
  }
}

function* onUpdateProvince({ payload: province }) {
  try {
    const response = yield call(updateProvinceApi, province);
    yield put(updateProvinceSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateProvinceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteProvince({ payload: province }) {
  try {
    const response = yield call(deleteProvinceApi, province);
    yield put(deleteProvinceSuccess({ province, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteProvinceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewProvince({ payload: province }) {
  try {
    const response = yield call(addNewProvinceApi, province);
    yield put(addProvinceSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addProvinceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetProvince() {
  yield takeEvery(GET_PROVINCE, getProvince);
}

export function* watchUpdateProvince() {
  yield takeEvery(UPDATE_PROVINCE, onUpdateProvince);
}

export function* watchDeleteProvince() {
  yield takeEvery(DELETE_PROVINCE, onDeleteProvince);
}

export function* watchAddNewProvince() {
  yield takeEvery(ADD_NEW_PROVINCE, onAddNewProvince);
}

function* provinceSaga() {
  yield all([
    fork(watchGetProvince),
    fork(watchDeleteProvince),
    fork(watchUpdateProvince),
    fork(watchAddNewProvince)
  ]);
}

export default provinceSaga;
