import {
  MAIL_LOADING,
  GET_MAIL,
  GET_MAIL_SUCCESS,
  GET_MAIL_FAIL,
  ADD_MAIL_SUCCESS,
  ADD_MAIL_FAIL,
  DELETE_MAIL_SUCCESS,
  DELETE_MAIL_FAIL,
  UPDATE_MAIL_SUCCESS,
  UPDATE_MAIL_FAIL,
  MAIL_RESET,
  MAIL_RESET_CREATE
} from "./actionType";

const INIT_STATE = {
  mails: [],
  mailAdded: {},
  error: {},
};

const Mail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAIL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MAIL:
          return {
            ...state,
            mails: action.payload.data,
            isMailCreated: false,
            isMailSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_MAIL_FAIL:
      switch (action.payload.actionType) {
        case GET_MAIL_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isMailCreated: false,
            isMailSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_MAIL: {
      return {
        ...state,
        isMailCreated: false,
        loading: true,
      };
    }

    case MAIL_LOADING: {
      return {
        ...state,
        isMailCreated: false,
        loading: true,
      };
    }

    case ADD_MAIL_SUCCESS:
      return {
        ...state,
        isMailCreated: true,
        loading: false,
        mails: [...state.mails, action.payload.data],
        mailAdded: action.payload.data,
      };

    case ADD_MAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        mails: state.mails.filter(
          mail => mail.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_MAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        mails: state.mails.map(mail =>
          mail.id.toString() === action.payload.data.id.toString() ? { ...mail, ...action.payload.data } : mail
        ),
      };

    case UPDATE_MAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MAIL_RESET_CREATE:
      return {
        ...state,
        isMailCreated: false,
      };

    case MAIL_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Mail;