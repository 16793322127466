import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ASSETCLASS,
  DELETE_ASSETCLASS,
  UPDATE_ASSETCLASS,
  ADD_NEW_ASSETCLASS,
} from "./actionType";

import {
  getAssetClassSuccess,
  getAssetClassFail,
  deleteAssetClassSuccess,
  deleteAssetClassFail,
  updateAssetClassSuccess,
  updateAssetClassFail,
  addAssetClassSuccess,
  addAssetClassFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getAssetClassApi,
  updateAssetClassApi,
  deleteAssetClassApi,
  addNewAssetClassApi
} from "../../helpers/backend_helper";

function* getAssetClass({ payload: assetClass }) {
  try {
    const response = yield call(getAssetClassApi, assetClass);
    yield put(getAssetClassSuccess(GET_ASSETCLASS, response.data));
  } catch (error) {
    yield put(getAssetClassFail(GET_ASSETCLASS, error));
  }
}

function* onUpdateAssetClass({ payload: assetClass }) {
  try {
    const response = yield call(updateAssetClassApi, assetClass);
    yield put(updateAssetClassSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateAssetClassFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAssetClass({ payload: assetClass }) {
  try {
    const response = yield call(deleteAssetClassApi, assetClass);
    yield put(deleteAssetClassSuccess({ assetClass, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAssetClassFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAssetClass({ payload: assetClass }) {
  try {
    const response = yield call(addNewAssetClassApi, assetClass);
    yield put(addAssetClassSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAssetClassFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAssetClass() {
  yield takeEvery(GET_ASSETCLASS, getAssetClass);
}

export function* watchUpdateAssetClass() {
  yield takeEvery(UPDATE_ASSETCLASS, onUpdateAssetClass);
}

export function* watchDeleteAssetClass() {
  yield takeEvery(DELETE_ASSETCLASS, onDeleteAssetClass);
}

export function* watchAddNewAssetClass() {
  yield takeEvery(ADD_NEW_ASSETCLASS, onAddNewAssetClass);
}

function* assetClassSaga() {
  yield all([
    fork(watchGetAssetClass),
    fork(watchDeleteAssetClass),
    fork(watchUpdateAssetClass),
    fork(watchAddNewAssetClass)
  ]);
}

export default assetClassSaga;
