export const ASSETGROUP_LOADING = "ASSETGROUP_LOADING";
export const GET_ASSETGROUP = "GET_ASSETGROUP";
export const GET_ASSETGROUP_SUCCESS = "GET_ASSETGROUP_SUCCESS";
export const GET_ASSETGROUP_FAIL = "GET_ASSETGROUP_FAIL";
export const DELETE_ASSETGROUP = "DELETE_ASSETGROUP"
export const DELETE_ASSETGROUP_SUCCESS = "DELETE_ASSETGROUP_SUCCESS"
export const DELETE_ASSETGROUP_FAIL = "DELETE_ASSETGROUP_FAIL"
export const UPDATE_ASSETGROUP = "UPDATE_ASSETGROUP"
export const UPDATE_ASSETGROUP_SUCCESS = "UPDATE_ASSETGROUP_SUCCESS"
export const UPDATE_ASSETGROUP_FAIL = "UPDATE_ASSETGROUP_FAIL"
export const ADD_NEW_ASSETGROUP = "ADD_NEW_ASSETGROUP"
export const ADD_ASSETGROUP_SUCCESS = "ADD_ASSETGROUP_SUCCESS"
export const ADD_ASSETGROUP_FAIL = "ADD_ASSETGROUP_FAIL"