import {
  GET_IDENTITYTYPE,
  GET_IDENTITYTYPE_SUCCESS,
  GET_IDENTITYTYPE_FAIL,
  DELETE_IDENTITYTYPE,
  DELETE_IDENTITYTYPE_SUCCESS,
  DELETE_IDENTITYTYPE_FAIL,
  UPDATE_IDENTITYTYPE,
  UPDATE_IDENTITYTYPE_SUCCESS,
  UPDATE_IDENTITYTYPE_FAIL,
  ADD_NEW_IDENTITYTYPE,
  ADD_IDENTITYTYPE_SUCCESS,
  ADD_IDENTITYTYPE_FAIL,
  IDENTITYTYPE_LOADING,
  IDENTITYTYPE_RESET,
} from "./actionType";

export const resetIdentityTypeState = (actionType) => ({
  type: IDENTITYTYPE_RESET,
  payload: { actionType },
});

export const getIdentityTypeSuccess = (actionType, data) => ({
  type: GET_IDENTITYTYPE_SUCCESS,
  payload: { actionType, data },
});

export const getIdentityTypeFail = (actionType, error) => ({
  type: GET_IDENTITYTYPE_FAIL,
  payload: { actionType, error },
});

export const getIdentityType = data => ({
  type: GET_IDENTITYTYPE,
  payload: data,
});

export const getIdentityTypeLoading = () => ({
  type: IDENTITYTYPE_LOADING
});

export const deleteIdentityType = data => ({
  type: DELETE_IDENTITYTYPE,
  payload: data,
});

export const deleteIdentityTypeSuccess = data => ({
  type: DELETE_IDENTITYTYPE_SUCCESS,
  payload: data,
});

export const deleteIdentityTypeFail = error => ({
  type: DELETE_IDENTITYTYPE_FAIL,
  payload: error,
});

export const updateIdentityType = data => ({
  type: UPDATE_IDENTITYTYPE,
  payload: data,
});

export const updateIdentityTypeFail = error => ({
  type: UPDATE_IDENTITYTYPE_FAIL,
  payload: error,
});

export const updateIdentityTypeSuccess = data => ({
  type: UPDATE_IDENTITYTYPE_SUCCESS,
  payload: data,
});

export const addNewIdentityType = data => ({
  type: ADD_NEW_IDENTITYTYPE,
  payload: data,
});

export const addIdentityTypeSuccess = data => ({
  type: ADD_IDENTITYTYPE_SUCCESS,
  payload: data,
});

export const addIdentityTypeFail = error => ({
  type: ADD_IDENTITYTYPE_FAIL,
  payload: error,
});