import {
  GET_BANK,
  GET_BANK_SUCCESS,
  GET_BANK_FAIL,
  DELETE_BANK,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAIL,
  UPDATE_BANK,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_FAIL,
  ADD_NEW_BANK,
  ADD_BANK_SUCCESS,
  ADD_BANK_FAIL,
  BANK_LOADING,
} from "./actionType";

export const getBankSuccess = (actionType, data) => ({
  type: GET_BANK_SUCCESS,
  payload: { actionType, data },
});

export const getBankFail = (actionType, error) => ({
  type: GET_BANK_FAIL,
  payload: { actionType, error },
});

export const getBank = data => ({
  type: GET_BANK,
  payload: data,
});

export const getBankLoading = () => ({
  type: BANK_LOADING
});

export const deleteBank = data => ({
  type: DELETE_BANK,
  payload: data,
});

export const deleteBankSuccess = data => ({
  type: DELETE_BANK_SUCCESS,
  payload: data,
});

export const deleteBankFail = error => ({
  type: DELETE_BANK_FAIL,
  payload: error,
});

export const updateBank = data => ({
  type: UPDATE_BANK,
  payload: data,
});

export const updateBankFail = error => ({
  type: UPDATE_BANK_FAIL,
  payload: error,
});

export const updateBankSuccess = data => ({
  type: UPDATE_BANK_SUCCESS,
  payload: data,
});

export const addNewBank = data => ({
  type: ADD_NEW_BANK,
  payload: data,
});

export const addBankSuccess = data => ({
  type: ADD_BANK_SUCCESS,
  payload: data,
});

export const addBankFail = error => ({
  type: ADD_BANK_FAIL,
  payload: error,
});