import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ASSETTAG,
  DELETE_ASSETTAG,
  UPDATE_ASSETTAG,
  ADD_NEW_ASSETTAG,
} from "./actionType";

import {
  getAssetTagSuccess,
  getAssetTagFail,
  deleteAssetTagSuccess,
  deleteAssetTagFail,
  updateAssetTagSuccess,
  updateAssetTagFail,
  addAssetTagSuccess,
  addAssetTagFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getAssetTagApi,
  updateAssetTagApi,
  deleteAssetTagApi,
  addNewAssetTagApi
} from "../../helpers/backend_helper";

function* getAssetTag({ payload: assetTag }) {
  try {
    const response = yield call(getAssetTagApi, assetTag);
    yield put(getAssetTagSuccess(GET_ASSETTAG, response.data));
  } catch (error) {
    yield put(getAssetTagFail(GET_ASSETTAG, error));
  }
}

function* onUpdateAssetTag({ payload: assetTag }) {
  try {
    const response = yield call(updateAssetTagApi, assetTag);
    yield put(updateAssetTagSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateAssetTagFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAssetTag({ payload: assetTag }) {
  try {
    const response = yield call(deleteAssetTagApi, assetTag);
    yield put(deleteAssetTagSuccess({ assetTag, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAssetTagFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAssetTag({ payload: assetTag }) {
  try {
    const response = yield call(addNewAssetTagApi, assetTag);
    yield put(addAssetTagSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAssetTagFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAssetTag() {
  yield takeEvery(GET_ASSETTAG, getAssetTag);
}

export function* watchUpdateAssetTag() {
  yield takeEvery(UPDATE_ASSETTAG, onUpdateAssetTag);
}

export function* watchDeleteAssetTag() {
  yield takeEvery(DELETE_ASSETTAG, onDeleteAssetTag);
}

export function* watchAddNewAssetTag() {
  yield takeEvery(ADD_NEW_ASSETTAG, onAddNewAssetTag);
}

function* assetTagSaga() {
  yield all([
    fork(watchGetAssetTag),
    fork(watchDeleteAssetTag),
    fork(watchUpdateAssetTag),
    fork(watchAddNewAssetTag)
  ]);
}

export default assetTagSaga;
