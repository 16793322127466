import { APIClient } from "./api_helper";
const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// profile
export const updateImageProfile = (data) => api.patchImg("/profiles", data);

export const postLogin = (data) => api.post("/auth", data);
export const postRegister = (data) => api.post("/register", data);
export const postJwtForgetPwd = (data) => api.post("/jwt-forget-pwd", data);
export const postJwtProfile = (data) => api.post("/jwt-forget-pwd", data);
export const refreshToken = () => api.get("/refresh");


export const getReportApi = (report, path) => api.get("/reports", report, path);

export const getMailApi = (data) => api.get("/mails", data);
export const approveMailApi = (data) => api.put("/mails", data);
export const addNewMailApi = (data) => api.postImg("/mails", data);
export const updateMailApi = (data) => api.patchImg("/mails", data);
export const deleteMailApi = (data) => api.delete("/mails", data);

export const getClientApi = (data) => api.get("/clients", data);
export const addNewClientApi = (data) => api.postImg("/clients", data);
export const updateClientApi = (data) => api.patchImg("/clients", data);
export const deleteClientApi = (data) => api.delete("/clients", data);

export const getUnitApi = (data) => api.get("/units", data);
export const addNewUnitApi = (data) => api.post("/units", data);
export const updateUnitApi = (data) => api.patch("/units", data);
export const deleteUnitApi = (data) => api.delete("/units", data);


export const getRoomApi = (data) => api.get("/rooms", data);
export const addNewRoomApi = (data) => api.post("/rooms", data);
export const updateRoomApi = (data) => api.patch("/rooms", data);
export const deleteRoomApi = (data) => api.delete("/rooms", data);

export const getBookApi = (data) => api.get("/books", data);
export const addNewBookApi = (data) => api.post("/books", data);
export const updateBookApi = (data) => api.patch("/books", data);
export const deleteBookApi = (data) => api.delete("/books", data);

export const getAssetLocationApi = (data) => api.get("/asset-locations", data);
export const addNewAssetLocationApi = (data) => api.post("/asset-locations", data);
export const updateAssetLocationApi = (data) => api.patch("/asset-locations", data);
export const deleteAssetLocationApi = (data) => api.delete("/asset-locations", data);

export const getProvinceApi = (data) => api.get("/provinces", data);
export const addNewProvinceApi = (data) => api.post("/provinces", data);
export const updateProvinceApi = (data) => api.patch("/provinces", data);
export const deleteProvinceApi = (data) => api.delete("/provinces", data);

export const getCityApi = (data) => api.get("/cities", data);
export const addNewCityApi = (data) => api.post("/cities", data);
export const updateCityApi = (data) => api.patch("/cities", data);
export const deleteCityApi = (data) => api.delete("/cities", data);

export const getDistrictApi = (data) => api.get("/districts", data);
export const addNewDistrictApi = (data) => api.post("/districts", data);
export const updateDistrictApi = (data) => api.patch("/districts", data);
export const deleteDistrictApi = (data) => api.delete("/districts", data);

export const getVillageApi = (data) => api.get("/villages", data);
export const addNewVillageApi = (data) => api.post("/villages", data);
export const updateVillageApi = (data) => api.patch("/villages", data);
export const deleteVillageApi = (data) => api.delete("/villages", data);

export const getAddressApi = (data) => api.get("/addresses", data);
export const addNewAddressApi = (data) => api.post("/addresses", data);
export const updateAddressApi = (data) => api.patch("/addresses", data);
export const deleteAddressApi = (data) => api.delete("/addresses", data);

export const getIdentityApi = (data) => api.get("/identities", data);
export const addNewIdentityApi = (data) => api.post("/identities", data);
export const updateIdentityApi = (data) => api.patch("/identities", data);
export const deleteIdentityApi = (data) => api.delete("/identities", data);

export const getIdentityTypeApi = (data) => api.get("/identity-types", data);
export const addNewIdentityTypeApi = (data) => api.post("/identity-types", data);
export const updateIdentityTypeApi = (data) => api.patch("/identity-types", data);
export const deleteIdentityTypeApi = (data) => api.delete("/identity-types", data);

export const getMaritalApi = (data) => api.get("/marital-status", data);
export const addNewMaritalApi = (data) => api.post("/marital-status", data);
export const updateMaritalApi = (data) => api.patch("/marital-status", data);
export const deleteMaritalApi = (data) => api.delete("/marital-status", data);

export const getMaritalTaxApi = (data) => api.get("/marital-taxes", data);
export const addNewMaritalTaxApi = (data) => api.post("/marital-taxes", data);
export const updateMaritalTaxApi = (data) => api.patch("/marital-taxes", data);
export const deleteMaritalTaxApi = (data) => api.delete("/marital-taxes", data);
export const approveMaritalTaxApi = (data) => api.put("/marital-taxes", data);

export const getDisposalApi = (data) => api.get("/disposal", data);
export const addNewDisposalApi = (data) => api.post("/disposal", data);
export const updateDisposalApi = (data) => api.patch("/disposal", data);
export const deleteDisposalApi = (data) => api.delete("/disposal", data);
export const approveDisposalApi = (data) => api.put("/disposal", data);

export const getDepreciationApi = (data) => api.get("/depreciationl", data);
export const addNewDepreciationApi = (data) => api.post("/depreciationl", data);
export const updateDepreciationApi = (data) => api.patch("/depreciationl", data);
export const deleteDepreciationApi = (data) => api.delete("/depreciationl", data);
export const approveDepreciationApi = (data) => api.put("/depreciationl", data);

export const getReligionApi = (data) => api.get("/religions", data);
export const addNewReligionApi = (data) => api.post("/religions", data);
export const updateReligionApi = (data) => api.patch("/religions", data);
export const deleteReligionApi = (data) => api.delete("/religions", data);

export const getBankApi = (data) => api.get("/banks", data);
export const addNewBankApi = (data) => api.post("/banks", data);
export const updateBankApi = (data) => api.patch("/banks", data);
export const deleteBankApi = (data) => api.delete("/banks", data);

export const getBankDataApi = (data) => api.get("/bankdatas", data);
export const addNewBankDataApi = (data) => api.post("/bankdatas", data);
export const updateBankDataApi = (data) => api.patch("/bankdatas", data);
export const deleteBankDataApi = (data) => api.delete("/bankdatas", data);

export const getFolderApi = (data) => api.get("/storage-folders", data);
export const addNewFolderApi = (data) => api.post("/storage-folders", data);
export const updateFolderApi = (data) => api.patch("/storage-folders", data);
export const deleteFolderApi = (data) => api.delete("/storage-folders", data);

export const getFileApi = (data) => api.get("/storage-files", data);
export const addNewFileApi = (data) => api.postImg("/storage-files", data);
export const updateFileApi = (data) => api.patchImg("/storage-files", data);
export const deleteFileApi = (data) => api.delete("/storage-files", data);

export const getEducationApi = (data) => api.get("/educations", data);
export const addNewEducationApi = (data) => api.post("/educations", data);
export const updateEducationApi = (data) => api.patch("/educations", data);
export const deleteEducationApi = (data) => api.delete("/educations", data);

export const getEducationLevelApi = (data) => api.get("/education-levels", data);
export const addNewEducationLevelApi = (data) => api.post("/education-levels", data);
export const updateEducationLevelApi = (data) => api.patch("/education-levels", data);
export const deleteEducationLevelApi = (data) => api.delete("/education-levels", data);

export const getFamilyContactApi = (data) => api.get("/family-contacts", data);
export const addNewFamilyContactApi = (data) => api.post("/family-contacts", data);
export const updateFamilyContactApi = (data) => api.patch("/family-contacts", data);
export const deleteFamilyContactApi = (data) => api.delete("/family-contacts", data);

export const getFamilyContactTypeApi = (data) => api.get("/family-contact-types", data);
export const addNewFamilyContactTypeApi = (data) => api.post("/family-contact-types", data);
export const updateFamilyContactTypeApi = (data) => api.patch("/family-contact-types", data);
export const deleteFamilyContactTypeApi = (data) => api.delete("/family-contact-types", data);

export const getEvents = () => api.get("/permissions");
export const getCategories = () => api.get("/permission-categories");
export const getUpCommingEvent = () => api.get("/events");
export const addNewEvent = (event) => api.postImg("/permissions", event);
export const approveEvent = (data) => api.put("/permissions", data);
export const updateEvent = (event) => api.patchImg("/permissions", event);
export const deleteEvent = (event) => api.delete("/permissions", event);

//Human Resources
export const getOneUserApi = (id) => api.getOne("/users", id);
export const getUserApi = (data) => api.get("/users", data);
export const addNewUserApi = (data) => api.postImg("/users", data);
export const approveUserApi = (data) => api.put("/users", data);
export const updateUserApi = (data) => api.patchImg("/users", data);
export const deleteUserApi = (data) => api.delete("/users", data);

export const getOneParentApi = (id) => api.getOne("/parents", id);
export const getParentApi = (data) => api.get("/parents", data);
export const addNewParentApi = (data) => api.postImg("/parents", data);
export const approveParentApi = (data) => api.put("/parents", data);
export const updateParentApi = (data) => api.patchImg("/parents", data);
export const deleteParentApi = (data) => api.delete("/parents", data);

export const getUserFaceApi = (data) => api.get("/user-faces", data);
export const addNewUserFaceApi = (data) => api.postImg("/user-faces", data);
export const approveUserFaceApi = (data) => api.put("/user-faces", data);
export const updateUserFaceApi = (data) => api.patchImg("/user-faces", data);
export const deleteUserFaceApi = (data) => api.delete("/user-faces", data);

export const getDepartmentApi = (data) => api.get("/departments", data);
export const addNewDepartmentApi = (data) => api.post("/departments", data);
export const updateDepartmentApi = (data) => api.patch("/departments", data);
export const deleteDepartmentApi = (data) => api.delete("/departments", data);

export const getSectionApi = (data) => api.get("/sections", data);
export const addNewSectionApi = (data) => api.post("/sections", data);
export const updateSectionApi = (data) => api.patch("/sections", data);
export const deleteSectionApi = (data) => api.delete("/sections", data);

export const getPositionApi = (data, path) => api.get("/positions", data, path);
export const addNewPositionApi = (data) => api.post("/positions", data);
export const updatePositionApi = (data) => api.patch("/positions", data);
export const deletePositionApi = (data) => api.delete("/positions", data);

export const getPermissionCategoryApi = (data) => api.get("/permission-categories", data);
export const addNewPermissionCategoryApi = (data) => api.post("/permission-categories", data);
export const updatePermissionCategoryApi = (data) => api.patch("/permission-categories", data);
export const deletePermissionCategoryApi = (data) => api.delete("/permission-categories", data);

export const getUserContractApi = (data) => api.get("/user-contracts", data);
export const approveUserContractApi = (data) => api.put("/user-contracts", data);
export const addNewUserContractApi = (data) => api.post("/user-contracts", data);
export const updateUserContractApi = (data) => api.patch("/user-contracts", data);
export const deleteUserContractApi = (data) => api.delete("/user-contracts", data);

export const getUserContractTypeApi = (data) => api.get("/user-contract-types", data);
export const addNewUserContractTypeApi = (data) => api.post("/user-contract-types", data);
export const updateUserContractTypeApi = (data) => api.patch("/user-contract-types", data);
export const deleteUserContractTypeApi = (data) => api.delete("/user-contract-types", data);

export const getUserLocationApi = (data) => api.get("/user-locations", data);
export const approveUserLocationApi = (data) => api.put("/user-locations", data);
export const addNewUserLocationApi = (data) => api.post("/user-locations", data);
export const updateUserLocationApi = (data) => api.patch("/user-locations", data);
export const deleteUserLocationApi = (data) => api.delete("/user-locations", data);

export const getUserWarningApi = (data) => api.get("/user-warnings", data);
export const approveUserWarningApi = (data) => api.put("/user-warnings", data);
export const addNewUserWarningApi = (data) => api.post("/user-warnings", data);
export const updateUserWarningApi = (data) => api.patch("/user-warnings", data);
export const deleteUserWarningApi = (data) => api.delete("/user-warnings", data);

export const getPositionChangeApi = (data) => api.get("/position-changes", data);
export const approvePositionChangeApi = (data) => api.put("/position-changes", data);
export const addNewPositionChangeApi = (data) => api.post("/position-changes", data);
export const updatePositionChangeApi = (data) => api.patch("/position-changes", data);
export const deletePositionChangeApi = (data) => api.delete("/position-changes", data);

export const getTerminationApi = (data) => api.get("/terminations", data);
export const approveTerminationApi = (data) => api.put("/terminations", data);
export const addNewTerminationApi = (data) => api.post("/terminations", data);
export const updateTerminationApi = (data) => api.patch("/terminations", data);
export const deleteTerminationApi = (data) => api.delete("/terminations", data);

export const getPayrateApi = (data) => api.get("/pay-rates", data);
export const approvePayrateApi = (data) => api.put("/pay-rates", data);
export const addNewPayrateApi = (data) => api.post("/pay-rates", data);
export const updatePayrateApi = (data) => api.patch("/pay-rates", data);
export const deletePayrateApi = (data) => api.delete("/pay-rates", data);

export const getPayrollApi = (data) => api.get("/payrolls", data);
export const addNewPayrollApi = (data) => api.post("/payrolls", data);
export const updatePayrollApi = (data) => api.patch("/payrolls", data);
export const deletePayrollApi = (data) => api.delete("/payrolls", data);

export const getPayrollSalaryApi = (data) => api.get("/payrolls", data);
export const addNewPayrollSalaryApi = (data) => api.post("/payrolls", data);
export const approvePayrollSalaryApi = (data) => api.put("/payrolls", data);
export const updatePayrollSalaryApi = (data) => api.patch("/payrolls", data);
export const deletePayrollSalaryApi = (data) => api.delete("/payrolls", data);

export const getAttendanceApi = (data) => api.get("/attendances", data);
export const approveAttendanceApi = (data) => api.put("/attendances", data);
export const addNewAttendanceApi = (data) => api.post("/attendances", data);
export const updateAttendanceApi = (data) => api.patch("/attendances", data);
export const deleteAttendanceApi = (data) => api.delete("/attendances", data);

export const getAttEventApi = (data) => api.get("/attendance-events", data);
export const approveAttEventApi = (data) => api.put("/attendance-events", data);
export const addNewAttEventApi = (data) => api.post("/attendance-events", data);
export const updateAttEventApi = (data) => api.patch("/attendance-events", data);
export const deleteAttEventApi = (data) => api.delete("/attendance-events", data);

export const getAttScheduleApi = (data) => api.get("/attendance-schedules", data);
export const approveAttScheduleApi = (data) => api.put("/attendance-schedules", data);
export const addNewAttScheduleApi = (data) => api.post("/attendance-schedules", data);
export const updateAttScheduleApi = (data) => api.patch("/attendance-schedules", data);
export const deleteAttScheduleApi = (data) => api.delete("/attendance-schedules", data);

export const getClassScheduleApi = (data) => api.get("/class-schedules", data);
export const approveClassScheduleApi = (data) => api.put("/class-schedules", data);
export const addNewClassScheduleApi = (data) => api.post("/class-schedules", data);
export const updateClassScheduleApi = (data) => api.patch("/class-schedules", data);
export const deleteClassScheduleApi = (data) => api.delete("/class-schedules", data);

export const getPaymentComponentApi = (data) => api.get("/payment-components", data);
export const approvePaymentComponentApi = (data) => api.put("/payment-components", data);
export const addNewPaymentComponentApi = (data) => api.post("/payment-components", data);
export const updatePaymentComponentApi = (data) => api.patch("/payment-components", data);
export const deletePaymentComponentApi = (data) => api.delete("/payment-components", data);

export const getPaymentAllocationApi = (data) => api.get("/payment-allocations", data);
export const approvePaymentAllocationApi = (data) => api.put("/payment-allocations", data);
export const addNewPaymentAllocationApi = (data) => api.post("/payment-allocations", data);
export const updatePaymentAllocationApi = (data) => api.patch("/payment-allocations", data);
export const deletePaymentAllocationApi = (data) => api.delete("/payment-allocations", data);

export const getClassScheduleDetailApi = (data) => api.get("/class-schedule-details", data);
export const approveClassScheduleDetailApi = (data) => api.put("/class-schedule-details", data);
export const addNewClassScheduleDetailApi = (data) => api.post("/class-schedule-details", data);
export const updateClassScheduleDetailApi = (data) => api.patch("/class-schedule-details", data);
export const deleteClassScheduleDetailApi = (data) => api.delete("/class-schedule-details", data);

export const getAttScheduleDetailApi = (data) => api.get("/attendance-schedule-details", data);
export const approveAttScheduleDetailApi = (data) => api.put("/attendance-schedule-details", data);
export const addNewAttScheduleDetailApi = (data) => api.post("/attendance-schedule-details", data);
export const updateAttScheduleDetailApi = (data) => api.patch("/attendance-schedule-details", data);
export const deleteAttScheduleDetailApi = (data) => api.delete("/attendance-schedule-details", data);

export const getPayrollSettingApi = (data) => api.get("/payroll-settings", data);
export const approvePayrollSettingApi = (data) => api.put("/payroll-settings", data);
export const addNewPayrollSettingApi = (data) => api.post("/payroll-settings", data);
export const updatePayrollSettingApi = (data) => api.patch("/payroll-settings", data);
export const deletePayrollSettingApi = (data) => api.delete("/payroll-settings", data);

export const getPayrollComponentApi = (data) => api.get("/payroll-components", data);
export const approvePayrollComponentApi = (data) => api.put("/payroll-components", data);
export const addNewPayrollComponentApi = (data) => api.post("/payroll-components", data);
export const updatePayrollComponentApi = (data) => api.patch("/payroll-components", data);
export const deletePayrollComponentApi = (data) => api.delete("/payroll-components", data);

export const getPayrollComponentDetailApi = (data) => api.get("/payroll-component-details", data);
export const approvePayrollComponentDetailApi = (data) => api.put("/payroll-component-details", data);
export const addNewPayrollComponentDetailApi = (data) => api.post("/payroll-component-details", data);
export const updatePayrollComponentDetailApi = (data) => api.patch("/payroll-component-details", data);
export const deletePayrollComponentDetailApi = (data) => api.delete("/payroll-component-details", data);

export const getPaymentComponentDetailApi = (data) => api.get("/payment-component-details", data);
export const approvePaymentComponentDetailApi = (data) => api.put("/payment-component-details", data);
export const addNewPaymentComponentDetailApi = (data) => api.post("/payment-component-details", data);
export const updatePaymentComponentDetailApi = (data) => api.patch("/payment-component-details", data);
export const deletePaymentComponentDetailApi = (data) => api.delete("/payment-component-details", data);

export const getPaymentComponentAllocationApi = (data) => api.get("/payment-allocations", data);
export const approvePaymentComponentAllocationApi = (data) => api.put("/payment-allocationss", data);
export const addNewPaymentComponentAllocationApi = (data) => api.post("/payment-allocations", data);
export const updatePaymentComponentAllocationApi = (data) => api.patch("/payment-allocations", data);
export const deletePaymentComponentAllocationApi = (data) => api.delete("/payment-allocations", data);

export const getWorkGroupApi = (data) => api.get("/work-groups", data);
export const approveWorkGroupApi = (data) => api.put("/work-groups", data);
export const addNewWorkGroupApi = (data) => api.post("/work-groups", data);
export const updateWorkGroupApi = (data) => api.patch("/work-groups", data);
export const deleteWorkGroupApi = (data) => api.delete("/work-groups", data);

export const getWorkGroupDetailApi = (data) => api.get("/work-group-details", data);
export const approveWorkGroupDetailApi = (data) => api.put("/work-group-details", data);
export const addNewWorkGroupDetailApi = (data) => api.post("/work-group-details", data);
export const updateWorkGroupDetailApi = (data) => api.patch("/work-group-details", data);
export const deleteWorkGroupDetailApi = (data) => api.delete("/work-group-details", data);

export const getUserTaxApi = (data) => api.get("/user-taxes", data);
export const addNewUserTaxApi = (data) => api.post("/user-taxes", data);
export const updateUserTaxApi = (data) => api.patch("/user-taxes", data);
export const deleteUserTaxApi = (data) => api.delete("/user-taxes", data);
export const approveUserTaxApi = (data) => api.put("/user-taxes", data);

export const getPerformanceAppraisalDetailApi = (data) => api.get("/performance-appraisal-details", data);
export const approvePerformanceAppraisalDetailApi = (data) => api.put("/performance-appraisal-details", data);
export const addNewPerformanceAppraisalDetailApi = (data) => api.post("/performance-appraisal-details", data);
export const updatePerformanceAppraisalDetailApi = (data) => api.patch("/performance-appraisal-details", data);
export const deletePerformanceAppraisalDetailApi = (data) => api.delete("/performance-appraisal-details", data);

export const getPerformanceAppraisalApi = (data) => api.get("/performance-appraisals", data);
export const approvePerformanceAppraisalApi = (data) => api.put("/performance-appraisals", data);
export const addNewPerformanceAppraisalApi = (data) => api.post("/performance-appraisals", data);
export const updatePerformanceAppraisalApi = (data) => api.patch("/performance-appraisals", data);
export const deletePerformanceAppraisalApi = (data) => api.delete("/performance-appraisals", data);

export const getPerformanceApi = (data) => api.get("/performances", data);
export const approvePerformanceApi = (data) => api.put("/performances", data);
export const addNewPerformanceApi = (data) => api.post("/performances", data);
export const updatePerformanceApi = (data) => api.patch("/performances", data);
export const deletePerformanceApi = (data) => api.delete("/performances", data);

export const getPerformancePredicateApi = (data) => api.get("/performance-predicates", data);
export const approvePerformancePredicateApi = (data) => api.put("/performance-predicates", data);
export const addNewPerformancePredicateApi = (data) => api.post("/performance-predicates", data);
export const updatePerformancePredicateApi = (data) => api.patch("/performance-predicates", data);
export const deletePerformancePredicateApi = (data) => api.delete("/performance-predicates", data);

export const getPerformanceScoreApi = (data) => api.get("/performance-scores", data);
export const approvePerformanceScoreApi = (data) => api.put("/performance-scores", data);
export const addNewPerformanceScoreApi = (data) => api.post("/performance-scores", data);
export const updatePerformanceScoreApi = (data) => api.patch("/performance-scores", data);
export const deletePerformanceScoreApi = (data) => api.delete("/performance-scores", data);

export const getPerformanceScoreDetailApi = (data) => api.get("/performance-score-details", data);
export const approvePerformanceScoreDetailApi = (data) => api.put("/performance-score-details", data);
export const addNewPerformanceScoreDetailApi = (data) => api.post("/performance-score-details", data);
export const updatePerformanceScoreDetailApi = (data) => api.patch("/performance-score-details", data);
export const deletePerformanceScoreDetailApi = (data) => api.delete("/performance-score-details", data);

export const getUserAssessmentDetailApi = (data) => api.get("/user-assessment-details", data);
export const approveUserAssessmentDetailApi = (data) => api.put("/user-assessment-details", data);
export const addNewUserAssessmentDetailApi = (data) => api.post("/user-assessment-details", data);
export const updateUserAssessmentDetailApi = (data) => api.patch("/user-assessment-details", data);
export const deleteUserAssessmentDetailApi = (data) => api.delete("/user-assessment-details", data);

export const getUserAssessmentApi = (data) => api.get("/user-assessments", data);
export const approveUserAssessmentApi = (data) => api.put("/user-assessments", data);
export const addNewUserAssessmentApi = (data) => api.post("/user-assessments", data);
export const updateUserAssessmentApi = (data) => api.patch("/user-assessments", data);
export const deleteUserAssessmentApi = (data) => api.delete("/user-assessments", data);

export const getTaskApi = (data) => api.get("/tasks", data);
export const approveTaskApi = (data) => api.put("/tasks", data);
export const addNewTaskApi = (data) => api.post("/tasks", data);
export const updateTaskApi = (data) => api.patch("/tasks", data);
export const deleteTaskApi = (data) => api.delete("/tasks", data);

export const getTaskDetailApi = (data) => api.get("/task-details", data);
export const approveTaskDetailApi = (data) => api.put("/task-details", data);
export const addNewTaskDetailApi = (data) => api.post("/task-details", data);
export const updateTaskDetailApi = (data) => api.patch("/task-details", data);
export const deleteTaskDetailApi = (data) => api.delete("/task-details", data);

export const getTaskListDetailApi = (data) => api.get("/task-list-details", data);
export const approveTaskListDetailApi = (data) => api.put("/task-list-details", data);
export const addNewTaskListDetailApi = (data) => api.post("/task-list-details", data);
export const updateTaskListDetailApi = (data) => api.patch("/task-list-details", data);
export const deleteTaskListDetailApi = (data) => api.delete("/task-list-details", data);

export const getTaskListApi = (data) => api.get("/task-lists", data);
export const approveTaskListApi = (data) => api.put("/task-lists", data);
export const addNewTaskListApi = (data) => api.post("/task-lists", data);
export const updateTaskListApi = (data) => api.patch("/task-lists", data);
export const deleteTaskListApi = (data) => api.delete("/task-lists", data);

export const getTicketApi = (data) => api.get("/tickets", data);
export const approveTicketApi = (data) => api.put("/tickets", data);
export const addNewTicketApi = (data) => api.post("/tickets", data);
export const updateTicketApi = (data) => api.patch("/tickets", data);
export const deleteTicketApi = (data) => api.delete("/tickets", data);

export const getLoanTermApi = (data) => api.get("/loan-terms", data);
export const approveLoanTermApi = (data) => api.put("/loan-terms", data);
export const addNewLoanTermApi = (data) => api.post("/loan-terms", data);
export const updateLoanTermApi = (data) => api.patch("/loan-terms", data);
export const deleteLoanTermApi = (data) => api.delete("/loan-terms", data);

export const getLoanApi = (data) => api.get("/loans", data);
export const approveLoanApi = (data) => api.put("/loans", data);
export const addNewLoanApi = (data) => api.post("/loans", data);
export const updateLoanApi = (data) => api.patch("/loans", data);
export const deleteLoanApi = (data) => api.delete("/loans", data);

//pelunasan
export const getLoanPaymentApi = (data) => api.get("/loan-payments", data);
export const approveLoanPaymentApi = (data) => api.put("/loan-payments", data);
export const addNewLoanPaymentApi = (data) => api.post("/loan-payments", data);
export const updateLoanPaymentApi = (data) => api.patch("/loan-payments", data);
export const deleteLoanPaymentApi = (data) => api.delete("/loan-payments", data);

export const getReimbursementTypeApi = (data) => api.get("/reimbursement-types", data);
export const approveReimbursementTypeApi = (data) => api.put("/reimbursement-types", data);
export const addNewReimbursementTypeApi = (data) => api.post("/reimbursement-types", data);
export const updateReimbursementTypeApi = (data) => api.patch("/reimbursement-types", data);
export const deleteReimbursementTypeApi = (data) => api.delete("/reimbursement-types", data);

export const getReimbursementApi = (data) => api.get("/reimbursements", data);
export const approveReimbursementApi = (data) => api.put("/reimbursements", data);
export const addNewReimbursementApi = (data) => api.postImg("/reimbursements", data);
export const updateReimbursementApi = (data) => api.patchImg("/reimbursements", data);
export const deleteReimbursementApi = (data) => api.delete("/reimbursements", data);

//Inventory

//Asset
export const getAssetApi = (data) => api.get("/assets", data);
export const approveAssetApi = (data) => api.put("/assets", data);
export const addNewAssetApi = (data) => api.postImg("/assets", data);
export const updateAssetApi = (data) => api.patchImg("/assets", data);
export const deleteAssetApi = (data) => api.delete("/assets", data);

export const getAssetTagApi = (data) => api.get("/asset-tags", data);
export const approveAssetTagApi = (data) => api.put("/asset-tags", data);
export const addNewAssetTagApi = (data) => api.post("/asset-tags", data);
export const updateAssetTagApi = (data) => api.patch("/asset-tags", data);
export const deleteAssetTagApi = (data) => api.delete("/asset-tags", data);

export const getAssetFeatureApi = (data) => api.get("/asset-features", data);
export const approveAssetFeatureApi = (data) => api.put("/asset-features", data);
export const addNewAssetFeatureApi = (data) => api.postImg("/asset-features", data);
export const updateAssetFeatureApi = (data) => api.patchImg("/asset-features", data);
export const deleteAssetFeatureApi = (data) => api.delete("/asset-features", data);

export const getAssetGroupApi = (data) => api.get("/asset-groups", data);
export const approveAssetGroupApi = (data) => api.put("/asset-groups", data);
export const addNewAssetGroupApi = (data) => api.postImg("/asset-groups", data);
export const updateAssetGroupApi = (data) => api.patchImg("/asset-groups", data);
export const deleteAssetGroupApi = (data) => api.delete("/asset-groups", data);

export const getUomApi = (data) => api.get("/uoms", data);
export const approveUomApi = (data) => api.put("/uoms", data);
export const addNewUomApi = (data) => api.post("/uoms", data);
export const updateUomApi = (data) => api.patch("/uoms", data);
export const deleteUomApi = (data) => api.delete("/uoms", data);

export const getAccAccountApi = (data) => api.get("/acc-accounts", data);
export const approveAccAccountApi = (data) => api.put("/acc-accounts", data);
export const addNewAccAccountApi = (data) => api.post("/acc-accounts", data);
export const updateAccAccountApi = (data) => api.patch("/acc-accounts", data);
export const deleteAccAccountApi = (data) => api.delete("/acc-accounts", data);

export const getAccCategoryApi = (data) => api.get("/acc-categories", data);
export const approveAccCategoryApi = (data) => api.put("/acc-categories", data);
export const addNewAccCategoryApi = (data) => api.post("/acc-categories", data);
export const updateAccCategoryApi = (data) => api.patch("/acc-categories", data);
export const deleteAccCategoryApi = (data) => api.delete("/acc-categories", data);

export const getAssetClassApi = (data) => api.get("/asset-classes", data);
export const approveAssetClassApi = (data) => api.put("/asset-classes", data);
export const addNewAssetClassApi = (data) => api.post("/asset-classes", data);
export const updateAssetClassApi = (data) => api.patch("/asset-classes", data);
export const deleteAssetClassApi = (data) => api.delete("/asset-classes", data);

export const getAssetCategoryApi = (data) => api.get("/asset-categories", data);
export const addNewAssetCategoryApi = (data) => api.post("/asset-categories", data);
export const updateAssetCategoryApi = (data) => api.patch("/asset-categories", data);
export const deleteAssetCategoryApi = (data) => api.delete("/asset-categories", data);

export const getAssetInventoryApi = (data) => api.get("/asset-inventories", data);
export const approveAssetInventoryApi = (data) => api.put("/asset-inventories", data);
export const addNewAssetInventoryApi = (data) => api.post("/asset-inventories", data);
export const updateAssetInventoryApi = (data) => api.patch("/asset-inventories", data);
export const deleteAssetInventoryApi = (data) => api.delete("/asset-inventories", data);

export const getAssetInventoryDetailApi = (data) => api.get("/asset-inventory-details", data);
export const approveAssetInventoryDetailApi = (data) => api.put("/asset-inventory-details", data);
export const addNewAssetInventoryDetailApi = (data) => api.post("/asset-inventory-details", data);
export const updateAssetInventoryDetailApi = (data) => api.patch("/asset-inventory-details", data);
export const deleteAssetInventoryDetailApi = (data) => api.delete("/asset-inventory-details", data);

////TRANSACTION METHOD
export const getPaymentMethodApi = (data) => api.get("/payment-methods", data);
export const approvePaymentMethodApi = (data) => api.put("/payment-methods", data);
export const addNewPaymentMethodApi = (data) => api.post("/payment-methods", data);
export const updatePaymentMethodApi = (data) => api.patch("/payment-methods", data);
export const deletePaymentMethodApi = (data) => api.delete("/payment-methods", data);

export const getPaymentTermApi = (data) => api.get("/payment-terms", data);
export const approvePaymentTermApi = (data) => api.put("/payment-terms", data);
export const addNewPaymentTermApi = (data) => api.post("/payment-terms", data);
export const updatePaymentTermApi = (data) => api.patch("/payment-terms", data);
export const deletePaymentTermApi = (data) => api.delete("/payment-terms", data);

export const getShipmentMethodApi = (data) => api.get("/shipment-methods", data);
export const approveShipmentMethodApi = (data) => api.put("/shipment-methods", data);
export const addNewShipmentMethodApi = (data) => api.post("/shipment-methods", data);
export const updateShipmentMethodApi = (data) => api.patch("/shipment-methods", data);
export const deleteShipmentMethodApi = (data) => api.delete("/shipment-methods", data);

export const getShipmentCarrierApi = (data) => api.get("/shipment-carriers", data);
export const approveShipmentCarrierApi = (data) => api.put("/shipment-carriers", data);
export const addNewShipmentCarrierApi = (data) => api.post("/shipment-carriers", data);
export const updateShipmentCarrierApi = (data) => api.patch("/shipment-carriers", data);
export const deleteShipmentCarrierApi = (data) => api.delete("/shipment-carriers", data);

export const getTransactionTaxApi = (data) => api.get("/transaction-taxes", data);
export const approveTransactionTaxApi = (data) => api.put("/transaction-taxes", data);
export const addNewTransactionTaxApi = (data) => api.post("/transaction-taxes", data);
export const updateTransactionTaxApi = (data) => api.patch("/transaction-taxes", data);
export const deleteTransactionTaxApi = (data) => api.delete("/transaction-taxes", data);

//Purchase
export const getVendorApi = (data) => api.get("/vendors", data);
export const addNewVendorApi = (data) => api.post("/vendors", data);
export const approveVendorApi = (data) => api.put("/vendors", data);
export const updateVendorApi = (data) => api.patch("/vendors", data);
export const deleteVendorApi = (data) => api.delete("/vendors", data);

export const getPurchaseApi = (data) => api.get("/purchases", data);
export const approvePurchaseApi = (data) => api.put("/purchases", data);
export const addNewPurchaseApi = (data) => api.postImg("/purchases", data);
export const updatePurchaseApi = (data) => api.patchImg("/purchases", data);
export const deletePurchaseApi = (data) => api.delete("/purchases", data);

export const getPurchaseDetailApi = (data) => api.get("/purchase-details", data);
export const approvePurchaseDetailApi = (data) => api.put("/purchase-details", data);
export const addNewPurchaseDetailApi = (data) => api.post("/purchase-details", data);
export const updatePurchaseDetailApi = (data) => api.patch("/purchase-details", data);
export const deletePurchaseDetailApi = (data) => api.delete("/purchase-details", data);

export const getPurchaseTotalApi = (data) => api.get("/purchase-totals", data);
export const approvePurchaseTotalApi = (data) => api.put("/purchase-totals", data);
export const addNewPurchaseTotalApi = (data) => api.post("/purchase-totals", data);
export const updatePurchaseTotalApi = (data) => api.patch("/purchase-totals", data);
export const deletePurchaseTotalApi = (data) => api.delete("/purchase-totals", data);

export const getPurchaseApprovalApi = (data) => api.get("/purchase-approvals", data);
export const approvePurchaseApprovalApi = (data) => api.put("/purchase-approvals", data);
export const addNewPurchaseApprovalApi = (data) => api.post("/purchase-approvals", data);
export const updatePurchaseApprovalApi = (data) => api.patch("/purchase-approvals", data);
export const deletePurchaseApprovalApi = (data) => api.delete("/purchase-approvals", data);

export const getPurchasePaymentApi = (data) => api.get("/purchase-payments", data);
export const approvePurchasePaymentApi = (data) => api.put("/purchase-payments", data);
export const addNewPurchasePaymentApi = (data) => api.post("/purchase-payments", data);
export const updatePurchasePaymentApi = (data) => api.patch("/purchase-payments", data);
export const deletePurchasePaymentApi = (data) => api.delete("/purchase-payments", data);

export const getPurchaseHistoryApi = (data) => api.get("/purchase-histories", data);
export const approvePurchaseHistoryApi = (data) => api.put("/purchase-histories", data);
export const addNewPurchaseHistoryApi = (data) => api.post("/purchase-histories", data);
export const updatePurchaseHistoryApi = (data) => api.patch("/purchase-histories", data);
export const deletePurchaseHistoryApi = (data) => api.delete("/purchase-histories", data);

//Sales
export const getCustomerApi = (data) => api.get("/customers", data);
export const addNewCustomerApi = (data) => api.post("/customers", data);
export const approveCustomerApi = (data) => api.put("/customers", data);
export const updateCustomerApi = (data) => api.patch("/customers", data);
export const deleteCustomerApi = (data) => api.delete("/customers", data);

//ROLE
export const getRoleApi = data  => api.get("/roles", data);
export const addNewRoleApi = data => api.post("/roles", data);
export const updateRoleApi = data => api.patch("/roles", data);
export const deleteRoleApi = data => api.delete("/roles", data);
export const approveRoleApi = data => api.put("/roles", data);

export const getRoleListApi = data  => api.get("/role-lists", data);
export const addNewRoleListApi = data => api.post("/role-lists", data);
export const updateRoleListApi = data => api.patch("/role-lists", data);
export const deleteRoleListApi = data => api.delete("/role-lists", data);
export const approveRoleListApi = data => api.put("/role-lists", data);

export const getStudentApi = data  => api.get("/students", data);
export const addNewStudentApi = data => api.post("/students", data);
export const updateStudentApi = data => api.patch("/students", data);
export const deleteStudentApi = data => api.delete("/students", data);
export const approveStudentApi = data => api.put("/students", data);
export const getOneStudentApi = data => api.put("/students", data);


export const getClassApi = (data) => api.get("/classes", data);
export const addNewClassApi = (data) => api.post("/classes", data);
export const updateClassApi = (data) => api.patch("/classes", data);
export const deleteClassApi = (data) => api.delete("/classes", data);

export const getAcademicYearApi = (data) => api.get("/academic-years", data);
export const getOneAcademicYearApi = (data) => api.get("/academic-years", data);
export const addNewAcademicYearApi = (data) => api.post("/academic-years", data);
export const updateAcademicYearApi = (data) => api.patch("/academic-years", data);
export const deleteAcademicYearApi = (data) => api.delete("/academic-years", data);
export const approveAcademicYearApi = (data) => api.put("/academic-years", data);

export const getAcademicSemesterApi = (data) => api.get("/academic-semesters", data);
export const getOneAcademicSemesterApi = (data) => api.get("/academic-semesters", data);
export const addNewAcademicSemesterApi = (data) => api.post("/academic-semesters", data);
export const updateAcademicSemesterApi = (data) => api.patch("/academic-semesters", data);
export const deleteAcademicSemesterApi = (data) => api.delete("/academic-semesters", data);
export const approveAcademicSemesterApi = (data) => api.put("/academic-semesters", data);

export const getAcademicSubjectApi = (data) => api.get("/academic-subjects", data);
export const getOneAcademicSubjectApi = (data) => api.get("/academic-subjects", data);
export const addNewAcademicSubjectApi = (data) => api.post("/academic-subjects", data);
export const updateAcademicSubjectApi = (data) => api.patch("/academic-subjects", data);
export const deleteAcademicSubjectApi = (data) => api.delete("/academic-subjects", data);
export const approveAcademicSubjectApi = (data) => api.put("/academic-subjects", data);

export const getClassAttendanceApi = (data) => api.get("/class-attendances", data);
export const getOneClassAttendanceApi = (data) => api.get("/class-attendances", data);
export const addNewClassAttendanceApi = (data) => api.post("/class-attendances", data);
export const updateClassAttendanceApi = (data) => api.patch("/class-attendances", data);
export const deleteClassAttendanceApi = (data) => api.delete("/class-attendances", data);
export const approveClassAttendanceApi = (data) => api.put("/class-attendances", data);

export const getClassGradeApi = (data) => api.get("/class-grades", data);
export const getOneClassGradeApi = (data) => api.get("/class-grades", data);
export const addNewClassGradeApi = (data) => api.post("/class-grades", data);
export const updateClassGradeApi = (data) => api.patch("/class-grades", data);
export const deleteClassGradeApi = (data) => api.delete("/class-grades", data);
export const approveClassGradeApi = (data) => api.put("/class-grades", data);

export const getPaymentApi = (data) => api.get("/payments", data);
export const approvePaymentApi = (data) => api.put("/payments", data);
export const addNewPaymentApi = (data) => api.postImg("/payments", data);
export const updatePaymentApi = (data) => api.patchImg("/payments", data);
export const deletePaymentApi = (data) => api.delete("/payments", data);

export const getParentingCounselApi = (data) => api.get("/parenting-counsels", data);
export const approveParentingCounselApi = (data) => api.put("/parenting-counsels", data);
export const addNewParentingCounselApi = (data) => api.post("/parenting-counsels", data);
export const updateParentingCounselApi = (data) => api.patch("/parenting-counsels", data);
export const deleteParentingCounselApi = (data) => api.delete("/parenting-counsels", data);

export const getParentingTahfidApi = (data) => api.get("/parenting-tahfids", data);
export const approveParentingTahfidApi = (data) => api.put("/parenting-tahfids", data);
export const addNewParentingTahfidApi = (data) => api.post("/parenting-tahfids", data);
export const updateParentingTahfidApi = (data) => api.patch("/parenting-tahfids", data);
export const deleteParentingTahfidApi = (data) => api.delete("/parenting-tahfids", data);

export const getParentingLeaveApi = (data) => api.get("/parenting-leaves", data);
export const approveParentingLeaveApi = (data) => api.put("/parenting-leaves", data);
export const addNewParentingLeaveApi = (data) => api.post("/parenting-leaves", data);
export const updateParentingLeaveApi = (data) => api.patch("/parenting-leaves", data);
export const deleteParentingLeaveApi = (data) => api.delete("/parenting-leaves", data);

export const getParentingHomeApi = (data) => api.get("/parenting-homes", data);
export const approveParentingHomeApi = (data) => api.put("/parenting-homes", data);
export const addNewParentingHomeApi = (data) => api.post("/parenting-homes", data);
export const updateParentingHomeApi = (data) => api.patch("/parenting-homes", data);
export const deleteParentingHomeApi = (data) => api.delete("/parenting-homes", data);

export const getParentingVisitApi = (data) => api.get("/parenting-visits", data);
export const approveParentingVisitApi = (data) => api.put("/parenting-visits", data);
export const addNewParentingVisitApi = (data) => api.post("/parenting-visits", data);
export const updateParentingVisitApi = (data) => api.patch("/parenting-visits", data);
export const deleteParentingVisitApi = (data) => api.delete("/parenting-visits", data);

export const getParentingNadhomanApi = (data) => api.get("/parenting-nadhomans", data);
export const approveParentingNadhomanApi = (data) => api.put("/parenting-nadhomans", data);
export const addNewParentingNadhomanApi = (data) => api.post("/parenting-nadhomans", data);
export const updateParentingNadhomanApi = (data) => api.patch("/parenting-nadhomans", data);
export const deleteParentingNadhomanApi = (data) => api.delete("/parenting-nadhomans", data);

export const getParentingHealthApi = (data) => api.get("/parenting-healths", data);
export const approveParentingHealthApi = (data) => api.put("/parenting-healths", data);
export const addNewParentingHealthApi = (data) => api.post("/parenting-healths", data);
export const updateParentingHealthApi = (data) => api.patch("/parenting-healths", data);
export const deleteParentingHealthApi = (data) => api.delete("/parenting-healths", data);

export const approveChangeClassApi = (data) => api.put("/class-changes", data);
export const getChangeClassApi = (data) => api.get("/class-changes", data);
export const addNewChangeClassApi = (data) => api.post("/class-changes", data);
export const updateChangeClassApi = (data) => api.patch("/class-changes", data);
export const deleteChangeClassApi = (data) => api.delete("/class-changes", data);

export const getChangeRoomApi = (data) => api.get("/room-changes", data);
export const addNewChangeRoomApi = (data) => api.post("/room-changes", data);
export const updateChangeRoomApi = (data) => api.patch("/room-changes", data);
export const deleteChangeRoomApi = (data) => api.delete("/room-changes", data);

export const getReportRoomChangeApi = (data) => api.get("/report-room-changes", data);
export const addNewReportRoomChangeApi = (data) => api.post("/report-room-changes", data);
export const updateReportRoomChangeApi = (data) => api.patch("/report-room-changes", data);
export const deleteReportRoomChangeApi = (data) => api.delete("/report-room-changes", data);
export const approveDashboardApi = (data) => api.put("/dashboards", data);
export const getDashboardApi = (data) => api.get("/dashboards", data);
export const addNewDashboardApi = (data) => api.post("/dashboards", data);
export const updateDashboardApi = (data) => api.patch("/dashboards", data);
export const deleteDashboardApi = (data) => api.delete("/dashboards", data);

export const getClassGradeReportApi = (data) => api.get("/class-grade-reports", data);
export const approveClassGradeReportApi = (data) => api.put("/class-grade-reports", data);
export const addNewClassGradeReportApi = (data) => api.post("/class-grade-reports", data);
export const updateClassGradeReportApi = (data) => api.patch("/class-grade-reports", data);
export const deleteClassGradeReportApi = (data) => api.delete("/class-grade-reports", data);

export const approveNewsApi = (data) => api.put("/news", data);
export const getNewsApi = (data) => api.get("/news", data);
export const addNewNewsApi = (data) => api.post("/news", data);
export const updateNewsApi = (data) => api.patchImg("/news", data);
export const deleteNewsApi = (data) => api.delete("/news", data);

export const approveBannerApi = (data) => api.put("/banners", data);
export const getBannerApi = (data) => api.get("/banners", data);
export const addNewBannerApi = (data) => api.post("/banners", data);
export const updateBannerApi = (data) => api.patchImg("/banners", data);
export const deleteBannerApi = (data) => api.delete("/banners", data);


export const approveContentApi = (data) => api.put("/contents", data);
export const getContentApi = (data) => api.get("/contents", data);
export const addNewContentApi = (data) => api.post("/contents", data);
export const updateContentApi = (data) => api.patchImg("/contents", data);
export const deleteContentApi = (data) => api.delete("/contents", data);
