export const BANNER_LOADING = "BANNER_LOADING";
export const BANNER_RESET = "BANNER_RESET";
export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAIL = "GET_BANNER_FAIL";
export const DELETE_BANNER = "DELETE_BANNER"
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS"
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL"
export const UPDATE_BANNER = "UPDATE_BANNER"
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS"
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL"
export const ADD_NEW_BANNER = "ADD_NEW_BANNER"
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS"
export const ADD_BANNER_FAIL = "ADD_BANNER_FAIL"