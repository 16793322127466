import {
  GET_ASSET,
  GET_ASSET_SUCCESS,
  GET_ASSET_FAIL,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAIL,
  UPDATE_ASSET,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,
  ADD_NEW_ASSET,
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAIL,
  ASSET_LOADING,
  ASSET_RESET,
  ASSET_RESET_CREATE
} from "./actionType";

export const resetAssetState = (actionType) => ({
  type: ASSET_RESET,
  payload: { actionType },
});


export const resetAssetCreateState = (actionType) => ({
  type: ASSET_RESET_CREATE,
  payload: { actionType }
});

export const getAssetSuccess = (actionType, data) => ({
  type: GET_ASSET_SUCCESS,
  payload: { actionType, data },
});

export const getAssetFail = (actionType, error) => ({
  type: GET_ASSET_FAIL,
  payload: { actionType, error },
});

export const getAsset = data => ({
  type: GET_ASSET,
  payload: data,
});

export const getAssetLoading = () => ({
  type: ASSET_LOADING
});

export const deleteAsset = data => ({
  type: DELETE_ASSET,
  payload: data,
});

export const deleteAssetSuccess = data => ({
  type: DELETE_ASSET_SUCCESS,
  payload: data,
});

export const deleteAssetFail = error => ({
  type: DELETE_ASSET_FAIL,
  payload: error,
});

export const updateAsset = data => ({
  type: UPDATE_ASSET,
  payload: data,
});

export const updateAssetFail = error => ({
  type: UPDATE_ASSET_FAIL,
  payload: error,
});

export const updateAssetSuccess = data => ({
  type: UPDATE_ASSET_SUCCESS,
  payload: data,
});

export const addNewAsset = data => ({
  type: ADD_NEW_ASSET,
  payload: data,
});

export const addAssetSuccess = data => ({
  type: ADD_ASSET_SUCCESS,
  payload: data,
});

export const addAssetFail = error => ({
  type: ADD_ASSET_FAIL,
  payload: error,
});