import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_IDENTITYTYPE,
  DELETE_IDENTITYTYPE,
  UPDATE_IDENTITYTYPE,
  ADD_NEW_IDENTITYTYPE,
} from "./actionType";

import {
  getIdentityTypeSuccess,
  getIdentityTypeFail,
  deleteIdentityTypeSuccess,
  deleteIdentityTypeFail,
  updateIdentityTypeSuccess,
  updateIdentityTypeFail,
  addIdentityTypeSuccess,
  addIdentityTypeFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getIdentityTypeApi,
  updateIdentityTypeApi,
  deleteIdentityTypeApi,
  addNewIdentityTypeApi
} from "../../helpers/backend_helper";

function* getIdentityType({ payload: identityType }) {
  try {
    const response = yield call(getIdentityTypeApi, identityType);
    yield put(getIdentityTypeSuccess(GET_IDENTITYTYPE, response.data));
  } catch (error) {
    yield put(getIdentityTypeFail(GET_IDENTITYTYPE, error));
  }
}

function* onUpdateIdentityType({ payload: identityType }) {
  try {
    const response = yield call(updateIdentityTypeApi, identityType);
    yield put(updateIdentityTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateIdentityTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteIdentityType({ payload: identityType }) {
  try {
    const response = yield call(deleteIdentityTypeApi, identityType);
    yield put(deleteIdentityTypeSuccess({ identityType, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteIdentityTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewIdentityType({ payload: identityType }) {
  try {
    const response = yield call(addNewIdentityTypeApi, identityType);
    yield put(addIdentityTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addIdentityTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetIdentityType() {
  yield takeEvery(GET_IDENTITYTYPE, getIdentityType);
}

export function* watchUpdateIdentityType() {
  yield takeEvery(UPDATE_IDENTITYTYPE, onUpdateIdentityType);
}

export function* watchDeleteIdentityType() {
  yield takeEvery(DELETE_IDENTITYTYPE, onDeleteIdentityType);
}

export function* watchAddNewIdentityType() {
  yield takeEvery(ADD_NEW_IDENTITYTYPE, onAddNewIdentityType);
}

function* identityTypeSaga() {
  yield all([
    fork(watchGetIdentityType),
    fork(watchDeleteIdentityType),
    fork(watchUpdateIdentityType),
    fork(watchAddNewIdentityType)
  ]);
}

export default identityTypeSaga;
