import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ASSETCATEGORY,
  DELETE_ASSETCATEGORY,
  UPDATE_ASSETCATEGORY,
  ADD_NEW_ASSETCATEGORY,
} from "./actionType";

import {
  getAssetCategorySuccess,
  getAssetCategoryFail,
  deleteAssetCategorySuccess,
  deleteAssetCategoryFail,
  updateAssetCategorySuccess,
  updateAssetCategoryFail,
  addAssetCategorySuccess,
  addAssetCategoryFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getAssetCategoryApi,
  updateAssetCategoryApi,
  deleteAssetCategoryApi,
  addNewAssetCategoryApi
} from "../../helpers/backend_helper";

function* getAssetCategory({ payload: assetCategory }) {
  try {
    const response = yield call(getAssetCategoryApi, assetCategory);
    yield put(getAssetCategorySuccess(GET_ASSETCATEGORY, response.data));
  } catch (error) {
    yield put(getAssetCategoryFail(GET_ASSETCATEGORY, error));
  }
}

function* onUpdateAssetCategory({ payload: assetCategory }) {
  try {
    const response = yield call(updateAssetCategoryApi, assetCategory);
    yield put(updateAssetCategorySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateAssetCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAssetCategory({ payload: assetCategory }) {
  try {
    const response = yield call(deleteAssetCategoryApi, assetCategory);
    yield put(deleteAssetCategorySuccess({ assetCategory, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAssetCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAssetCategory({ payload: assetCategory }) {
  try {
    const response = yield call(addNewAssetCategoryApi, assetCategory);
    yield put(addAssetCategorySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAssetCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAssetCategory() {
  yield takeEvery(GET_ASSETCATEGORY, getAssetCategory);
}

export function* watchUpdateAssetCategory() {
  yield takeEvery(UPDATE_ASSETCATEGORY, onUpdateAssetCategory);
}

export function* watchDeleteAssetCategory() {
  yield takeEvery(DELETE_ASSETCATEGORY, onDeleteAssetCategory);
}

export function* watchAddNewAssetCategory() {
  yield takeEvery(ADD_NEW_ASSETCATEGORY, onAddNewAssetCategory);
}

function* assetCategorySaga() {
  yield all([
    fork(watchGetAssetCategory),
    fork(watchDeleteAssetCategory),
    fork(watchUpdateAssetCategory),
    fork(watchAddNewAssetCategory)
  ]);
}

export default assetCategorySaga;
