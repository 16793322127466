export const BANK_LOADING = "BANK_LOADING";
export const GET_BANK = "GET_BANK";
export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";
export const GET_BANK_FAIL = "GET_BANK_FAIL";
export const DELETE_BANK = "DELETE_BANK"
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS"
export const DELETE_BANK_FAIL = "DELETE_BANK_FAIL"
export const UPDATE_BANK = "UPDATE_BANK"
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS"
export const UPDATE_BANK_FAIL = "UPDATE_BANK_FAIL"
export const ADD_NEW_BANK = "ADD_NEW_BANK"
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS"
export const ADD_BANK_FAIL = "ADD_BANK_FAIL"