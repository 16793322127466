import {
  CONTENT_LOADING,
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_FAIL,
  ADD_CONTENT_SUCCESS,
  ADD_CONTENT_FAIL,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAIL,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAIL,
  CONTENT_RESET
} from "./actionType";

const INIT_STATE = {
  contents: [],
  error: {},
};

const Content = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTENT_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CONTENT:
          return {
            ...state,
            contents: action.payload.data,
            isContentCreated: false,
            isContentSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_CONTENT_FAIL:
      switch (action.payload.actionType) {
        case GET_CONTENT_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isContentCreated: false,
            isContentSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_CONTENT: {
      return {
        ...state,
        isContentCreated: false,
        loading: true,
      };
    }

    case CONTENT_LOADING: {
      return {
        ...state,
        isContentCreated: false,
        loading: true,
      };
    }

    case ADD_CONTENT_SUCCESS:
      return {
        ...state,
        isContentCreated: true,
        loading: false,
        contents: [...state.contents, action.payload.data],
      };

    case ADD_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        contents: state.contents.filter(
          content => content.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        contents: state.contents.map(content =>
          content.id.toString() === action.payload.data.id.toString() ? { ...content, ...action.payload.data } : content
        ),
      };

    case UPDATE_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONTENT_RESET:
      return INIT_STATE;


    default:
      return { ...state };
  }
};

export default Content;