import {
  GET_FILE,
  GET_FILE_SUCCESS,
  GET_FILE_FAIL,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAIL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAIL,
  ADD_NEW_FILE,
  ADD_FILE_SUCCESS,
  ADD_FILE_FAIL,
  FILE_LOADING,
  FILE_RESET,
} from "./actionType";

export const resetFileState = (actionType) => ({
  type: FILE_RESET,
  payload: { actionType },
});

export const getFileSuccess = (actionType, data) => ({
  type: GET_FILE_SUCCESS,
  payload: { actionType, data },
});

export const getFileFail = (actionType, error) => ({
  type: GET_FILE_FAIL,
  payload: { actionType, error },
});

export const getFile = data => ({
  type: GET_FILE,
  payload: data,
});

export const getFileLoading = () => ({
  type: FILE_LOADING
});

export const deleteFile = data => ({
  type: DELETE_FILE,
  payload: data,
});

export const deleteFileSuccess = data => ({
  type: DELETE_FILE_SUCCESS,
  payload: data,
});

export const deleteFileFail = error => ({
  type: DELETE_FILE_FAIL,
  payload: error,
});

export const updateFile = data => ({
  type: UPDATE_FILE,
  payload: data,
});

export const updateFileFail = error => ({
  type: UPDATE_FILE_FAIL,
  payload: error,
});

export const updateFileSuccess = data => ({
  type: UPDATE_FILE_SUCCESS,
  payload: data,
});

export const addNewFile = data => ({
  type: ADD_NEW_FILE,
  payload: data,
});

export const addFileSuccess = data => ({
  type: ADD_FILE_SUCCESS,
  payload: data,
});

export const addFileFail = error => ({
  type: ADD_FILE_FAIL,
  payload: error,
});