import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_VILLAGE,
  DELETE_VILLAGE,
  UPDATE_VILLAGE,
  ADD_NEW_VILLAGE,
} from "./actionType";

import {
  getVillageSuccess,
  getVillageFail,
  deleteVillageSuccess,
  deleteVillageFail,
  updateVillageSuccess,
  updateVillageFail,
  addVillageSuccess,
  addVillageFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveVillageApi,
  getVillageApi,
  updateVillageApi,
  deleteVillageApi,
  addNewVillageApi
} from "../../helpers/backend_helper";

function* getVillage({ payload: village }) {
  try {
    const response = yield call(getVillageApi, village);
    yield put(getVillageSuccess(GET_VILLAGE, response.data));
  } catch (error) {
    yield put(getVillageFail(GET_VILLAGE, error));
  }
}

function* onUpdateVillage({ payload: village }) {
  try {
    const response = yield call(updateVillageApi, village);
    yield put(updateVillageSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateVillageFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteVillage({ payload: village }) {
  try {
    const response = yield call(deleteVillageApi, village);
    yield put(deleteVillageSuccess({ village, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteVillageFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewVillage({ payload: village }) {
  try {
    const response = yield call(addNewVillageApi, village);
    yield put(addVillageSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addVillageFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetVillage() {
  yield takeEvery(GET_VILLAGE, getVillage);
}

export function* watchUpdateVillage() {
  yield takeEvery(UPDATE_VILLAGE, onUpdateVillage);
}

export function* watchDeleteVillage() {
  yield takeEvery(DELETE_VILLAGE, onDeleteVillage);
}

export function* watchAddNewVillage() {
  yield takeEvery(ADD_NEW_VILLAGE, onAddNewVillage);
}

function* villageSaga() {
  yield all([
    fork(watchGetVillage),
    fork(watchDeleteVillage),
    fork(watchUpdateVillage),
    fork(watchAddNewVillage)
  ]);
}

export default villageSaga;
