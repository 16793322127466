import {
  CITY_LOADING,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_FAIL,
  ADD_CITY_SUCCESS,
  ADD_CITY_FAIL,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAIL,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL
} from "./actionType";

const INIT_STATE = {
  cities: [],
  error: {},
};

const City = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CITY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CITY:
          return {
            ...state,
            cities: action.payload.data,
            isCityCreated: false,
            isCitySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_CITY_FAIL:
      switch (action.payload.actionType) {
        case GET_CITY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isCityCreated: false,
            isCitySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_CITY: {
      return {
        ...state,
        isCityCreated: false,
        loading: true,
      };
    }

    case CITY_LOADING: {
      return {
        ...state,
        isCityCreated: false,
        loading: true,
      };
    }

    case ADD_CITY_SUCCESS:
      return {
        ...state,
        isCityCreated: true,
        loading: false,
        cities: [...state.cities, action.payload.data],
      };

    case ADD_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: state.cities.filter(
          city => city.id.toString() !== action.payload.city.id.toString()
        ),
      };

    case DELETE_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: state.cities.map(city =>
          city.id.toString() === action.payload.data.id.toString() ? { ...city, ...action.payload.data } : city
        ),
      };

    case UPDATE_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default City;