import {
  BANNER_LOADING,
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAIL,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAIL,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  BANNER_RESET
} from "./actionType";

const INIT_STATE = {
  banners: [],
  error: {},
};

const Banner = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BANNER:
          return {
            ...state,
            banners: action.payload.data,
            isBannerCreated: false,
            isBannerSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_BANNER_FAIL:
      switch (action.payload.actionType) {
        case GET_BANNER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isBannerCreated: false,
            isBannerSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_BANNER: {
      return {
        ...state,
        isBannerCreated: false,
        loading: true,
      };
    }

    case BANNER_LOADING: {
      return {
        ...state,
        isBannerCreated: false,
        loading: true,
      };
    }

    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        isBannerCreated: true,
        loading: false,
        banners: [...state.banners, action.payload.data],
      };

    case ADD_BANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        banners: state.banners.filter(
          banner => banner.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        banners: state.banners.map(banner =>
          banner.id.toString() === action.payload.data.id.toString() ? { ...banner, ...action.payload.data } : banner
        ),
      };

    case UPDATE_BANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BANNER_RESET:
      return INIT_STATE;


    default:
      return { ...state };
  }
};

export default Banner;