import {
  GET_ASSETFEATURE,
  GET_ASSETFEATURE_SUCCESS,
  GET_ASSETFEATURE_FAIL,
  DELETE_ASSETFEATURE,
  DELETE_ASSETFEATURE_SUCCESS,
  DELETE_ASSETFEATURE_FAIL,
  UPDATE_ASSETFEATURE,
  UPDATE_ASSETFEATURE_SUCCESS,
  UPDATE_ASSETFEATURE_FAIL,
  ADD_NEW_ASSETFEATURE,
  ADD_ASSETFEATURE_SUCCESS,
  ADD_ASSETFEATURE_FAIL,
  ASSETFEATURE_LOADING,
} from "./actionType";

export const getAssetFeatureSuccess = (actionType, data) => ({
  type: GET_ASSETFEATURE_SUCCESS,
  payload: { actionType, data },
});

export const getAssetFeatureFail = (actionType, error) => ({
  type: GET_ASSETFEATURE_FAIL,
  payload: { actionType, error },
});

export const getAssetFeature = data => ({
  type: GET_ASSETFEATURE,
  payload: data,
});

export const getAssetFeatureLoading = () => ({
  type: ASSETFEATURE_LOADING
});

export const deleteAssetFeature = data => ({
  type: DELETE_ASSETFEATURE,
  payload: data,
});

export const deleteAssetFeatureSuccess = data => ({
  type: DELETE_ASSETFEATURE_SUCCESS,
  payload: data,
});

export const deleteAssetFeatureFail = error => ({
  type: DELETE_ASSETFEATURE_FAIL,
  payload: error,
});

export const updateAssetFeature = data => ({
  type: UPDATE_ASSETFEATURE,
  payload: data,
});

export const updateAssetFeatureFail = error => ({
  type: UPDATE_ASSETFEATURE_FAIL,
  payload: error,
});

export const updateAssetFeatureSuccess = data => ({
  type: UPDATE_ASSETFEATURE_SUCCESS,
  payload: data,
});

export const addNewAssetFeature = data => ({
  type: ADD_NEW_ASSETFEATURE,
  payload: data,
});

export const addAssetFeatureSuccess = data => ({
  type: ADD_ASSETFEATURE_SUCCESS,
  payload: data,
});

export const addAssetFeatureFail = error => ({
  type: ADD_ASSETFEATURE_FAIL,
  payload: error,
});