import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  ADD_NEW_CLIENT,
} from "./actionType";

import {
  getClientSuccess,
  getClientFail,
  deleteClientSuccess,
  deleteClientFail,
  updateClientSuccess,
  updateClientFail,
  addClientSuccess,
  addClientFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getClientApi,
  updateClientApi,
  deleteClientApi,
  addNewClientApi
} from "../../helpers/backend_helper";

function* getClient({ payload: client }) {
  try {
    const response = yield call(getClientApi, client);
    yield put(getClientSuccess(GET_CLIENT, response.data));
  } catch (error) {
    yield put(getClientFail(GET_CLIENT, error));
  }
}

function* onUpdateClient({ payload: client }) {
  try {
    const response = yield call(updateClientApi, client);
    yield put(updateClientSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateClientFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteClient({ payload: client }) {
  try {
    const response = yield call(deleteClientApi, client);
    yield put(deleteClientSuccess({ client, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteClientFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewClient({ payload: client }) {
  try {
    const response = yield call(addNewClientApi, client);
    yield put(addClientSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addClientFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetClient() {
  yield takeEvery(GET_CLIENT, getClient);
}

export function* watchUpdateClient() {
  yield takeEvery(UPDATE_CLIENT, onUpdateClient);
}

export function* watchDeleteClient() {
  yield takeEvery(DELETE_CLIENT, onDeleteClient);
}

export function* watchAddNewClient() {
  yield takeEvery(ADD_NEW_CLIENT, onAddNewClient);
}

function* clientSaga() {
  yield all([
    fork(watchGetClient),
    fork(watchDeleteClient),
    fork(watchUpdateClient),
    fork(watchAddNewClient)
  ]);
}

export default clientSaga;
