import {
  USER_LOADING,
  GET_USER,
  GET_USER_ONE,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_RESET,
} from "./actionType";

const INIT_STATE = {
  users: [],
  user: {},
  error: {},
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_USER:
          return {
            ...state,
            users: action.payload.data,
            isUserCreated: false,
            isUserSuccess: true,
            loading: false,
          };

        case GET_USER_ONE:
          return {
            ...state,
            user: action.payload.data,
            isUserSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_USER_FAIL:
      switch (action.payload.actionType) {
        case GET_USER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isUserCreated: false,
            isUserSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_USER: {
      return {
        ...state,
        isUserCreated: false,
        loading: true,
      };
    }

    case GET_USER_ONE: {
      return {
        ...state,
        loading: true,
      };
    }

    case USER_LOADING: {
      return {
        ...state,
        isUserCreated: false,
        loading: true,
      };
    }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        isUserCreated: true,
        loading: false,
        users: [...state.users, action.payload.data],
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter(
          (user) => user.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.map((user) =>
          user.id.toString() === action.payload.data.id.toString()
            ? { ...user, ...action.payload.data }
            : user
        ),
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default User;
