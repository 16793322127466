export const ASSET_LOADING = "ASSET_LOADING";
export const ASSET_RESET = "ASSET_RESET";
export const GET_ASSET = "GET_ASSET";
export const GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS";
export const GET_ASSET_FAIL = "GET_ASSET_FAIL";
export const DELETE_ASSET = "DELETE_ASSET"
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS"
export const DELETE_ASSET_FAIL = "DELETE_ASSET_FAIL"
export const UPDATE_ASSET = "UPDATE_ASSET"
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS"
export const UPDATE_ASSET_FAIL = "UPDATE_ASSET_FAIL"
export const ADD_NEW_ASSET = "ADD_NEW_ASSET"
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS"
export const ADD_ASSET_FAIL = "ADD_ASSET_FAIL"
export const ASSET_RESET_CREATE = "ASSET_RESET_CREATE"