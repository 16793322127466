import {
  NEWS_LOADING,
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_FAIL,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  NEWS_RESET
} from "./actionType";

const INIT_STATE = {
  newss: [],
  error: {},
};

const News = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NEWS_SUCCESS:
      switch (action.payload.actionType) {
        case GET_NEWS:
          return {
            ...state,
            newss: action.payload.data,
            isNewsCreated: false,
            isNewsSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_NEWS_FAIL:
      switch (action.payload.actionType) {
        case GET_NEWS_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isNewsCreated: false,
            isNewsSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_NEWS: {
      return {
        ...state,
        isNewsCreated: false,
        loading: true,
      };
    }

    case NEWS_LOADING: {
      return {
        ...state,
        isNewsCreated: false,
        loading: true,
      };
    }

    case ADD_NEWS_SUCCESS:
      return {
        ...state,
        isNewsCreated: true,
        loading: false,
        newss: [...state.newss, action.payload.data],
      };

    case ADD_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        newss: state.newss.filter(
          news => news.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        newss: state.newss.map(news =>
          news.id.toString() === action.payload.data.id.toString() ? { ...news, ...action.payload.data } : news
        ),
      };

    case UPDATE_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NEWS_RESET:
      return INIT_STATE;


    default:
      return { ...state };
  }
};

export default News;