export const DISTRICT_LOADING = "DISTRICT_LOADING";
export const GET_DISTRICT = "GET_DISTRICT";
export const GET_DISTRICT_SUCCESS = "GET_DISTRICT_SUCCESS";
export const GET_DISTRICT_FAIL = "GET_DISTRICT_FAIL";
export const DELETE_DISTRICT = "DELETE_DISTRICT"
export const DELETE_DISTRICT_SUCCESS = "DELETE_DISTRICT_SUCCESS"
export const DELETE_DISTRICT_FAIL = "DELETE_DISTRICT_FAIL"
export const UPDATE_DISTRICT = "UPDATE_DISTRICT"
export const UPDATE_DISTRICT_SUCCESS = "UPDATE_DISTRICT_SUCCESS"
export const UPDATE_DISTRICT_FAIL = "UPDATE_DISTRICT_FAIL"
export const ADD_NEW_DISTRICT = "ADD_NEW_DISTRICT"
export const ADD_DISTRICT_SUCCESS = "ADD_DISTRICT_SUCCESS"
export const ADD_DISTRICT_FAIL = "ADD_DISTRICT_FAIL"