export const ASSETCLASS_LOADING = "ASSETCLASS_LOADING";
export const GET_ASSETCLASS = "GET_ASSETCLASS";
export const GET_ASSETCLASS_SUCCESS = "GET_ASSETCLASS_SUCCESS";
export const GET_ASSETCLASS_FAIL = "GET_ASSETCLASS_FAIL";
export const DELETE_ASSETCLASS = "DELETE_ASSETCLASS"
export const DELETE_ASSETCLASS_SUCCESS = "DELETE_ASSETCLASS_SUCCESS"
export const DELETE_ASSETCLASS_FAIL = "DELETE_ASSETCLASS_FAIL"
export const UPDATE_ASSETCLASS = "UPDATE_ASSETCLASS"
export const UPDATE_ASSETCLASS_SUCCESS = "UPDATE_ASSETCLASS_SUCCESS"
export const UPDATE_ASSETCLASS_FAIL = "UPDATE_ASSETCLASS_FAIL"
export const ADD_NEW_ASSETCLASS = "ADD_NEW_ASSETCLASS"
export const ADD_ASSETCLASS_SUCCESS = "ADD_ASSETCLASS_SUCCESS"
export const ADD_ASSETCLASS_FAIL = "ADD_ASSETCLASS_FAIL"