export const PAYMENTTERM_LOADING = "PAYMENTTERM_LOADING";
export const PAYMENTTERM_RESET = "PAYMENTTERM_RESET";
export const GET_PAYMENTTERM = "GET_PAYMENTTERM";
export const GET_PAYMENTTERM_SUCCESS = "GET_PAYMENTTERM_SUCCESS";
export const GET_PAYMENTTERM_FAIL = "GET_PAYMENTTERM_FAIL";
export const DELETE_PAYMENTTERM = "DELETE_PAYMENTTERM"
export const DELETE_PAYMENTTERM_SUCCESS = "DELETE_PAYMENTTERM_SUCCESS"
export const DELETE_PAYMENTTERM_FAIL = "DELETE_PAYMENTTERM_FAIL"
export const UPDATE_PAYMENTTERM = "UPDATE_PAYMENTTERM"
export const UPDATE_PAYMENTTERM_SUCCESS = "UPDATE_PAYMENTTERM_SUCCESS"
export const UPDATE_PAYMENTTERM_FAIL = "UPDATE_PAYMENTTERM_FAIL"
export const ADD_NEW_PAYMENTTERM = "ADD_NEW_PAYMENTTERM"
export const ADD_PAYMENTTERM_SUCCESS = "ADD_PAYMENTTERM_SUCCESS"
export const ADD_PAYMENTTERM_FAIL = "ADD_PAYMENTTERM_FAIL"