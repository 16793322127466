import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_BANNER,
  DELETE_BANNER,
  UPDATE_BANNER,
  ADD_NEW_BANNER,
} from "./actionType";

import {
  getBannerSuccess,
  getBannerFail,
  deleteBannerSuccess,
  deleteBannerFail,
  updateBannerSuccess,
  updateBannerFail,
  addBannerSuccess,
  addBannerFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getBannerApi,
  updateBannerApi,
  deleteBannerApi,
  addNewBannerApi
} from "../../helpers/backend_helper";

function* getBanner({ payload: banner }) {
  try {
    const response = yield call(getBannerApi, banner);
    yield put(getBannerSuccess(GET_BANNER, response.data));
  } catch (error) {
    yield put(getBannerFail(GET_BANNER, error));
  }
}

function* onUpdateBanner({ payload: banner }) {
  try {
    const response = yield call(updateBannerApi, banner);
    yield put(updateBannerSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateBannerFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBanner({ payload: banner }) {
  try {
    const response = yield call(deleteBannerApi, banner);
    yield put(deleteBannerSuccess({ banner, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBannerFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBanner({ payload: banner }) {
  try {
    const response = yield call(addNewBannerApi, banner);
    yield put(addBannerSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBannerFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetBanner() {
  yield takeEvery(GET_BANNER, getBanner);
}

export function* watchUpdateBanner() {
  yield takeEvery(UPDATE_BANNER, onUpdateBanner);
}

export function* watchDeleteBanner() {
  yield takeEvery(DELETE_BANNER, onDeleteBanner);
}

export function* watchAddNewBanner() {
  yield takeEvery(ADD_NEW_BANNER, onAddNewBanner);
}

function* bannerSaga() {
  yield all([
    fork(watchGetBanner),
    fork(watchDeleteBanner),
    fork(watchUpdateBanner),
    fork(watchAddNewBanner)
  ]);
}

export default bannerSaga;
