import {
  ASSETCLASS_LOADING,
  GET_ASSETCLASS,
  GET_ASSETCLASS_SUCCESS,
  GET_ASSETCLASS_FAIL,
  ADD_ASSETCLASS_SUCCESS,
  ADD_ASSETCLASS_FAIL,
  DELETE_ASSETCLASS_SUCCESS,
  DELETE_ASSETCLASS_FAIL,
  UPDATE_ASSETCLASS_SUCCESS,
  UPDATE_ASSETCLASS_FAIL
} from "./actionType";

const INIT_STATE = {
  assetClasses: [],
  error: {},
};

const AssetClass = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETCLASS_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ASSETCLASS:
          return {
            ...state,
            assetClasses: action.payload.data,
            isAssetClassCreated: false,
            isAssetClassSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ASSETCLASS_FAIL:
      switch (action.payload.actionType) {
        case GET_ASSETCLASS_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAssetClassCreated: false,
            isAssetClassSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ASSETCLASS: {
      return {
        ...state,
        isAssetClassCreated: false,
        loading: true,
      };
    }

    case ASSETCLASS_LOADING: {
      return {
        ...state,
        isAssetClassCreated: false,
        loading: true,
      };
    }

    case ADD_ASSETCLASS_SUCCESS:
      return {
        ...state,
        isAssetClassCreated: true,
        loading: false,
        assetClasses: [...state.assetClasses, action.payload.data],
      };

    case ADD_ASSETCLASS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ASSETCLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        assetClasses: state.assetClasses.filter(
          assetClass => assetClass.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ASSETCLASS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ASSETCLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        assetClasses: state.assetClasses.map(assetClass =>
          assetClass.id.toString() === action.payload.data.id.toString() ? { ...assetClass, ...action.payload.data } : assetClass
        ),
      };

    case UPDATE_ASSETCLASS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default AssetClass;