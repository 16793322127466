import {
  PROVINCE_LOADING,
  GET_PROVINCE,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAIL,
  ADD_PROVINCE_SUCCESS,
  ADD_PROVINCE_FAIL,
  DELETE_PROVINCE_SUCCESS,
  DELETE_PROVINCE_FAIL,
  UPDATE_PROVINCE_SUCCESS,
  UPDATE_PROVINCE_FAIL
} from "./actionType";

const INIT_STATE = {
  provinces: [],
  error: {},
};

const Province = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROVINCE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROVINCE:
          return {
            ...state,
            provinces: action.payload.data,
            isProvinceCreated: false,
            isProvinceSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_PROVINCE_FAIL:
      switch (action.payload.actionType) {
        case GET_PROVINCE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isProvinceCreated: false,
            isProvinceSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_PROVINCE: {
      return {
        ...state,
        isProvinceCreated: false,
        loading: true,
      };
    }

    case PROVINCE_LOADING: {
      return {
        ...state,
        isProvinceCreated: false,
        loading: true,
      };
    }

    case ADD_PROVINCE_SUCCESS:
      return {
        ...state,
        isProvinceCreated: true,
        loading: false,
        provinces: [...state.provinces, action.payload.data],
      };

    case ADD_PROVINCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PROVINCE_SUCCESS:
      return {
        ...state,
        loading: false,
        provinces: state.provinces.filter(
          province => province.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PROVINCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PROVINCE_SUCCESS:
      return {
        ...state,
        loading: false,
        provinces: state.provinces.map(province =>
          province.id.toString() === action.payload.data.id.toString() ? { ...province, ...action.payload.data } : province
        ),
      };

    case UPDATE_PROVINCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Province;