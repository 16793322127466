// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item a{
  display: block !important;
}

.table-with-input th {
  width: 25% !important;
}

.hiddenViewPrint {
  display: none;
  color: black !important;
}

.defaultTable tr, .defaultTable th, .defaultTable td{
  padding: 1 !important;
  color: black !important;
  border: 1px solid black !important;
  border-collapse: collapse !important;
}

.noBorder tr, .noBorder th, .noBorder td{
  padding: 0 !important;
  color: black !important;
  border: 1px solid white !important;
  border-collapse: collapse !important;
}



`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,kCAAkC;EAClC,oCAAoC;AACtC;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,kCAAkC;EAClC,oCAAoC;AACtC","sourcesContent":[".dropdown-item a{\n  display: block !important;\n}\n\n.table-with-input th {\n  width: 25% !important;\n}\n\n.hiddenViewPrint {\n  display: none;\n  color: black !important;\n}\n\n.defaultTable tr, .defaultTable th, .defaultTable td{\n  padding: 1 !important;\n  color: black !important;\n  border: 1px solid black !important;\n  border-collapse: collapse !important;\n}\n\n.noBorder tr, .noBorder th, .noBorder td{\n  padding: 0 !important;\n  color: black !important;\n  border: 1px solid white !important;\n  border-collapse: collapse !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
