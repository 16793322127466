export const ASSETTAG_LOADING = "ASSETTAG_LOADING";
export const GET_ASSETTAG = "GET_ASSETTAG";
export const GET_ASSETTAG_SUCCESS = "GET_ASSETTAG_SUCCESS";
export const GET_ASSETTAG_FAIL = "GET_ASSETTAG_FAIL";
export const DELETE_ASSETTAG = "DELETE_ASSETTAG"
export const DELETE_ASSETTAG_SUCCESS = "DELETE_ASSETTAG_SUCCESS"
export const DELETE_ASSETTAG_FAIL = "DELETE_ASSETTAG_FAIL"
export const UPDATE_ASSETTAG = "UPDATE_ASSETTAG"
export const UPDATE_ASSETTAG_SUCCESS = "UPDATE_ASSETTAG_SUCCESS"
export const UPDATE_ASSETTAG_FAIL = "UPDATE_ASSETTAG_FAIL"
export const ADD_NEW_ASSETTAG = "ADD_NEW_ASSETTAG"
export const ADD_ASSETTAG_SUCCESS = "ADD_ASSETTAG_SUCCESS"
export const ADD_ASSETTAG_FAIL = "ADD_ASSETTAG_FAIL"