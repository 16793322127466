import React from "react";
import { Navigate } from "react-router-dom";

import Landing from '../pages/Home';
import Location from '../pages/Location';
import Detail from '../pages/Detail';
import Property from '../pages/Property';
import About from '../pages/About';
import Promotion from '../pages/Promotion';
import Landlord from '../pages/Landlord';
import PromoDetail from '../pages/PromotionDetail';

const authProtectedRoutes = [
  
];

const publicRoutes = [
  { path: "/home", component: <Landing /> },
  { path: "/location", component: <Location /> },
  { path: "/property", component: <Property /> },
  { path: "/promo", component: <Promotion /> },
  { path: "/about", component: <About /> },
  { path: "/landlord", component: <Landlord /> },
  { path: "/detail/:assetId", component: <Detail /> },
  { path: "/promo-detail/:newsId", component: <PromoDetail /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
];

export { authProtectedRoutes, publicRoutes };