import {
  GET_ASSETLOCATION,
  GET_ASSETLOCATION_SUCCESS,
  GET_ASSETLOCATION_FAIL,
  DELETE_ASSETLOCATION,
  DELETE_ASSETLOCATION_SUCCESS,
  DELETE_ASSETLOCATION_FAIL,
  UPDATE_ASSETLOCATION,
  UPDATE_ASSETLOCATION_SUCCESS,
  UPDATE_ASSETLOCATION_FAIL,
  ADD_NEW_ASSETLOCATION,
  ADD_ASSETLOCATION_SUCCESS,
  ADD_ASSETLOCATION_FAIL,
  ASSETLOCATION_LOADING,
  ASSETLOCATION_RESET,
} from "./actionType";

export const resetAssetLocationState = (actionType) => ({
  type: ASSETLOCATION_RESET,
  payload: { actionType },
});

export const getAssetLocationSuccess = (actionType, data) => ({
  type: GET_ASSETLOCATION_SUCCESS,
  payload: { actionType, data },
});

export const getAssetLocationFail = (actionType, error) => ({
  type: GET_ASSETLOCATION_FAIL,
  payload: { actionType, error },
});

export const getAssetLocation = data => ({
  type: GET_ASSETLOCATION,
  payload: data,
});

export const getAssetLocationLoading = () => ({
  type: ASSETLOCATION_LOADING
});

export const deleteAssetLocation = data => ({
  type: DELETE_ASSETLOCATION,
  payload: data,
});

export const deleteAssetLocationSuccess = data => ({
  type: DELETE_ASSETLOCATION_SUCCESS,
  payload: data,
});

export const deleteAssetLocationFail = error => ({
  type: DELETE_ASSETLOCATION_FAIL,
  payload: error,
});

export const updateAssetLocation = data => ({
  type: UPDATE_ASSETLOCATION,
  payload: data,
});

export const updateAssetLocationFail = error => ({
  type: UPDATE_ASSETLOCATION_FAIL,
  payload: error,
});

export const updateAssetLocationSuccess = data => ({
  type: UPDATE_ASSETLOCATION_SUCCESS,
  payload: data,
});

export const addNewAssetLocation = data => ({
  type: ADD_NEW_ASSETLOCATION,
  payload: data,
});

export const addAssetLocationSuccess = data => ({
  type: ADD_ASSETLOCATION_SUCCESS,
  payload: data,
});

export const addAssetLocationFail = error => ({
  type: ADD_ASSETLOCATION_FAIL,
  payload: error,
});