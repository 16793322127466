import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ASSET,
  DELETE_ASSET,
  UPDATE_ASSET,
  ADD_NEW_ASSET,
} from "./actionType";

import {
  getAssetSuccess,
  getAssetFail,
  deleteAssetSuccess,
  deleteAssetFail,
  updateAssetSuccess,
  updateAssetFail,
  addAssetSuccess,
  addAssetFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveAssetApi,
  getAssetApi,
  updateAssetApi,
  deleteAssetApi,
  addNewAssetApi
} from "../../helpers/backend_helper";

function* getAsset({ payload: asset }) {
  try {
    const response = yield call(getAssetApi, asset);
    yield put(getAssetSuccess(GET_ASSET, response.data));
  } catch (error) {
    yield put(getAssetFail(GET_ASSET, error));
  }
}

function* onUpdateAsset({ payload: asset }) {
  try {
    if (asset.setApprove) {
      const response = yield call(approveAssetApi, asset);
      yield put(updateAssetSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateAssetApi, asset);
      yield put(updateAssetSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateAssetFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAsset({ payload: asset }) {
  try {
    const response = yield call(deleteAssetApi, asset);
    yield put(deleteAssetSuccess({ asset, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAssetFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAsset({ payload: asset }) {
  try {
    const response = yield call(addNewAssetApi, asset);
    yield put(addAssetSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAssetFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAsset() {
  yield takeEvery(GET_ASSET, getAsset);
}

export function* watchUpdateAsset() {
  yield takeEvery(UPDATE_ASSET, onUpdateAsset);
}

export function* watchDeleteAsset() {
  yield takeEvery(DELETE_ASSET, onDeleteAsset);
}

export function* watchAddNewAsset() {
  yield takeEvery(ADD_NEW_ASSET, onAddNewAsset);
}

function* assetSaga() {
  yield all([
    fork(watchGetAsset),
    fork(watchDeleteAsset),
    fork(watchUpdateAsset),
    fork(watchAddNewAsset)
  ]);
}

export default assetSaga;
