import { takeEvery, takeLatest, put, call } from "redux-saga/effects";

// Login Redux States
import { API_ERROR, LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
  postLogin,
  postRefreshToken
} from "../../../helpers/backend_helper";

//const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      username: user.email,
      password: user.password,
    });
    if (response.status === "success") {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
      history('/dashboard')
    }
    else{
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      yield put(logoutUserSuccess(LOGOUT_USER, {}));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
