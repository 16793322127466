import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_CONTENT,
  DELETE_CONTENT,
  UPDATE_CONTENT,
  ADD_NEW_CONTENT,
} from "./actionType";

import {
  getContentSuccess,
  getContentFail,
  deleteContentSuccess,
  deleteContentFail,
  updateContentSuccess,
  updateContentFail,
  addContentSuccess,
  addContentFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getContentApi,
  updateContentApi,
  deleteContentApi,
  addNewContentApi
} from "../../helpers/backend_helper";

function* getContent({ payload: content }) {
  try {
    const response = yield call(getContentApi, content);
    yield put(getContentSuccess(GET_CONTENT, response.data));
  } catch (error) {
    yield put(getContentFail(GET_CONTENT, error));
  }
}

function* onUpdateContent({ payload: content }) {
  try {
    const response = yield call(updateContentApi, content);
    yield put(updateContentSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateContentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteContent({ payload: content }) {
  try {
    const response = yield call(deleteContentApi, content);
    yield put(deleteContentSuccess({ content, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteContentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewContent({ payload: content }) {
  try {
    const response = yield call(addNewContentApi, content);
    yield put(addContentSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addContentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetContent() {
  yield takeEvery(GET_CONTENT, getContent);
}

export function* watchUpdateContent() {
  yield takeEvery(UPDATE_CONTENT, onUpdateContent);
}

export function* watchDeleteContent() {
  yield takeEvery(DELETE_CONTENT, onDeleteContent);
}

export function* watchAddNewContent() {
  yield takeEvery(ADD_NEW_CONTENT, onAddNewContent);
}

function* contentSaga() {
  yield all([
    fork(watchGetContent),
    fork(watchDeleteContent),
    fork(watchUpdateContent),
    fork(watchAddNewContent)
  ]);
}

export default contentSaga;
