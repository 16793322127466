import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_FILE,
  DELETE_FILE,
  UPDATE_FILE,
  ADD_NEW_FILE,
} from "./actionType";

import {
  getFileSuccess,
  getFileFail,
  deleteFileSuccess,
  deleteFileFail,
  updateFileSuccess,
  updateFileFail,
  addFileSuccess,
  addFileFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getFileApi,
  updateFileApi,
  deleteFileApi,
  addNewFileApi
} from "../../helpers/backend_helper";

function* getFile({ payload: file }) {
  try {
    const response = yield call(getFileApi, file);
    yield put(getFileSuccess(GET_FILE, response.data));
  } catch (error) {
    yield put(getFileFail(GET_FILE, error));
  }
}

function* onUpdateFile({ payload: file }) {
  try {
    const response = yield call(updateFileApi, file);
    yield put(updateFileSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateFileFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteFile({ payload: file }) {
  try {
    const response = yield call(deleteFileApi, file);
    yield put(deleteFileSuccess({ file, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteFileFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewFile({ payload: file }) {
  try {
    const response = yield call(addNewFileApi, file);
    yield put(addFileSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addFileFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetFile() {
  yield takeEvery(GET_FILE, getFile);
}

export function* watchUpdateFile() {
  yield takeEvery(UPDATE_FILE, onUpdateFile);
}

export function* watchDeleteFile() {
  yield takeEvery(DELETE_FILE, onDeleteFile);
}

export function* watchAddNewFile() {
  yield takeEvery(ADD_NEW_FILE, onAddNewFile);
}

function* fileSaga() {
  yield all([
    fork(watchGetFile),
    fork(watchDeleteFile),
    fork(watchUpdateFile),
    fork(watchAddNewFile)
  ]);
}

export default fileSaga;
