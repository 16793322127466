import {
  VILLAGE_LOADING,
  GET_VILLAGE,
  GET_VILLAGE_SUCCESS,
  GET_VILLAGE_FAIL,
  ADD_VILLAGE_SUCCESS,
  ADD_VILLAGE_FAIL,
  DELETE_VILLAGE_SUCCESS,
  DELETE_VILLAGE_FAIL,
  UPDATE_VILLAGE_SUCCESS,
  UPDATE_VILLAGE_FAIL,
  VILLAGE_RESET
} from "./actionType";

const INIT_STATE = {
  villages: [],
  error: {},
};

const Village = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VILLAGE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_VILLAGE:
          return {
            ...state,
            villages: action.payload.data,
            isVillageCreated: false,
            isVillageSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_VILLAGE_FAIL:
      switch (action.payload.actionType) {
        case GET_VILLAGE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isVillageCreated: false,
            isVillageSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_VILLAGE: {
      return {
        ...state,
        isVillageCreated: false,
        loading: true,
      };
    }

    case VILLAGE_LOADING: {
      return {
        ...state,
        isVillageCreated: false,
        loading: true,
      };
    }

    case ADD_VILLAGE_SUCCESS:
      return {
        ...state,
        isVillageCreated: true,
        loading: false,
        villages: [...state.villages, action.payload.data],
      };

    case ADD_VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: state.villages.filter(
          village => village.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: state.villages.map(village =>
          village.id.toString() === action.payload.data.id.toString() ? { ...village, ...action.payload.data } : village
        ),
      };

    case UPDATE_VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VILLAGE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Village;