import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_PAYMENTTERM,
  DELETE_PAYMENTTERM,
  UPDATE_PAYMENTTERM,
  ADD_NEW_PAYMENTTERM,
} from "./actionType";

import {
  getPaymentTermSuccess,
  getPaymentTermFail,
  deletePaymentTermSuccess,
  deletePaymentTermFail,
  updatePaymentTermSuccess,
  updatePaymentTermFail,
  addPaymentTermSuccess,
  addPaymentTermFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approvePaymentTermApi,
  getPaymentTermApi,
  updatePaymentTermApi,
  deletePaymentTermApi,
  addNewPaymentTermApi
} from "../../helpers/backend_helper";

function* getPaymentTerm({ payload: paymentTerm }) {
  try {
    const response = yield call(getPaymentTermApi, paymentTerm);
    yield put(getPaymentTermSuccess(GET_PAYMENTTERM, response.data));
  } catch (error) {
    yield put(getPaymentTermFail(GET_PAYMENTTERM, error));
  }
}

function* onUpdatePaymentTerm({ payload: paymentTerm }) {
  try {
    if (paymentTerm.setApprove) {
      const response = yield call(approvePaymentTermApi, paymentTerm);
      yield put(updatePaymentTermSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updatePaymentTermApi, paymentTerm);
      yield put(updatePaymentTermSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updatePaymentTermFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeletePaymentTerm({ payload: paymentTerm }) {
  try {
    const response = yield call(deletePaymentTermApi, paymentTerm);
    yield put(deletePaymentTermSuccess({ paymentTerm, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePaymentTermFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewPaymentTerm({ payload: paymentTerm }) {
  try {
    const response = yield call(addNewPaymentTermApi, paymentTerm);
    yield put(addPaymentTermSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addPaymentTermFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetPaymentTerm() {
  yield takeEvery(GET_PAYMENTTERM, getPaymentTerm);
}

export function* watchUpdatePaymentTerm() {
  yield takeEvery(UPDATE_PAYMENTTERM, onUpdatePaymentTerm);
}

export function* watchDeletePaymentTerm() {
  yield takeEvery(DELETE_PAYMENTTERM, onDeletePaymentTerm);
}

export function* watchAddNewPaymentTerm() {
  yield takeEvery(ADD_NEW_PAYMENTTERM, onAddNewPaymentTerm);
}

function* paymentTermSaga() {
  yield all([
    fork(watchGetPaymentTerm),
    fork(watchDeletePaymentTerm),
    fork(watchUpdatePaymentTerm),
    fork(watchAddNewPaymentTerm)
  ]);
}

export default paymentTermSaga;
