import React, { useState } from 'react';
import { layoutTypes } from "../../Components/constants/layout";

const LayoutType = ({ layouttype, onChangeLayout }) => {

    const mode = layouttype === layoutTypes['HORIZONTAL'] ? layoutTypes['VERTICAL'] : layoutTypes['HORIZONTAL'];
    const [isHorizontal, setIsHorizontal] = useState(true);
    return (
        <div className="ms-1 header-item d-none d-sm-flex">
            <button
                onClick={() => {
                    layouttype === layoutTypes['HORIZONTAL'] ? setIsHorizontal(true) :  setIsHorizontal(false);
                    onChangeLayout(mode);
                }}
                type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none">
                <i className={ isHorizontal ? 'bx bx-sidebar fs-22' : 'ri-pages-line fs-22'}></i>
            </button>
        </div>
    );
};

export default LayoutType;