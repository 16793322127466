import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import avatar from "../../assets/images/users/user-dummy-img.jpg";
import { Link } from "react-router-dom";

const ProfileDropdown = () => {
  const { user } = useSelector((state) => ({
    user: state.User.user,
  }));

  const [userName, setUserName] = useState("User");
  const [userPosition, setUserPosition] = useState("-");
  const [userImage, setUserImage] = useState(avatar);
  

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserName(obj.data.user_name || "User");
      setUserPosition(obj.data.userType || "Belum diposisikan");

      if (obj.data.user_image)
        setUserImage(
          process.env.REACT_APP_API_URL +
            "/files/" +
            obj.data.user_image.path
        );
      else setUserImage(avatar);
    }
  }, [userName, user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user "
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={userImage}
              alt=""
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {userPosition}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>
          <DropdownItem>
            <Link to="/profile">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/message">
              <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Messages</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/task">
              <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Taskboard</span>
            </Link>
          </DropdownItem>
          <DropdownItem href="/pages-faqs">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Help</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="/logout">
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
