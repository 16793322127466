import {
  GET_DISTRICT,
  GET_DISTRICT_SUCCESS,
  GET_DISTRICT_FAIL,
  DELETE_DISTRICT,
  DELETE_DISTRICT_SUCCESS,
  DELETE_DISTRICT_FAIL,
  UPDATE_DISTRICT,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DISTRICT_FAIL,
  ADD_NEW_DISTRICT,
  ADD_DISTRICT_SUCCESS,
  ADD_DISTRICT_FAIL,
  DISTRICT_LOADING,
} from "./actionType";

export const getDistrictSuccess = (actionType, data) => ({
  type: GET_DISTRICT_SUCCESS,
  payload: { actionType, data },
});

export const getDistrictFail = (actionType, error) => ({
  type: GET_DISTRICT_FAIL,
  payload: { actionType, error },
});

export const getDistrict = data => ({
  type: GET_DISTRICT,
  payload: data,
});

export const getDistrictLoading = () => ({
  type: DISTRICT_LOADING
});

export const deleteDistrict = data => ({
  type: DELETE_DISTRICT,
  payload: data,
});

export const deleteDistrictSuccess = data => ({
  type: DELETE_DISTRICT_SUCCESS,
  payload: data,
});

export const deleteDistrictFail = error => ({
  type: DELETE_DISTRICT_FAIL,
  payload: error,
});

export const updateDistrict = data => ({
  type: UPDATE_DISTRICT,
  payload: data,
});

export const updateDistrictFail = error => ({
  type: UPDATE_DISTRICT_FAIL,
  payload: error,
});

export const updateDistrictSuccess = data => ({
  type: UPDATE_DISTRICT_SUCCESS,
  payload: data,
});

export const addNewDistrict = data => ({
  type: ADD_NEW_DISTRICT,
  payload: data,
});

export const addDistrictSuccess = data => ({
  type: ADD_DISTRICT_SUCCESS,
  payload: data,
});

export const addDistrictFail = error => ({
  type: ADD_DISTRICT_FAIL,
  payload: error,
});