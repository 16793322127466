import {
  GET_IDENTITY,
  GET_IDENTITY_SUCCESS,
  GET_IDENTITY_FAIL,
  DELETE_IDENTITY,
  DELETE_IDENTITY_SUCCESS,
  DELETE_IDENTITY_FAIL,
  UPDATE_IDENTITY,
  UPDATE_IDENTITY_SUCCESS,
  UPDATE_IDENTITY_FAIL,
  ADD_NEW_IDENTITY,
  ADD_IDENTITY_SUCCESS,
  ADD_IDENTITY_FAIL,
  IDENTITY_LOADING,
} from "./actionType";

export const getIdentitySuccess = (actionType, data) => ({
  type: GET_IDENTITY_SUCCESS,
  payload: { actionType, data },
});

export const getIdentityFail = (actionType, error) => ({
  type: GET_IDENTITY_FAIL,
  payload: { actionType, error },
});

export const getIdentity = data => ({
  type: GET_IDENTITY,
  payload: data,
});

export const getIdentityLoading = () => ({
  type: IDENTITY_LOADING
});

export const deleteIdentity = data => ({
  type: DELETE_IDENTITY,
  payload: data,
});

export const deleteIdentitySuccess = data => ({
  type: DELETE_IDENTITY_SUCCESS,
  payload: data,
});

export const deleteIdentityFail = error => ({
  type: DELETE_IDENTITY_FAIL,
  payload: error,
});

export const updateIdentity = data => ({
  type: UPDATE_IDENTITY,
  payload: data,
});

export const updateIdentityFail = error => ({
  type: UPDATE_IDENTITY_FAIL,
  payload: error,
});

export const updateIdentitySuccess = data => ({
  type: UPDATE_IDENTITY_SUCCESS,
  payload: data,
});

export const addNewIdentity = data => ({
  type: ADD_NEW_IDENTITY,
  payload: data,
});

export const addIdentitySuccess = data => ({
  type: ADD_IDENTITY_SUCCESS,
  payload: data,
});

export const addIdentityFail = error => ({
  type: ADD_IDENTITY_FAIL,
  payload: error,
});