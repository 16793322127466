import {
  FOLDER_LOADING,
  GET_FOLDER,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_FAIL,
  ADD_FOLDER_SUCCESS,
  ADD_FOLDER_FAIL,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAIL,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAIL,
  FOLDER_RESET
} from "./actionType";

const INIT_STATE = {
  folders: [],
  error: {},
};

const Folder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FOLDER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_FOLDER:
          return {
            ...state,
            folders: action.payload.data,
            isFolderCreated: false,
            isFolderSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_FOLDER_FAIL:
      switch (action.payload.actionType) {
        case GET_FOLDER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isFolderCreated: false,
            isFolderSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_FOLDER: {
      return {
        ...state,
        isFolderCreated: false,
        loading: true,
      };
    }

    case FOLDER_LOADING: {
      return {
        ...state,
        isFolderCreated: false,
        loading: true,
      };
    }

    case ADD_FOLDER_SUCCESS:
      return {
        ...state,
        isFolderCreated: true,
        loading: false,
        folders: [...state.folders, action.payload.data],
      };

    case ADD_FOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: state.folders.filter(
          folder => folder.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_FOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: state.folders.map(folder =>
          folder.id.toString() === action.payload.data.id.toString() ? { ...folder, ...action.payload.data } : folder
        ),
      };

    case UPDATE_FOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FOLDER_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Folder;