import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  EDIT_IMAGE_PROFILE,
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_SUCCESS,
} from "./actionTypes";

export const editProfile = (user) => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const editImageProfile = ({data}) => {
  return {
    type: EDIT_IMAGE_PROFILE,
    payload: data,
  };
};

export const editProfileFail = error => ({
  type: EDIT_PROFILE_FAIL,
  payload: error,
});

export const editProfileSuccess = data => ({
  type: EDIT_PROFILE_SUCCESS,
  payload: data,
});

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = (error) => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};
