import {
  GET_MAIL,
  GET_MAIL_SUCCESS,
  GET_MAIL_FAIL,
  DELETE_MAIL,
  DELETE_MAIL_SUCCESS,
  DELETE_MAIL_FAIL,
  UPDATE_MAIL,
  UPDATE_MAIL_SUCCESS,
  UPDATE_MAIL_FAIL,
  ADD_NEW_MAIL,
  ADD_MAIL_SUCCESS,
  ADD_MAIL_FAIL,
  MAIL_LOADING,
  MAIL_RESET,
  MAIL_RESET_CREATE
} from "./actionType";

export const resetMailState = (actionType) => ({
  type: MAIL_RESET,
  payload: { actionType },
});


export const resetMailCreateState = (actionType) => ({
  type: MAIL_RESET_CREATE,
  payload: { actionType }
});

export const getMailSuccess = (actionType, data) => ({
  type: GET_MAIL_SUCCESS,
  payload: { actionType, data },
});

export const getMailFail = (actionType, error) => ({
  type: GET_MAIL_FAIL,
  payload: { actionType, error },
});

export const getMail = data => ({
  type: GET_MAIL,
  payload: data,
});

export const getMailLoading = () => ({
  type: MAIL_LOADING
});

export const deleteMail = data => ({
  type: DELETE_MAIL,
  payload: data,
});

export const deleteMailSuccess = data => ({
  type: DELETE_MAIL_SUCCESS,
  payload: data,
});

export const deleteMailFail = error => ({
  type: DELETE_MAIL_FAIL,
  payload: error,
});

export const updateMail = data => ({
  type: UPDATE_MAIL,
  payload: data,
});

export const updateMailFail = error => ({
  type: UPDATE_MAIL_FAIL,
  payload: error,
});

export const updateMailSuccess = data => ({
  type: UPDATE_MAIL_SUCCESS,
  payload: data,
});

export const addNewMail = data => ({
  type: ADD_NEW_MAIL,
  payload: data,
});

export const addMailSuccess = data => ({
  type: ADD_MAIL_SUCCESS,
  payload: data,
});

export const addMailFail = error => ({
  type: ADD_MAIL_FAIL,
  payload: error,
});