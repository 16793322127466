import {
  ASSETCATEGORY_LOADING,
  GET_ASSETCATEGORY,
  GET_ASSETCATEGORY_SUCCESS,
  GET_ASSETCATEGORY_FAIL,
  ADD_ASSETCATEGORY_SUCCESS,
  ADD_ASSETCATEGORY_FAIL,
  DELETE_ASSETCATEGORY_SUCCESS,
  DELETE_ASSETCATEGORY_FAIL,
  UPDATE_ASSETCATEGORY_SUCCESS,
  UPDATE_ASSETCATEGORY_FAIL,
  ASSETCATEGORY_RESET
} from "./actionType";

const INIT_STATE = {
  assetCategories: [],
  error: {},
};

const AssetCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETCATEGORY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ASSETCATEGORY:
          return {
            ...state,
            assetCategories: action.payload.data,
            isAssetCategoryCreated: false,
            isAssetCategorySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ASSETCATEGORY_FAIL:
      switch (action.payload.actionType) {
        case GET_ASSETCATEGORY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAssetCategoryCreated: false,
            isAssetCategorySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ASSETCATEGORY: {
      return {
        ...state,
        isAssetCategoryCreated: false,
        loading: true,
      };
    }

    case ASSETCATEGORY_LOADING: {
      return {
        ...state,
        isAssetCategoryCreated: false,
        loading: true,
      };
    }

    case ADD_ASSETCATEGORY_SUCCESS:
      return {
        ...state,
        isAssetCategoryCreated: true,
        loading: false,
        assetCategories: [...state.assetCategories, action.payload.data],
      };

    case ADD_ASSETCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ASSETCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        assetCategories: state.assetCategories.filter(
          assetCategory => assetCategory.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ASSETCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ASSETCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        assetCategories: state.assetCategories.map(assetCategory =>
          assetCategory.id.toString() === action.payload.data.id.toString() ? { ...assetCategory, ...action.payload.data } : assetCategory
        ),
      };

    case UPDATE_ASSETCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ASSETCATEGORY_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default AssetCategory;