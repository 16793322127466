export const CONTENT_LOADING = "CONTENT_LOADING";
export const CONTENT_RESET = "CONTENT_RESET";
export const GET_CONTENT = "GET_CONTENT";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const GET_CONTENT_FAIL = "GET_CONTENT_FAIL";
export const DELETE_CONTENT = "DELETE_CONTENT"
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS"
export const DELETE_CONTENT_FAIL = "DELETE_CONTENT_FAIL"
export const UPDATE_CONTENT = "UPDATE_CONTENT"
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS"
export const UPDATE_CONTENT_FAIL = "UPDATE_CONTENT_FAIL"
export const ADD_NEW_CONTENT = "ADD_NEW_CONTENT"
export const ADD_CONTENT_SUCCESS = "ADD_CONTENT_SUCCESS"
export const ADD_CONTENT_FAIL = "ADD_CONTENT_FAIL"