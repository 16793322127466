import {
  ASSETFEATURE_LOADING,
  GET_ASSETFEATURE,
  GET_ASSETFEATURE_SUCCESS,
  GET_ASSETFEATURE_FAIL,
  ADD_ASSETFEATURE_SUCCESS,
  ADD_ASSETFEATURE_FAIL,
  DELETE_ASSETFEATURE_SUCCESS,
  DELETE_ASSETFEATURE_FAIL,
  UPDATE_ASSETFEATURE_SUCCESS,
  UPDATE_ASSETFEATURE_FAIL
} from "./actionType";

const INIT_STATE = {
  assetFeatures: [],
  error: {},
};

const AssetFeature = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETFEATURE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ASSETFEATURE:
          return {
            ...state,
            assetFeatures: action.payload.data,
            isAssetFeatureCreated: false,
            isAssetFeatureSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ASSETFEATURE_FAIL:
      switch (action.payload.actionType) {
        case GET_ASSETFEATURE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAssetFeatureCreated: false,
            isAssetFeatureSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ASSETFEATURE: {
      return {
        ...state,
        isAssetFeatureCreated: false,
        loading: true,
      };
    }

    case ASSETFEATURE_LOADING: {
      return {
        ...state,
        isAssetFeatureCreated: false,
        loading: true,
      };
    }

    case ADD_ASSETFEATURE_SUCCESS:
      return {
        ...state,
        isAssetFeatureCreated: true,
        loading: false,
        assetFeatures: [...state.assetFeatures, action.payload.data],
      };

    case ADD_ASSETFEATURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ASSETFEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        assetFeatures: state.assetFeatures.filter(
          assetFeature => assetFeature.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ASSETFEATURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ASSETFEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        assetFeatures: state.assetFeatures.map(assetFeature =>
          assetFeature.id.toString() === action.payload.data.id.toString() ? { ...assetFeature, ...action.payload.data } : assetFeature
        ),
      };

    case UPDATE_ASSETFEATURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default AssetFeature;