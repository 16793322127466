import {
  GET_VILLAGE,
  GET_VILLAGE_SUCCESS,
  GET_VILLAGE_FAIL,
  DELETE_VILLAGE,
  DELETE_VILLAGE_SUCCESS,
  DELETE_VILLAGE_FAIL,
  UPDATE_VILLAGE,
  UPDATE_VILLAGE_SUCCESS,
  UPDATE_VILLAGE_FAIL,
  ADD_NEW_VILLAGE,
  ADD_VILLAGE_SUCCESS,
  ADD_VILLAGE_FAIL,
  VILLAGE_LOADING,
  VILLAGE_RESET,
} from "./actionType";

export const resetVillageState = (actionType) => ({
  type: VILLAGE_RESET,
  payload: { actionType },
});

export const getVillageSuccess = (actionType, data) => ({
  type: GET_VILLAGE_SUCCESS,
  payload: { actionType, data },
});

export const getVillageFail = (actionType, error) => ({
  type: GET_VILLAGE_FAIL,
  payload: { actionType, error },
});

export const getVillage = data => ({
  type: GET_VILLAGE,
  payload: data,
});

export const getVillageLoading = () => ({
  type: VILLAGE_LOADING
});

export const deleteVillage = data => ({
  type: DELETE_VILLAGE,
  payload: data,
});

export const deleteVillageSuccess = data => ({
  type: DELETE_VILLAGE_SUCCESS,
  payload: data,
});

export const deleteVillageFail = error => ({
  type: DELETE_VILLAGE_FAIL,
  payload: error,
});

export const updateVillage = data => ({
  type: UPDATE_VILLAGE,
  payload: data,
});

export const updateVillageFail = error => ({
  type: UPDATE_VILLAGE_FAIL,
  payload: error,
});

export const updateVillageSuccess = data => ({
  type: UPDATE_VILLAGE_SUCCESS,
  payload: data,
});

export const addNewVillage = data => ({
  type: ADD_NEW_VILLAGE,
  payload: data,
});

export const addVillageSuccess = data => ({
  type: ADD_VILLAGE_SUCCESS,
  payload: data,
});

export const addVillageFail = error => ({
  type: ADD_VILLAGE_FAIL,
  payload: error,
});