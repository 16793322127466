import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_BANK,
  DELETE_BANK,
  UPDATE_BANK,
  ADD_NEW_BANK,
} from "./actionType";

import {
  getBankSuccess,
  getBankFail,
  deleteBankSuccess,
  deleteBankFail,
  updateBankSuccess,
  updateBankFail,
  addBankSuccess,
  addBankFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getBankApi,
  updateBankApi,
  deleteBankApi,
  addNewBankApi
} from "../../helpers/backend_helper";

function* getBank({ payload: bank }) {
  try {
    const response = yield call(getBankApi, bank);
    yield put(getBankSuccess(GET_BANK, response.data));
  } catch (error) {
    yield put(getBankFail(GET_BANK, error));
  }
}

function* onUpdateBank({ payload: bank }) {
  try {
    const response = yield call(updateBankApi, bank);
    yield put(updateBankSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateBankFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBank({ payload: bank }) {
  try {
    const response = yield call(deleteBankApi, bank);
    yield put(deleteBankSuccess({ bank, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBankFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBank({ payload: bank }) {
  try {
    const response = yield call(addNewBankApi, bank);
    yield put(addBankSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBankFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetBank() {
  yield takeEvery(GET_BANK, getBank);
}

export function* watchUpdateBank() {
  yield takeEvery(UPDATE_BANK, onUpdateBank);
}

export function* watchDeleteBank() {
  yield takeEvery(DELETE_BANK, onDeleteBank);
}

export function* watchAddNewBank() {
  yield takeEvery(ADD_NEW_BANK, onAddNewBank);
}

function* bankSaga() {
  yield all([
    fork(watchGetBank),
    fork(watchDeleteBank),
    fork(watchUpdateBank),
    fork(watchAddNewBank)
  ]);
}

export default bankSaga;
