import {
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_FAIL,
  DELETE_CONTENT,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAIL,
  UPDATE_CONTENT,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAIL,
  ADD_NEW_CONTENT,
  ADD_CONTENT_SUCCESS,
  ADD_CONTENT_FAIL,
  CONTENT_LOADING,
  CONTENT_RESET,
} from "./actionType";

export const resetContentState = (actionType) => ({
  type: CONTENT_RESET,
  payload: { actionType },
});

export const getContentSuccess = (actionType, data) => ({
  type: GET_CONTENT_SUCCESS,
  payload: { actionType, data },
});

export const getContentFail = (actionType, error) => ({
  type: GET_CONTENT_FAIL,
  payload: { actionType, error },
});

export const getContent = data => ({
  type: GET_CONTENT,
  payload: data,
});

export const getContentLoading = () => ({
  type: CONTENT_LOADING
});

export const deleteContent = data => ({
  type: DELETE_CONTENT,
  payload: data,
});

export const deleteContentSuccess = data => ({
  type: DELETE_CONTENT_SUCCESS,
  payload: data,
});

export const deleteContentFail = error => ({
  type: DELETE_CONTENT_FAIL,
  payload: error,
});

export const updateContent = data => ({
  type: UPDATE_CONTENT,
  payload: data,
});

export const updateContentFail = error => ({
  type: UPDATE_CONTENT_FAIL,
  payload: error,
});

export const updateContentSuccess = data => ({
  type: UPDATE_CONTENT_SUCCESS,
  payload: data,
});

export const addNewContent = data => ({
  type: ADD_NEW_CONTENT,
  payload: data,
});

export const addContentSuccess = data => ({
  type: ADD_CONTENT_SUCCESS,
  payload: data,
});

export const addContentFail = error => ({
  type: ADD_CONTENT_FAIL,
  payload: error,
});