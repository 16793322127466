export const PROVINCE_LOADING = "PROVINCE_LOADING";
export const GET_PROVINCE = "GET_PROVINCE";
export const GET_PROVINCE_SUCCESS = "GET_PROVINCE_SUCCESS";
export const GET_PROVINCE_FAIL = "GET_PROVINCE_FAIL";
export const DELETE_PROVINCE = "DELETE_PROVINCE"
export const DELETE_PROVINCE_SUCCESS = "DELETE_PROVINCE_SUCCESS"
export const DELETE_PROVINCE_FAIL = "DELETE_PROVINCE_FAIL"
export const UPDATE_PROVINCE = "UPDATE_PROVINCE"
export const UPDATE_PROVINCE_SUCCESS = "UPDATE_PROVINCE_SUCCESS"
export const UPDATE_PROVINCE_FAIL = "UPDATE_PROVINCE_FAIL"
export const ADD_NEW_PROVINCE = "ADD_NEW_PROVINCE"
export const ADD_PROVINCE_SUCCESS = "ADD_PROVINCE_SUCCESS"
export const ADD_PROVINCE_FAIL = "ADD_PROVINCE_FAIL"