import {
  IDENTITYTYPE_LOADING,
  GET_IDENTITYTYPE,
  GET_IDENTITYTYPE_SUCCESS,
  GET_IDENTITYTYPE_FAIL,
  ADD_IDENTITYTYPE_SUCCESS,
  ADD_IDENTITYTYPE_FAIL,
  DELETE_IDENTITYTYPE_SUCCESS,
  DELETE_IDENTITYTYPE_FAIL,
  UPDATE_IDENTITYTYPE_SUCCESS,
  UPDATE_IDENTITYTYPE_FAIL,
  IDENTITYTYPE_RESET
} from "./actionType";

const INIT_STATE = {
  identityTypes: [],
  error: {},
};

const IdentityType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IDENTITYTYPE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_IDENTITYTYPE:
          return {
            ...state,
            identityTypes: action.payload.data,
            isIdentityTypeCreated: false,
            isIdentityTypeSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_IDENTITYTYPE_FAIL:
      switch (action.payload.actionType) {
        case GET_IDENTITYTYPE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isIdentityTypeCreated: false,
            isIdentityTypeSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_IDENTITYTYPE: {
      return {
        ...state,
        isIdentityTypeCreated: false,
        loading: true,
      };
    }

    case IDENTITYTYPE_LOADING: {
      return {
        ...state,
        isIdentityTypeCreated: false,
        loading: true,
      };
    }

    case ADD_IDENTITYTYPE_SUCCESS:
      return {
        ...state,
        isIdentityTypeCreated: true,
        loading: false,
        identityTypes: [...state.identityTypes, action.payload.data],
      };

    case ADD_IDENTITYTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_IDENTITYTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        identityTypes: state.identityTypes.filter(
          identityType => identityType.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_IDENTITYTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_IDENTITYTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        identityTypes: state.identityTypes.map(identityType =>
          identityType.id.toString() === action.payload.data.id.toString() ? { ...identityType, ...action.payload.data } : identityType
        ),
      };

    case UPDATE_IDENTITYTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case IDENTITYTYPE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default IdentityType;