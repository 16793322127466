import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_PAYMENTMETHOD,
  DELETE_PAYMENTMETHOD,
  UPDATE_PAYMENTMETHOD,
  ADD_NEW_PAYMENTMETHOD,
} from "./actionType";

import {
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  deletePaymentMethodSuccess,
  deletePaymentMethodFail,
  updatePaymentMethodSuccess,
  updatePaymentMethodFail,
  addPaymentMethodSuccess,
  addPaymentMethodFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approvePaymentMethodApi,
  getPaymentMethodApi,
  updatePaymentMethodApi,
  deletePaymentMethodApi,
  addNewPaymentMethodApi
} from "../../helpers/backend_helper";

function* getPaymentMethod({ payload: paymentMethod }) {
  try {
    const response = yield call(getPaymentMethodApi, paymentMethod);
    yield put(getPaymentMethodSuccess(GET_PAYMENTMETHOD, response.data));
  } catch (error) {
    yield put(getPaymentMethodFail(GET_PAYMENTMETHOD, error));
  }
}

function* onUpdatePaymentMethod({ payload: paymentMethod }) {
  try {
    if (paymentMethod.setApprove) {
      const response = yield call(approvePaymentMethodApi, paymentMethod);
      yield put(updatePaymentMethodSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updatePaymentMethodApi, paymentMethod);
      yield put(updatePaymentMethodSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updatePaymentMethodFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeletePaymentMethod({ payload: paymentMethod }) {
  try {
    const response = yield call(deletePaymentMethodApi, paymentMethod);
    yield put(deletePaymentMethodSuccess({ paymentMethod, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePaymentMethodFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewPaymentMethod({ payload: paymentMethod }) {
  try {
    const response = yield call(addNewPaymentMethodApi, paymentMethod);
    yield put(addPaymentMethodSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addPaymentMethodFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetPaymentMethod() {
  yield takeEvery(GET_PAYMENTMETHOD, getPaymentMethod);
}

export function* watchUpdatePaymentMethod() {
  yield takeEvery(UPDATE_PAYMENTMETHOD, onUpdatePaymentMethod);
}

export function* watchDeletePaymentMethod() {
  yield takeEvery(DELETE_PAYMENTMETHOD, onDeletePaymentMethod);
}

export function* watchAddNewPaymentMethod() {
  yield takeEvery(ADD_NEW_PAYMENTMETHOD, onAddNewPaymentMethod);
}

function* paymentMethodSaga() {
  yield all([
    fork(watchGetPaymentMethod),
    fork(watchDeletePaymentMethod),
    fork(watchUpdatePaymentMethod),
    fork(watchAddNewPaymentMethod)
  ]);
}

export default paymentMethodSaga;
