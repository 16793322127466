import {
  GET_TRANSACTIONTAX,
  GET_TRANSACTIONTAX_SUCCESS,
  GET_TRANSACTIONTAX_FAIL,
  DELETE_TRANSACTIONTAX,
  DELETE_TRANSACTIONTAX_SUCCESS,
  DELETE_TRANSACTIONTAX_FAIL,
  UPDATE_TRANSACTIONTAX,
  UPDATE_TRANSACTIONTAX_SUCCESS,
  UPDATE_TRANSACTIONTAX_FAIL,
  ADD_NEW_TRANSACTIONTAX,
  ADD_TRANSACTIONTAX_SUCCESS,
  ADD_TRANSACTIONTAX_FAIL,
  TRANSACTIONTAX_LOADING,
  TRANSACTIONTAX_RESET,
} from "./actionType";

export const resetTransactionTaxState = (actionType) => ({
  type: TRANSACTIONTAX_RESET,
  payload: { actionType },
});

export const getTransactionTaxSuccess = (actionType, data) => ({
  type: GET_TRANSACTIONTAX_SUCCESS,
  payload: { actionType, data },
});

export const getTransactionTaxFail = (actionType, error) => ({
  type: GET_TRANSACTIONTAX_FAIL,
  payload: { actionType, error },
});

export const getTransactionTax = data => ({
  type: GET_TRANSACTIONTAX,
  payload: data,
});

export const getTransactionTaxLoading = () => ({
  type: TRANSACTIONTAX_LOADING
});

export const deleteTransactionTax = data => ({
  type: DELETE_TRANSACTIONTAX,
  payload: data,
});

export const deleteTransactionTaxSuccess = data => ({
  type: DELETE_TRANSACTIONTAX_SUCCESS,
  payload: data,
});

export const deleteTransactionTaxFail = error => ({
  type: DELETE_TRANSACTIONTAX_FAIL,
  payload: error,
});

export const updateTransactionTax = data => ({
  type: UPDATE_TRANSACTIONTAX,
  payload: data,
});

export const updateTransactionTaxFail = error => ({
  type: UPDATE_TRANSACTIONTAX_FAIL,
  payload: error,
});

export const updateTransactionTaxSuccess = data => ({
  type: UPDATE_TRANSACTIONTAX_SUCCESS,
  payload: data,
});

export const addNewTransactionTax = data => ({
  type: ADD_NEW_TRANSACTIONTAX,
  payload: data,
});

export const addTransactionTaxSuccess = data => ({
  type: ADD_TRANSACTIONTAX_SUCCESS,
  payload: data,
});

export const addTransactionTaxFail = error => ({
  type: ADD_TRANSACTIONTAX_FAIL,
  payload: error,
});