import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { EDIT_IMAGE_PROFILE, EDIT_PROFILE } from "./actionTypes";
import {
  profileSuccess,
  profileError,
  editImageProfile,
  editProfileSuccess,
  editProfileFail,
} from "./actions";
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeProfile,
  postJwtProfile,
  updateImageProfile,
} from "../../../helpers/backend_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const fireBaseBackend = getFirebaseBackend();

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      );
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      });
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeProfile, user);
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* onEditImageProfile({ payload: user }) {
  try {
    const response = yield call(updateImageProfile, user);
    yield put(editProfileSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(editProfileFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

export function* watchEditImageProfile() {
  yield takeEvery(EDIT_IMAGE_PROFILE, onEditImageProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile), fork(watchEditImageProfile)]);
}

export default ProfileSaga;
