import {
  PAYMENTTERM_LOADING,
  GET_PAYMENTTERM,
  GET_PAYMENTTERM_SUCCESS,
  GET_PAYMENTTERM_FAIL,
  ADD_PAYMENTTERM_SUCCESS,
  ADD_PAYMENTTERM_FAIL,
  DELETE_PAYMENTTERM_SUCCESS,
  DELETE_PAYMENTTERM_FAIL,
  UPDATE_PAYMENTTERM_SUCCESS,
  UPDATE_PAYMENTTERM_FAIL,
  PAYMENTTERM_RESET
} from "./actionType";

const INIT_STATE = {
  paymentTerms: [],
  error: {},
};

const PaymentTerm = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTTERM_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PAYMENTTERM:
          return {
            ...state,
            paymentTerms: action.payload.data,
            isPaymentTermCreated: false,
            isPaymentTermSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_PAYMENTTERM_FAIL:
      switch (action.payload.actionType) {
        case GET_PAYMENTTERM_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isPaymentTermCreated: false,
            isPaymentTermSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_PAYMENTTERM: {
      return {
        ...state,
        isPaymentTermCreated: false,
        loading: true,
      };
    }

    case PAYMENTTERM_LOADING: {
      return {
        ...state,
        isPaymentTermCreated: false,
        loading: true,
      };
    }

    case ADD_PAYMENTTERM_SUCCESS:
      return {
        ...state,
        isPaymentTermCreated: true,
        loading: false,
        paymentTerms: [...state.paymentTerms, action.payload.data],
      };

    case ADD_PAYMENTTERM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PAYMENTTERM_SUCCESS:
      return {
        ...state,
        loading: false,
        isPaymentTermCreated: true,
        paymentTerms: state.paymentTerms.filter(
          paymentTerm => paymentTerm.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PAYMENTTERM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PAYMENTTERM_SUCCESS:
      return {
        ...state,
        loading: false,
        isPaymentTermCreated: true,
        paymentTerms: state.paymentTerms.map(paymentTerm =>
          paymentTerm.id.toString() === action.payload.data.id.toString() ? { ...paymentTerm, ...action.payload.data } : paymentTerm
        ),
      };

    case UPDATE_PAYMENTTERM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTTERM_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default PaymentTerm;