export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const DASHBOARD_RESET = "DASHBOARD_RESET";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";
export const DELETE_DASHBOARD = "DELETE_DASHBOARD"
export const DELETE_DASHBOARD_SUCCESS = "DELETE_DASHBOARD_SUCCESS"
export const DELETE_DASHBOARD_FAIL = "DELETE_DASHBOARD_FAIL"
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD"
export const UPDATE_DASHBOARD_SUCCESS = "UPDATE_DASHBOARD_SUCCESS"
export const UPDATE_DASHBOARD_FAIL = "UPDATE_DASHBOARD_FAIL"
export const ADD_NEW_DASHBOARD = "ADD_NEW_DASHBOARD"
export const ADD_DASHBOARD_SUCCESS = "ADD_DASHBOARD_SUCCESS"
export const ADD_DASHBOARD_FAIL = "ADD_DASHBOARD_FAIL"