import {
  ASSET_LOADING,
  GET_ASSET,
  GET_ASSET_SUCCESS,
  GET_ASSET_FAIL,
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAIL,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAIL,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,
  ASSET_RESET,
  ASSET_RESET_CREATE
} from "./actionType";

const INIT_STATE = {
  assets: [],
  assetAdded: {},
  error: {},
};

const Asset = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSET_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ASSET:
          return {
            ...state,
            assets: action.payload.data,
            isAssetCreated: false,
            isAssetSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ASSET_FAIL:
      switch (action.payload.actionType) {
        case GET_ASSET_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAssetCreated: false,
            isAssetSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ASSET: {
      return {
        ...state,
        isAssetCreated: false,
        loading: true,
      };
    }

    case ASSET_LOADING: {
      return {
        ...state,
        isAssetCreated: false,
        loading: true,
      };
    }

    case ADD_ASSET_SUCCESS:
      return {
        ...state,
        isAssetCreated: true,
        loading: false,
        assets: [...state.assets, action.payload.data],
        assetAdded: action.payload.data,
      };

    case ADD_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        assets: state.assets.filter(
          asset => asset.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        assets: state.assets.map(asset =>
          asset.id.toString() === action.payload.data.id.toString() ? { ...asset, ...action.payload.data } : asset
        ),
      };

    case UPDATE_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ASSET_RESET_CREATE:
      return {
        ...state,
        isAssetCreated: false,
      };

    case ASSET_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Asset;