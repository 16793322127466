import {
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  UPDATE_NEWS,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  ADD_NEW_NEWS,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_FAIL,
  NEWS_LOADING,
  NEWS_RESET,
} from "./actionType";

export const resetNewsState = (actionType) => ({
  type: NEWS_RESET,
  payload: { actionType },
});

export const getNewsSuccess = (actionType, data) => ({
  type: GET_NEWS_SUCCESS,
  payload: { actionType, data },
});

export const getNewsFail = (actionType, error) => ({
  type: GET_NEWS_FAIL,
  payload: { actionType, error },
});

export const getNews = data => ({
  type: GET_NEWS,
  payload: data,
});

export const getNewsLoading = () => ({
  type: NEWS_LOADING
});

export const deleteNews = data => ({
  type: DELETE_NEWS,
  payload: data,
});

export const deleteNewsSuccess = data => ({
  type: DELETE_NEWS_SUCCESS,
  payload: data,
});

export const deleteNewsFail = error => ({
  type: DELETE_NEWS_FAIL,
  payload: error,
});

export const updateNews = data => ({
  type: UPDATE_NEWS,
  payload: data,
});

export const updateNewsFail = error => ({
  type: UPDATE_NEWS_FAIL,
  payload: error,
});

export const updateNewsSuccess = data => ({
  type: UPDATE_NEWS_SUCCESS,
  payload: data,
});

export const addNewNews = data => ({
  type: ADD_NEW_NEWS,
  payload: data,
});

export const addNewsSuccess = data => ({
  type: ADD_NEWS_SUCCESS,
  payload: data,
});

export const addNewsFail = error => ({
  type: ADD_NEWS_FAIL,
  payload: error,
});