import {
  GET_ASSETCATEGORY,
  GET_ASSETCATEGORY_SUCCESS,
  GET_ASSETCATEGORY_FAIL,
  DELETE_ASSETCATEGORY,
  DELETE_ASSETCATEGORY_SUCCESS,
  DELETE_ASSETCATEGORY_FAIL,
  UPDATE_ASSETCATEGORY,
  UPDATE_ASSETCATEGORY_SUCCESS,
  UPDATE_ASSETCATEGORY_FAIL,
  ADD_NEW_ASSETCATEGORY,
  ADD_ASSETCATEGORY_SUCCESS,
  ADD_ASSETCATEGORY_FAIL,
  ASSETCATEGORY_LOADING,
  ASSETCATEGORY_RESET
} from "./actionType";

export const resetAssetCategoryState = (actionType) => ({
  type: ASSETCATEGORY_RESET,
  payload: { actionType },
});

export const getAssetCategorySuccess = (actionType, data) => ({
  type: GET_ASSETCATEGORY_SUCCESS,
  payload: { actionType, data },
});

export const getAssetCategoryFail = (actionType, error) => ({
  type: GET_ASSETCATEGORY_FAIL,
  payload: { actionType, error },
});

export const getAssetCategory = data => ({
  type: GET_ASSETCATEGORY,
  payload: data,
});

export const getAssetCategoryLoading = () => ({
  type: ASSETCATEGORY_LOADING
});

export const deleteAssetCategory = data => ({
  type: DELETE_ASSETCATEGORY,
  payload: data,
});

export const deleteAssetCategorySuccess = data => ({
  type: DELETE_ASSETCATEGORY_SUCCESS,
  payload: data,
});

export const deleteAssetCategoryFail = error => ({
  type: DELETE_ASSETCATEGORY_FAIL,
  payload: error,
});

export const updateAssetCategory = data => ({
  type: UPDATE_ASSETCATEGORY,
  payload: data,
});

export const updateAssetCategoryFail = error => ({
  type: UPDATE_ASSETCATEGORY_FAIL,
  payload: error,
});

export const updateAssetCategorySuccess = data => ({
  type: UPDATE_ASSETCATEGORY_SUCCESS,
  payload: data,
});

export const addNewAssetCategory = data => ({
  type: ADD_NEW_ASSETCATEGORY,
  payload: data,
});

export const addAssetCategorySuccess = data => ({
  type: ADD_ASSETCATEGORY_SUCCESS,
  payload: data,
});

export const addAssetCategoryFail = error => ({
  type: ADD_ASSETCATEGORY_FAIL,
  payload: error,
});