import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_FAIL,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_FAIL,
  ADD_NEW_DASHBOARD,
  ADD_DASHBOARD_SUCCESS,
  ADD_DASHBOARD_FAIL,
  DASHBOARD_LOADING,
  DASHBOARD_RESET,
} from "./actionType";

export const resetDashboardState = (actionType) => ({
  type: DASHBOARD_RESET,
  payload: { actionType },
});

export const getDashboardSuccess = (actionType, data) => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: { actionType, data },
});

export const getDashboardFail = (actionType, error) => ({
  type: GET_DASHBOARD_FAIL,
  payload: { actionType, error },
});

export const getDashboard = data => ({
  type: GET_DASHBOARD,
  payload: data,
});

export const getDashboardLoading = () => ({
  type: DASHBOARD_LOADING
});

export const deleteDashboard = data => ({
  type: DELETE_DASHBOARD,
  payload: data,
});

export const deleteDashboardSuccess = data => ({
  type: DELETE_DASHBOARD_SUCCESS,
  payload: data,
});

export const deleteDashboardFail = error => ({
  type: DELETE_DASHBOARD_FAIL,
  payload: error,
});

export const updateDashboard = data => ({
  type: UPDATE_DASHBOARD,
  payload: data,
});

export const updateDashboardFail = error => ({
  type: UPDATE_DASHBOARD_FAIL,
  payload: error,
});

export const updateDashboardSuccess = data => ({
  type: UPDATE_DASHBOARD_SUCCESS,
  payload: data,
});

export const addNewDashboard = data => ({
  type: ADD_NEW_DASHBOARD,
  payload: data,
});

export const addDashboardSuccess = data => ({
  type: ADD_DASHBOARD_SUCCESS,
  payload: data,
});

export const addDashboardFail = error => ({
  type: ADD_DASHBOARD_FAIL,
  payload: error,
});