import {
  DASHBOARD_LOADING,
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
  ADD_DASHBOARD_SUCCESS,
  ADD_DASHBOARD_FAIL,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_FAIL,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_FAIL,
  DASHBOARD_RESET
} from "./actionType";

const INIT_STATE = {
  dashboards: [],
  dashboardsAll:[],
  error: {},
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DASHBOARD:
          return {
            ...state,
            dashboards: action.payload.data,
            isDashboardCreated: false,
            isDashboardSuccess: true,
            loading: false,
          };
         

        default:
          return { ...state };
      }
    case GET_DASHBOARD_FAIL:
      switch (action.payload.actionType) {
        case GET_DASHBOARD_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDashboardCreated: false,
            isDashboardSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DASHBOARD: {
      return {
        ...state,
        isDashboardCreated: false,
        loading: true,
      };
    }

    case DASHBOARD_LOADING: {
      return {
        ...state,
        isDashboardCreated: false,
        loading: true,
      };
    }

    case ADD_DASHBOARD_SUCCESS:
      return {
        ...state,
        isDashboardCreated: true,
        loading: false,
        dashboards: [...state.dashboards, action.payload.data],
      };

    case ADD_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        isDashboardCreated: true,
        dashboards: state.dashboards.filter(
          dashboard => dashboard.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        isDashboardCreated: true,
        dashboards: state.dashboards.map(dashboard =>
          dashboard.id.toString() === action.payload.data.id.toString() ? { ...dashboard, ...action.payload.data } : dashboard
        ),
      };

    case UPDATE_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DASHBOARD_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Dashboard;