import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_NEWS,
  DELETE_NEWS,
  UPDATE_NEWS,
  ADD_NEW_NEWS,
} from "./actionType";

import {
  getNewsSuccess,
  getNewsFail,
  deleteNewsSuccess,
  deleteNewsFail,
  updateNewsSuccess,
  updateNewsFail,
  addNewsSuccess,
  addNewsFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getNewsApi,
  updateNewsApi,
  deleteNewsApi,
  addNewNewsApi
} from "../../helpers/backend_helper";

function* getNews({ payload: news }) {
  try {
    const response = yield call(getNewsApi, news);
    yield put(getNewsSuccess(GET_NEWS, response.data));
  } catch (error) {
    yield put(getNewsFail(GET_NEWS, error));
  }
}

function* onUpdateNews({ payload: news }) {
  try {
    const response = yield call(updateNewsApi, news);
    yield put(updateNewsSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateNewsFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteNews({ payload: news }) {
  try {
    const response = yield call(deleteNewsApi, news);
    yield put(deleteNewsSuccess({ news, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteNewsFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewNews({ payload: news }) {
  try {
    const response = yield call(addNewNewsApi, news);
    yield put(addNewsSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addNewsFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetNews() {
  yield takeEvery(GET_NEWS, getNews);
}

export function* watchUpdateNews() {
  yield takeEvery(UPDATE_NEWS, onUpdateNews);
}

export function* watchDeleteNews() {
  yield takeEvery(DELETE_NEWS, onDeleteNews);
}

export function* watchAddNewNews() {
  yield takeEvery(ADD_NEW_NEWS, onAddNewNews);
}

function* newsSaga() {
  yield all([
    fork(watchGetNews),
    fork(watchDeleteNews),
    fork(watchUpdateNews),
    fork(watchAddNewNews)
  ]);
}

export default newsSaga;
