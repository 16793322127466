export const ASSETFEATURE_LOADING = "ASSETFEATURE_LOADING";
export const GET_ASSETFEATURE = "GET_ASSETFEATURE";
export const GET_ASSETFEATURE_SUCCESS = "GET_ASSETFEATURE_SUCCESS";
export const GET_ASSETFEATURE_FAIL = "GET_ASSETFEATURE_FAIL";
export const DELETE_ASSETFEATURE = "DELETE_ASSETFEATURE"
export const DELETE_ASSETFEATURE_SUCCESS = "DELETE_ASSETFEATURE_SUCCESS"
export const DELETE_ASSETFEATURE_FAIL = "DELETE_ASSETFEATURE_FAIL"
export const UPDATE_ASSETFEATURE = "UPDATE_ASSETFEATURE"
export const UPDATE_ASSETFEATURE_SUCCESS = "UPDATE_ASSETFEATURE_SUCCESS"
export const UPDATE_ASSETFEATURE_FAIL = "UPDATE_ASSETFEATURE_FAIL"
export const ADD_NEW_ASSETFEATURE = "ADD_NEW_ASSETFEATURE"
export const ADD_ASSETFEATURE_SUCCESS = "ADD_ASSETFEATURE_SUCCESS"
export const ADD_ASSETFEATURE_FAIL = "ADD_ASSETFEATURE_FAIL"