import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Mail from "./mail/reducer";

// Address
import Province from "./province/reducer";
import City from "./city/reducer";
import District from "./district/reducer";
import Village from "./village/reducer";
import Address from "./address/reducer";

//Bank
import Bank from "./bank/reducer";

import Identity from "./identity/reducer";
import IdentityType from "./identityType/reducer";

// Unit
import Client from "./client/reducer";

//Files
import File from "./file/reducer";
import Folder from "./folder/reducer";

// hr
import User from "./users/reducer";

//Inventory
import Asset from "./asset/reducer";
import AssetTag from "./assetTag/reducer";
import AssetFeature from "./assetFeature/reducer";
import AssetGroup from "./assetGroup/reducer";
import AssetCategory from "./assetCategory/reducer";
import AssetClass from "./assetClass/reducer";
import AssetLocation from "./assetLocation/reducer";

import PaymentMethod from "./paymentMethod/reducer";
import PaymentTerm from "./paymentTerm/reducer";
import TransactionTax from "./transactionTax/reducer";

//Customer
import Customer from "./customer/reducer";

import Dashboard from "./dashboard/reducer";
import News from "./news/reducer";
import Banner from "./banner/reducer";
import Content from "./content/reducer";

const rootReducer = combineReducers({
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Mail,
    Dashboard,
    Client,
    Address,
    Province,
    City,
    District,
    Village,
    Bank,
    Identity,
    IdentityType,
    Folder,
    File,
    User,

    Asset,
    AssetTag,
    AssetFeature,
    AssetGroup,
    AssetCategory,
    AssetClass,
    AssetLocation,

    PaymentMethod,
    PaymentTerm,
    TransactionTax,

    Customer,
    News,
    Banner,
    Content
});

export default rootReducer;