import {
  ASSETGROUP_LOADING,
  GET_ASSETGROUP,
  GET_ASSETGROUP_SUCCESS,
  GET_ASSETGROUP_FAIL,
  ADD_ASSETGROUP_SUCCESS,
  ADD_ASSETGROUP_FAIL,
  DELETE_ASSETGROUP_SUCCESS,
  DELETE_ASSETGROUP_FAIL,
  UPDATE_ASSETGROUP_SUCCESS,
  UPDATE_ASSETGROUP_FAIL
} from "./actionType";

const INIT_STATE = {
  assetGroups: [],
  error: {},
};

const AssetGroup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETGROUP_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ASSETGROUP:
          return {
            ...state,
            assetGroups: action.payload.data,
            isAssetGroupCreated: false,
            isAssetGroupSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ASSETGROUP_FAIL:
      switch (action.payload.actionType) {
        case GET_ASSETGROUP_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAssetGroupCreated: false,
            isAssetGroupSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ASSETGROUP: {
      return {
        ...state,
        isAssetGroupCreated: false,
        loading: true,
      };
    }

    case ASSETGROUP_LOADING: {
      return {
        ...state,
        isAssetGroupCreated: false,
        loading: true,
      };
    }

    case ADD_ASSETGROUP_SUCCESS:
      return {
        ...state,
        isAssetGroupCreated: true,
        loading: false,
        assetGroups: [...state.assetGroups, action.payload.data],
      };

    case ADD_ASSETGROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ASSETGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        assetGroups: state.assetGroups.filter(
          assetGroup => assetGroup.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ASSETGROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ASSETGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        assetGroups: state.assetGroups.map(assetGroup =>
          assetGroup.id.toString() === action.payload.data.id.toString() ? { ...assetGroup, ...action.payload.data } : assetGroup
        ),
      };

    case UPDATE_ASSETGROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default AssetGroup;