export const ASSETCATEGORY_LOADING = "ASSETCATEGORY_LOADING";
export const ASSETCATEGORY_RESET = "ASSETCATEGORY_RESET";
export const GET_ASSETCATEGORY = "GET_ASSETCATEGORY";
export const GET_ASSETCATEGORY_SUCCESS = "GET_ASSETCATEGORY_SUCCESS";
export const GET_ASSETCATEGORY_FAIL = "GET_ASSETCATEGORY_FAIL";
export const DELETE_ASSETCATEGORY = "DELETE_ASSETCATEGORY"
export const DELETE_ASSETCATEGORY_SUCCESS = "DELETE_ASSETCATEGORY_SUCCESS"
export const DELETE_ASSETCATEGORY_FAIL = "DELETE_ASSETCATEGORY_FAIL"
export const UPDATE_ASSETCATEGORY = "UPDATE_ASSETCATEGORY"
export const UPDATE_ASSETCATEGORY_SUCCESS = "UPDATE_ASSETCATEGORY_SUCCESS"
export const UPDATE_ASSETCATEGORY_FAIL = "UPDATE_ASSETCATEGORY_FAIL"
export const ADD_NEW_ASSETCATEGORY = "ADD_NEW_ASSETCATEGORY"
export const ADD_ASSETCATEGORY_SUCCESS = "ADD_ASSETCATEGORY_SUCCESS"
export const ADD_ASSETCATEGORY_FAIL = "ADD_ASSETCATEGORY_FAIL"