import {
  GET_ASSETTAG,
  GET_ASSETTAG_SUCCESS,
  GET_ASSETTAG_FAIL,
  DELETE_ASSETTAG,
  DELETE_ASSETTAG_SUCCESS,
  DELETE_ASSETTAG_FAIL,
  UPDATE_ASSETTAG,
  UPDATE_ASSETTAG_SUCCESS,
  UPDATE_ASSETTAG_FAIL,
  ADD_NEW_ASSETTAG,
  ADD_ASSETTAG_SUCCESS,
  ADD_ASSETTAG_FAIL,
  ASSETTAG_LOADING,
} from "./actionType";

export const getAssetTagSuccess = (actionType, data) => ({
  type: GET_ASSETTAG_SUCCESS,
  payload: { actionType, data },
});

export const getAssetTagFail = (actionType, error) => ({
  type: GET_ASSETTAG_FAIL,
  payload: { actionType, error },
});

export const getAssetTag = data => ({
  type: GET_ASSETTAG,
  payload: data,
});

export const getAssetTagLoading = () => ({
  type: ASSETTAG_LOADING
});

export const deleteAssetTag = data => ({
  type: DELETE_ASSETTAG,
  payload: data,
});

export const deleteAssetTagSuccess = data => ({
  type: DELETE_ASSETTAG_SUCCESS,
  payload: data,
});

export const deleteAssetTagFail = error => ({
  type: DELETE_ASSETTAG_FAIL,
  payload: error,
});

export const updateAssetTag = data => ({
  type: UPDATE_ASSETTAG,
  payload: data,
});

export const updateAssetTagFail = error => ({
  type: UPDATE_ASSETTAG_FAIL,
  payload: error,
});

export const updateAssetTagSuccess = data => ({
  type: UPDATE_ASSETTAG_SUCCESS,
  payload: data,
});

export const addNewAssetTag = data => ({
  type: ADD_NEW_ASSETTAG,
  payload: data,
});

export const addAssetTagSuccess = data => ({
  type: ADD_ASSETTAG_SUCCESS,
  payload: data,
});

export const addAssetTagFail = error => ({
  type: ADD_ASSETTAG_FAIL,
  payload: error,
});