import {
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_FAIL,
  DELETE_CITY,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAIL,
  UPDATE_CITY,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  ADD_NEW_CITY,
  ADD_CITY_SUCCESS,
  ADD_CITY_FAIL,
  CITY_LOADING,
} from "./actionType";

export const getCitySuccess = (actionType, data) => ({
  type: GET_CITY_SUCCESS,
  payload: { actionType, data },
});

export const getCityFail = (actionType, error) => ({
  type: GET_CITY_FAIL,
  payload: { actionType, error },
});

export const getCity = data => ({
  type: GET_CITY,
  payload: data,
});

export const getCityLoading = () => ({
  type: CITY_LOADING
});

export const deleteCity = data => ({
  type: DELETE_CITY,
  payload: data,
});

export const deleteCitySuccess = data => ({
  type: DELETE_CITY_SUCCESS,
  payload: data,
});

export const deleteCityFail = error => ({
  type: DELETE_CITY_FAIL,
  payload: error,
});

export const updateCity = data => ({
  type: UPDATE_CITY,
  payload: data,
});

export const updateCityFail = error => ({
  type: UPDATE_CITY_FAIL,
  payload: error,
});

export const updateCitySuccess = data => ({
  type: UPDATE_CITY_SUCCESS,
  payload: data,
});

export const addNewCity = data => ({
  type: ADD_NEW_CITY,
  payload: data,
});

export const addCitySuccess = data => ({
  type: ADD_CITY_SUCCESS,
  payload: data,
});

export const addCityFail = error => ({
  type: ADD_CITY_FAIL,
  payload: error,
});