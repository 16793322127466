import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ASSETLOCATION,
  DELETE_ASSETLOCATION,
  UPDATE_ASSETLOCATION,
  ADD_NEW_ASSETLOCATION,
} from "./actionType";

import {
  getAssetLocationSuccess,
  getAssetLocationFail,
  deleteAssetLocationSuccess,
  deleteAssetLocationFail,
  updateAssetLocationSuccess,
  updateAssetLocationFail,
  addAssetLocationSuccess,
  addAssetLocationFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getAssetLocationApi,
  updateAssetLocationApi,
  deleteAssetLocationApi,
  addNewAssetLocationApi
} from "../../helpers/backend_helper";

function* getAssetLocation({ payload: assetLocation }) {
  try {
    const response = yield call(getAssetLocationApi, assetLocation);
    yield put(getAssetLocationSuccess(GET_ASSETLOCATION, response.data));
  } catch (error) {
    yield put(getAssetLocationFail(GET_ASSETLOCATION, error));
  }
}

function* onUpdateAssetLocation({ payload: assetLocation }) {
  try {
    const response = yield call(updateAssetLocationApi, assetLocation);
    yield put(updateAssetLocationSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateAssetLocationFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAssetLocation({ payload: assetLocation }) {
  try {
    const response = yield call(deleteAssetLocationApi, assetLocation);
    yield put(deleteAssetLocationSuccess({ assetLocation, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAssetLocationFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAssetLocation({ payload: assetLocation }) {
  try {
    const response = yield call(addNewAssetLocationApi, assetLocation);
    yield put(addAssetLocationSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAssetLocationFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAssetLocation() {
  yield takeEvery(GET_ASSETLOCATION, getAssetLocation);
}

export function* watchUpdateAssetLocation() {
  yield takeEvery(UPDATE_ASSETLOCATION, onUpdateAssetLocation);
}

export function* watchDeleteAssetLocation() {
  yield takeEvery(DELETE_ASSETLOCATION, onDeleteAssetLocation);
}

export function* watchAddNewAssetLocation() {
  yield takeEvery(ADD_NEW_ASSETLOCATION, onAddNewAssetLocation);
}

function* assetLocationSaga() {
  yield all([
    fork(watchGetAssetLocation),
    fork(watchDeleteAssetLocation),
    fork(watchUpdateAssetLocation),
    fork(watchAddNewAssetLocation)
  ]);
}

export default assetLocationSaga;
