import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ASSETFEATURE,
  DELETE_ASSETFEATURE,
  UPDATE_ASSETFEATURE,
  ADD_NEW_ASSETFEATURE,
} from "./actionType";

import {
  getAssetFeatureSuccess,
  getAssetFeatureFail,
  deleteAssetFeatureSuccess,
  deleteAssetFeatureFail,
  updateAssetFeatureSuccess,
  updateAssetFeatureFail,
  addAssetFeatureSuccess,
  addAssetFeatureFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getAssetFeatureApi,
  updateAssetFeatureApi,
  deleteAssetFeatureApi,
  addNewAssetFeatureApi
} from "../../helpers/backend_helper";

function* getAssetFeature({ payload: assetFeature }) {
  try {
    const response = yield call(getAssetFeatureApi, assetFeature);
    yield put(getAssetFeatureSuccess(GET_ASSETFEATURE, response.data));
  } catch (error) {
    yield put(getAssetFeatureFail(GET_ASSETFEATURE, error));
  }
}

function* onUpdateAssetFeature({ payload: assetFeature }) {
  try {
    const response = yield call(updateAssetFeatureApi, assetFeature);
    yield put(updateAssetFeatureSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateAssetFeatureFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAssetFeature({ payload: assetFeature }) {
  try {
    const response = yield call(deleteAssetFeatureApi, assetFeature);
    yield put(deleteAssetFeatureSuccess({ assetFeature, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAssetFeatureFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAssetFeature({ payload: assetFeature }) {
  try {
    const response = yield call(addNewAssetFeatureApi, assetFeature);
    yield put(addAssetFeatureSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAssetFeatureFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAssetFeature() {
  yield takeEvery(GET_ASSETFEATURE, getAssetFeature);
}

export function* watchUpdateAssetFeature() {
  yield takeEvery(UPDATE_ASSETFEATURE, onUpdateAssetFeature);
}

export function* watchDeleteAssetFeature() {
  yield takeEvery(DELETE_ASSETFEATURE, onDeleteAssetFeature);
}

export function* watchAddNewAssetFeature() {
  yield takeEvery(ADD_NEW_ASSETFEATURE, onAddNewAssetFeature);
}

function* assetFeatureSaga() {
  yield all([
    fork(watchGetAssetFeature),
    fork(watchDeleteAssetFeature),
    fork(watchUpdateAssetFeature),
    fork(watchAddNewAssetFeature)
  ]);
}

export default assetFeatureSaga;
