import {
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAIL,
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAIL,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  ADD_NEW_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAIL,
  ADDRESS_LOADING,
} from "./actionType";

export const getAddressSuccess = (actionType, data) => ({
  type: GET_ADDRESS_SUCCESS,
  payload: { actionType, data },
});

export const getAddressFail = (actionType, error) => ({
  type: GET_ADDRESS_FAIL,
  payload: { actionType, error },
});

export const getAddress = (data) => ({
  type: GET_ADDRESS,
  payload: data,
});

export const getAddressLoading = () => ({
  type: ADDRESS_LOADING,
});

export const deleteAddress = (data) => ({
  type: DELETE_ADDRESS,
  payload: data,
});

export const deleteAddressSuccess = (data) => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload: data,
});

export const deleteAddressFail = (error) => ({
  type: DELETE_ADDRESS_FAIL,
  payload: error,
});

export const updateAddress = (data) => ({
  type: UPDATE_ADDRESS,
  payload: data,
});

export const updateAddressFail = (error) => ({
  type: UPDATE_ADDRESS_FAIL,
  payload: error,
});

export const updateAddressSuccess = (data) => ({
  type: UPDATE_ADDRESS_SUCCESS,
  payload: data,
});

export const addNewAddress = (data) => ({
  type: ADD_NEW_ADDRESS,
  payload: data,
});

export const addAddressSuccess = (data) => ({
  type: ADD_ADDRESS_SUCCESS,
  payload: data,
});

export const addAddressFail = (error) => ({
  type: ADD_ADDRESS_FAIL,
  payload: error,
});
