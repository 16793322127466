import {
  ASSETTAG_LOADING,
  GET_ASSETTAG,
  GET_ASSETTAG_SUCCESS,
  GET_ASSETTAG_FAIL,
  ADD_ASSETTAG_SUCCESS,
  ADD_ASSETTAG_FAIL,
  DELETE_ASSETTAG_SUCCESS,
  DELETE_ASSETTAG_FAIL,
  UPDATE_ASSETTAG_SUCCESS,
  UPDATE_ASSETTAG_FAIL
} from "./actionType";

const INIT_STATE = {
  assetTags: [],
  error: {},
};

const AssetTag = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETTAG_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ASSETTAG:
          return {
            ...state,
            assetTags: action.payload.data,
            isAssetTagCreated: false,
            isAssetTagSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ASSETTAG_FAIL:
      switch (action.payload.actionType) {
        case GET_ASSETTAG_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAssetTagCreated: false,
            isAssetTagSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ASSETTAG: {
      return {
        ...state,
        isAssetTagCreated: false,
        loading: true,
      };
    }

    case ASSETTAG_LOADING: {
      return {
        ...state,
        isAssetTagCreated: false,
        loading: true,
      };
    }

    case ADD_ASSETTAG_SUCCESS:
      return {
        ...state,
        isAssetTagCreated: true,
        loading: false,
        assetTags: [...state.assetTags, action.payload.data],
      };

    case ADD_ASSETTAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ASSETTAG_SUCCESS:
      return {
        ...state,
        loading: false,
        assetTags: state.assetTags.filter(
          assetTag => assetTag.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ASSETTAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ASSETTAG_SUCCESS:
      return {
        ...state,
        loading: false,
        assetTags: state.assetTags.map(assetTag =>
          assetTag.id.toString() === action.payload.data.id.toString() ? { ...assetTag, ...action.payload.data } : assetTag
        ),
      };

    case UPDATE_ASSETTAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default AssetTag;