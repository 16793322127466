import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// Import Images
import logolight from "../assets/images/logo-light.png";
import tiktok from "../assets/images/tiktok.png";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="custom-footer bg-dark py-5 position-relative">
                <Container>
                    <Row>
                        <Col lg={7} className="mt-4">
                            <div>
                                <div>
                                    <img src={logolight} alt="logo light" height="80" />
                                </div>
                                <div className="mt-4">
                                    <p>PT. Abdi Properti Indonesia</p>
                                    <p className="ff-secondary">Mengelola aset Investasi Properti Melalui Jual & Sewa Apartemen.</p>

                                </div>
                            </div>
                        </Col>

                        <Col lg={4} className="ms-lg-auto">
                            <Row>
                                <Col sm={6} className="mt-4">
                                    <h5 className="text-white mb-0">Company</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><Link to="/about">About Us</Link></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col sm={6} className="mt-4">
                                    <h5 className="text-white mb-0">Apps Pages</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li className="nav-item"><Link to="/property">View Property</Link></li>
                                            <li className="nav-item"><Link to="/location">Locations</Link></li>
                                            <li className="nav-item"><Link to="/landlord">Landlords</Link></li>
                                            <li className="nav-item"><Link to="/promo">Promotion</Link></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                    <Row className="text-center text-sm-start align-items-center mt-5">
                        <Col sm={6}>

                            <div>
                                <p className="copy-rights mb-0">
                                    {new Date().getFullYear()} © PT. Abdi Properti Indonesia
                                </p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end mt-3 mt-sm-0">
                                <ul className="list-inline mb-0 footer-social-link">
                                    <li className="list-inline-item">
                                        <Link to="https://www.instagram.com/abdihomeid/" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-instagram-line"></i>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="https://www.youtube.com/@abdihome3909" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-youtube-line"></i>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="https://www.tiktok.com/@abdihome" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <img className="avatar-xs" style={{ height: "14px", width : "14px" }} src={tiktok} alt="t" />
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment >
    );
};

export default Footer;