// Layout
export * from "./layouts/action";
// Authentication module
export * from "./auth/register/actions";
export * from "./auth/login/actions";
export * from "./auth/forgetpwd/actions";
export * from "./auth/profile/actions";

export * from "./dashboard/action";
export * from "./client/action";
export * from "./mail/action";

export * from "./province/action";
export * from "./city/action";
export * from "./district/action";
export * from "./village/action";
export * from "./address/action";
export * from "./bank/action";
export * from "./identityType/action";
export * from "./identity/action";

export * from "./file/action";
export * from "./folder/action";


export * from "./users/action";

//Asset
export * from "./asset/action";
export * from "./assetTag/action";
export * from "./assetFeature/action";
export * from "./assetGroup/action";
export * from "./assetClass/action";
export * from "./assetCategory/action";
export * from "./assetLocation/action";

////TRANSACTION
export * from "./paymentMethod/action";
export * from "./paymentTerm/action";
export * from "./transactionTax/action";

//Customer
export * from "./customer/action";

//Users
export * from "./news/action"
export * from "./banner/action"
export * from "./content/action"