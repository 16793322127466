import {
  GET_PAYMENTMETHOD,
  GET_PAYMENTMETHOD_SUCCESS,
  GET_PAYMENTMETHOD_FAIL,
  DELETE_PAYMENTMETHOD,
  DELETE_PAYMENTMETHOD_SUCCESS,
  DELETE_PAYMENTMETHOD_FAIL,
  UPDATE_PAYMENTMETHOD,
  UPDATE_PAYMENTMETHOD_SUCCESS,
  UPDATE_PAYMENTMETHOD_FAIL,
  ADD_NEW_PAYMENTMETHOD,
  ADD_PAYMENTMETHOD_SUCCESS,
  ADD_PAYMENTMETHOD_FAIL,
  PAYMENTMETHOD_LOADING,
  PAYMENTMETHOD_RESET,
} from "./actionType";

export const resetPaymentMethodState = (actionType) => ({
  type: PAYMENTMETHOD_RESET,
  payload: { actionType },
});

export const getPaymentMethodSuccess = (actionType, data) => ({
  type: GET_PAYMENTMETHOD_SUCCESS,
  payload: { actionType, data },
});

export const getPaymentMethodFail = (actionType, error) => ({
  type: GET_PAYMENTMETHOD_FAIL,
  payload: { actionType, error },
});

export const getPaymentMethod = data => ({
  type: GET_PAYMENTMETHOD,
  payload: data,
});

export const getPaymentMethodLoading = () => ({
  type: PAYMENTMETHOD_LOADING
});

export const deletePaymentMethod = data => ({
  type: DELETE_PAYMENTMETHOD,
  payload: data,
});

export const deletePaymentMethodSuccess = data => ({
  type: DELETE_PAYMENTMETHOD_SUCCESS,
  payload: data,
});

export const deletePaymentMethodFail = error => ({
  type: DELETE_PAYMENTMETHOD_FAIL,
  payload: error,
});

export const updatePaymentMethod = data => ({
  type: UPDATE_PAYMENTMETHOD,
  payload: data,
});

export const updatePaymentMethodFail = error => ({
  type: UPDATE_PAYMENTMETHOD_FAIL,
  payload: error,
});

export const updatePaymentMethodSuccess = data => ({
  type: UPDATE_PAYMENTMETHOD_SUCCESS,
  payload: data,
});

export const addNewPaymentMethod = data => ({
  type: ADD_NEW_PAYMENTMETHOD,
  payload: data,
});

export const addPaymentMethodSuccess = data => ({
  type: ADD_PAYMENTMETHOD_SUCCESS,
  payload: data,
});

export const addPaymentMethodFail = error => ({
  type: ADD_PAYMENTMETHOD_FAIL,
  payload: error,
});