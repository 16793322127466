import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_IDENTITY,
  DELETE_IDENTITY,
  UPDATE_IDENTITY,
  ADD_NEW_IDENTITY,
} from "./actionType";

import {
  getIdentitySuccess,
  getIdentityFail,
  deleteIdentitySuccess,
  deleteIdentityFail,
  updateIdentitySuccess,
  updateIdentityFail,
  addIdentitySuccess,
  addIdentityFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getIdentityApi,
  updateIdentityApi,
  deleteIdentityApi,
  addNewIdentityApi
} from "../../helpers/backend_helper";

function* getIdentity({ payload: identity }) {
  try {
    const response = yield call(getIdentityApi, identity);
    yield put(getIdentitySuccess(GET_IDENTITY, response.data));
  } catch (error) {
    yield put(getIdentityFail(GET_IDENTITY, error));
  }
}

function* onUpdateIdentity({ payload: identity }) {
  try {
    const response = yield call(updateIdentityApi, identity);
    yield put(updateIdentitySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateIdentityFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteIdentity({ payload: identity }) {
  try {
    const response = yield call(deleteIdentityApi, identity);
    yield put(deleteIdentitySuccess({ identity, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteIdentityFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewIdentity({ payload: identity }) {
  try {
    const response = yield call(addNewIdentityApi, identity);
    yield put(addIdentitySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addIdentityFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetIdentity() {
  yield takeEvery(GET_IDENTITY, getIdentity);
}

export function* watchUpdateIdentity() {
  yield takeEvery(UPDATE_IDENTITY, onUpdateIdentity);
}

export function* watchDeleteIdentity() {
  yield takeEvery(DELETE_IDENTITY, onDeleteIdentity);
}

export function* watchAddNewIdentity() {
  yield takeEvery(ADD_NEW_IDENTITY, onAddNewIdentity);
}

function* identitySaga() {
  yield all([
    fork(watchGetIdentity),
    fork(watchDeleteIdentity),
    fork(watchUpdateIdentity),
    fork(watchAddNewIdentity)
  ]);
}

export default identitySaga;
