export const FOLDER_LOADING = "FOLDER_LOADING";
export const FOLDER_RESET = "FOLDER_RESET";
export const GET_FOLDER = "GET_FOLDER";
export const GET_FOLDER_SUCCESS = "GET_FOLDER_SUCCESS";
export const GET_FOLDER_FAIL = "GET_FOLDER_FAIL";
export const DELETE_FOLDER = "DELETE_FOLDER"
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS"
export const DELETE_FOLDER_FAIL = "DELETE_FOLDER_FAIL"
export const UPDATE_FOLDER = "UPDATE_FOLDER"
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS"
export const UPDATE_FOLDER_FAIL = "UPDATE_FOLDER_FAIL"
export const ADD_NEW_FOLDER = "ADD_NEW_FOLDER"
export const ADD_FOLDER_SUCCESS = "ADD_FOLDER_SUCCESS"
export const ADD_FOLDER_FAIL = "ADD_FOLDER_FAIL"