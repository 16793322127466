import {
  GET_ASSETCLASS,
  GET_ASSETCLASS_SUCCESS,
  GET_ASSETCLASS_FAIL,
  DELETE_ASSETCLASS,
  DELETE_ASSETCLASS_SUCCESS,
  DELETE_ASSETCLASS_FAIL,
  UPDATE_ASSETCLASS,
  UPDATE_ASSETCLASS_SUCCESS,
  UPDATE_ASSETCLASS_FAIL,
  ADD_NEW_ASSETCLASS,
  ADD_ASSETCLASS_SUCCESS,
  ADD_ASSETCLASS_FAIL,
  ASSETCLASS_LOADING,
} from "./actionType";

export const getAssetClassSuccess = (actionType, data) => ({
  type: GET_ASSETCLASS_SUCCESS,
  payload: { actionType, data },
});

export const getAssetClassFail = (actionType, error) => ({
  type: GET_ASSETCLASS_FAIL,
  payload: { actionType, error },
});

export const getAssetClass = data => ({
  type: GET_ASSETCLASS,
  payload: data,
});

export const getAssetClassLoading = () => ({
  type: ASSETCLASS_LOADING
});

export const deleteAssetClass = data => ({
  type: DELETE_ASSETCLASS,
  payload: data,
});

export const deleteAssetClassSuccess = data => ({
  type: DELETE_ASSETCLASS_SUCCESS,
  payload: data,
});

export const deleteAssetClassFail = error => ({
  type: DELETE_ASSETCLASS_FAIL,
  payload: error,
});

export const updateAssetClass = data => ({
  type: UPDATE_ASSETCLASS,
  payload: data,
});

export const updateAssetClassFail = error => ({
  type: UPDATE_ASSETCLASS_FAIL,
  payload: error,
});

export const updateAssetClassSuccess = data => ({
  type: UPDATE_ASSETCLASS_SUCCESS,
  payload: data,
});

export const addNewAssetClass = data => ({
  type: ADD_NEW_ASSETCLASS,
  payload: data,
});

export const addAssetClassSuccess = data => ({
  type: ADD_ASSETCLASS_SUCCESS,
  payload: data,
});

export const addAssetClassFail = error => ({
  type: ADD_ASSETCLASS_FAIL,
  payload: error,
});