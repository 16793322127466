import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isDashboardOwner, setIsDashboardOwner] = useState(false);
    const [isCorporates, setIsCorporates] = useState(false);
    const [isAddress, setIsAddress] = useState(false);
    const [isReferralData, setIsReferralData] = useState(false);
    const [isStorages, setIsStorages] = useState(false);
    const [isWebContent, setIsWebContent] = useState(false)
    const [isProfiles, setIsProfiles] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isMessage, setIsMessage] = useState(false);
    const [isMasterData, setIsMasterData] = useState(false);

    const [isStudents, setIsStudents] = useState(false);
    const [isStudentsCRM, setIsStudentsCRM] = useState(false);

    const [roles, setRoles] = useState(null);
    const [userType, setUserType] = useState("user");
    const [isAcademics, setIsAcademics] = useState(false);
    const [isAcademicCategories, setIsAcademicCategories] = useState(false);
    const [isAcademicClasses, setIsAcademicClasses] = useState(false);
    const [isKeuangan, setIsKeuangan] = useState(false);

    const [isReport, setIsReport] = useState(false);
    const [isReportHumanResource, setIsReportHumanResource] = useState(false);
    const [isReportFinance, setIsReportFinance] = useState(false);
    const [isReportProduction, setIsReportProduction] = useState(false);
    const [isReportAssetInventory, setIsReportAssetInventory] = useState(false);
    const [isReportAcademic, setIsReportAcademic] = useState(false);
    const [isReportKepengasuhan, setIsReportKepengasuhan] = useState(false);

    const [isHumanResources, setIsHumanResources] = useState(false);
    const [isOrganizations, setIsOrganizations] = useState(false);
    const [isUsers, setIsUsers] = useState(false);
    const [isPayrolls, setIsPayrolls] = useState(false);
    const [isPayrollSchedules, setIsPayrollSchedule] = useState(false);
    const [isKeyPerformance, setIsKeyPerformance] = useState(false);
    const [isKeyPerformanceSub, setIsKeyPerformanceSub] = useState(false);
    const [isHRFinance, setIsHRFinance] = useState(false);
    const [isHRFinanceSub, setIsHRFinanceSub] = useState(false);
    const [isCalendar, setIsCalendar] = useState(false);

    const [isAssets, setIsAssets] = useState(false);
    const [isAssetCategories, setIsAssetCategories] = useState(false);
    const [isVendors, setIsVendors] = useState(false);

    const [isInventories, setIsInventories] = useState(false);
    const [isAssetInventories, setIsAssetInventories] = useState(false);

    const [isFinances, setIsFinances] = useState(false);
    const [isAccounting, setIsAccounting] = useState(false);
    const [isAccountingAccount, setIsAccountingAccount] = useState(false);
    const [isCurrentState, setIsCurrentState] = useState('Dashboard');
    const [isKepengasuhan, setIsKepengasuhan] = useState(false);

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        if (!roles) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            if (obj.data && obj.data.userRole)
                setRoles(obj.userRole);
            else
                setRoles(null);

            if (obj.data && obj.data.userType)
                setUserType(obj.data.userType);
        }

        document.body.classList.remove('twocolumn-panel');
        if (isCurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (isCurrentState !== 'Report') {
            setIsReport(false);
        }
        if (isCurrentState !== 'HumanResources') {
            setIsHumanResources(false);
        }
        if (isCurrentState !== 'Corporates') {
            setIsCorporates(false);
        }
        if (isCurrentState !== 'Storage') {
            setIsStorages(false);
        }
        if (isCurrentState !== 'Tasks') {
            setIsTasks(false);
        }
        if (isCurrentState !== 'Messages') {
            setIsMessage(false);
        }
        if (isCurrentState !== 'Calendar') {
            setIsCalendar(false);
        }
        if (isCurrentState !== 'Assets') {
            setIsAssets(false);
        }
        if (isCurrentState !== 'Inventories') {
            setIsInventories(false);
        }
        if (isCurrentState !== 'Finances') {
            setIsFinances(false);
        }
        if (isCurrentState !== 'Accounting') {
            setIsAccounting(false);
        }
        if (isCurrentState !== 'MasterData') {
            setIsMasterData(false);
        }
        if (isCurrentState !== 'Students') {
            setIsStudents(false);
        }
        if (isCurrentState !== 'Kepengasuhan') {
            setIsKepengasuhan(false);
        }
        if (isCurrentState !== 'Keuangan') {
            setIsKeuangan(false);
        }
        if (isCurrentState !== 'Kepengasuhan') {
            setIsKepengasuhan(false);
        }
    }, [
        history,
        isCurrentState,
        isDashboard,
        isReport,
        isStorages,
        isHumanResources,
        isCorporates,
        isTasks,
        isCalendar,
        isMessage,
        isAssets,
        isFinances,
        isAccounting,
        roles,
        isMasterData,
        isStudents,
        isKeuangan,
        isKepengasuhan,
        isWebContent,
        isKepengasuhan
    ]);
    const menuItems = [
        {
            id: "Dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/dashboard",
            click: function (e) {
                e.preventDefault();
                setIsCurrentState('Widgets');
            },
            stateVariables: isDashboard,
        },
        {
            id: "WebContent",
            label: "Web Content",
            icon: "bx bx-news",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsWebContent(!isWebContent);
                setIsCurrentState('WebContent');
                updateIconSidebar(e);
            },
            stateVariables: isWebContent,
            subItems: [
                {
                    id: "menuNews",
                    label: "News",
                    link: "/news",
                    parentId: "WebContent"
                },
                {
                    id: "menuWebContent",
                    label: "Banner Image",
                    link: "/banner",
                    parentId: "WebContent",
                },
                {
                    id: "menuContent",
                    label: "Static Page",
                    link: "/content",
                    parentId: "WebContent",
                },
            ]
        },
        {
            id: "Corporates",
            label: "Client",
            icon: "ri-briefcase-line",
            link: "/client",
            click: function (e) {
                e.preventDefault();
                setIsCorporates(!isCorporates);
                setIsCurrentState('Corporates');
                updateIconSidebar(e);
            },
            stateVariables: isCorporates,
        },
        {
            id: "Assets",
            label: "Assets",
            icon: "ri-hotel-line",
            link: "/#",
            stateVariables: isAssets,
            click: function (e) {
                e.preventDefault();
                setIsAssets(!isAssets);
                setIsCurrentState('Assets');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "menuAssetCategories",
                    label: "Asset Classification",
                    link: "/asset-category",
                    parentId: "Assets",
                    isChildItem: true,
                    stateVariables: isAssetCategories,
                    click: function (e) {
                        e.preventDefault();
                        setIsAssetCategories(!isAssetCategories);
                    },
                    childItems: [
                        {
                            id: 1,
                            label: "Categories",
                            link: "/asset-category",
                            parentId: "Assets",
                        },
                        {
                            id: 2,
                            label: "Classes",
                            link: "/asset-class",
                            parentId: "Assets",
                        },
                        {
                            id: 3,
                            label: "Tag",
                            link: "/asset-tag",
                            parentId: "Assets",
                        },
                    ]
                },
                {
                    id: "menuAssets",
                    label: "Assets",
                    link: "/asset",
                    parentId: "Assets",
                },

            ]
        },
        // {
        //     id: "Profiles",
        //     label: "Profil",
        //     icon: "ri-account-circle-line",
        //     link: "/profile",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsCurrentState('Profiles');
        //     },
        //     stateVariables: isProfiles,
        // },

        {
            id: "MasterData",
            label: "Refferal Data",
            icon: "ri-book-2-line",
            link: "/#",
            stateVariables: isMasterData,
            click: function (e) {
                e.preventDefault();
                setIsMasterData(!isMasterData);
                setIsCurrentState("MasterData");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "menuAddressReference",
                    label: "Address Reference",
                    link: "/address",
                    parentId: "Corporates",
                    isChildItem: true,
                    stateVariables: isAddress,
                    click: function (e) {
                        e.preventDefault();
                        setIsAddress(!isAddress);
                    },
                    childItems: [
                        {
                            id: 1,
                            label: "Province",
                            link: "/province",
                            parentId: "Corporates",
                        },
                        {
                            id: 2,
                            label: "City",
                            link: "/city",
                            parentId: "Corporates",
                        },
                        {
                            id: 3,
                            label: "District",
                            link: "/district",
                            parentId: "Corporates",
                        },
                        { id: 4, label: "Village", link: "/village", parentId: "Corporates" },
                    ],
                },

                { id: "MenuAddress", label: "Address", link: "/address", parentId: "MasterData" },
            ],
        },
        {
            id: "Storages",
            label: "Penyimpanan File",
            icon: "bx bx-hdd",
            link: "/storage",
            click: function (e) {
                e.preventDefault();
                setIsCurrentState('Storage');
            },
            stateVariables: isStorages,
        },
        {
            id: "Users",
            label: "Users",
            icon: "ri-account-circle-line",
            link: "/user",
            click: function (e) {
                e.preventDefault();
                setIsCurrentState('Users');
            },
            stateVariables: isUsers,
        },

    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;