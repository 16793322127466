export const PAYMENTMETHOD_LOADING = "PAYMENTMETHOD_LOADING";
export const PAYMENTMETHOD_RESET = "PAYMENTMETHOD_RESET";
export const GET_PAYMENTMETHOD = "GET_PAYMENTMETHOD";
export const GET_PAYMENTMETHOD_SUCCESS = "GET_PAYMENTMETHOD_SUCCESS";
export const GET_PAYMENTMETHOD_FAIL = "GET_PAYMENTMETHOD_FAIL";
export const DELETE_PAYMENTMETHOD = "DELETE_PAYMENTMETHOD"
export const DELETE_PAYMENTMETHOD_SUCCESS = "DELETE_PAYMENTMETHOD_SUCCESS"
export const DELETE_PAYMENTMETHOD_FAIL = "DELETE_PAYMENTMETHOD_FAIL"
export const UPDATE_PAYMENTMETHOD = "UPDATE_PAYMENTMETHOD"
export const UPDATE_PAYMENTMETHOD_SUCCESS = "UPDATE_PAYMENTMETHOD_SUCCESS"
export const UPDATE_PAYMENTMETHOD_FAIL = "UPDATE_PAYMENTMETHOD_FAIL"
export const ADD_NEW_PAYMENTMETHOD = "ADD_NEW_PAYMENTMETHOD"
export const ADD_PAYMENTMETHOD_SUCCESS = "ADD_PAYMENTMETHOD_SUCCESS"
export const ADD_PAYMENTMETHOD_FAIL = "ADD_PAYMENTMETHOD_FAIL"