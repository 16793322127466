export const IDENTITYTYPE_LOADING = "IDENTITYTYPE_LOADING";
export const IDENTITYTYPE_RESET = "IDENTITYTYPE_RESET";
export const GET_IDENTITYTYPE = "GET_IDENTITYTYPE";
export const GET_IDENTITYTYPE_SUCCESS = "GET_IDENTITYTYPE_SUCCESS";
export const GET_IDENTITYTYPE_FAIL = "GET_IDENTITYTYPE_FAIL";
export const DELETE_IDENTITYTYPE = "DELETE_IDENTITYTYPE"
export const DELETE_IDENTITYTYPE_SUCCESS = "DELETE_IDENTITYTYPE_SUCCESS"
export const DELETE_IDENTITYTYPE_FAIL = "DELETE_IDENTITYTYPE_FAIL"
export const UPDATE_IDENTITYTYPE = "UPDATE_IDENTITYTYPE"
export const UPDATE_IDENTITYTYPE_SUCCESS = "UPDATE_IDENTITYTYPE_SUCCESS"
export const UPDATE_IDENTITYTYPE_FAIL = "UPDATE_IDENTITYTYPE_FAIL"
export const ADD_NEW_IDENTITYTYPE = "ADD_NEW_IDENTITYTYPE"
export const ADD_IDENTITYTYPE_SUCCESS = "ADD_IDENTITYTYPE_SUCCESS"
export const ADD_IDENTITYTYPE_FAIL = "ADD_IDENTITYTYPE_FAIL"