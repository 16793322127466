import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
// profile
// import ProfileSaga from "./profile/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import MailSaga from "./mail/saga";

//corporate
import AddressSaga from "./address/saga";
import ProvinceSaga from "./province/saga";
import CitySaga from "./city/saga";
import DistrictSaga from "./district/saga";
import VillageSaga from "./village/saga";
import BankSaga from "./bank/saga";
import IdentitySaga from "./identity/saga";
import IdentityTypeSaga from "./identityType/saga";

import ClientSaga from "./client/saga";

import FileSaga from "./file/saga";
import FolderSaga from "./folder/saga";

//hr
import UserSaga from "./users/saga";

//Asset
import AssetSaga from "./asset/saga";
import AssetTagSaga from "./assetTag/saga";
import AssetFeatureSaga from "./assetFeature/saga";
import AssetGroupSaga from "./assetGroup/saga";
import AssetCategorySaga from "./assetCategory/saga";
import AssetClassSaga from "./assetClass/saga";
import AssetLocationSaga from "./assetLocation/saga";

import PaymentMethodSaga from "./paymentMethod/saga";
import PaymentTermSaga from "./paymentTerm/saga";
import TransactionTaxSaga from "./transactionTax/saga";


//Customer
import CustomerSaga from "./customer/saga";

import PaymentSaga from "./payment/saga";
import DashboardSaga from "./dashboard/saga";
import NewsSaga from "./news/saga";
import BannerSaga from "./banner/saga";
import ContentSaga from "./content/saga";
import assetGroupSaga from "./assetGroup/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(DashboardSaga),
    fork(ClientSaga),
    fork(AddressSaga),
    fork(ProvinceSaga),
    fork(CitySaga),
    fork(DistrictSaga),
    fork(VillageSaga),
    fork(BankSaga),
    fork(IdentitySaga),
    fork(IdentityTypeSaga),
    fork(AssetLocationSaga),

    fork(MailSaga),
    
    fork(FolderSaga),
    fork(FileSaga),

    fork(UserSaga),
    fork(AssetSaga),
    fork(AssetTagSaga),
    fork(AssetFeatureSaga),
    fork(AssetCategorySaga),
    fork(AssetClassSaga),
    fork(assetGroupSaga),
    fork(PaymentMethodSaga),
    fork(PaymentTermSaga),
    fork(TransactionTaxSaga),

    fork(CustomerSaga),

    fork(PaymentSaga),
    fork(NewsSaga),
    fork(BannerSaga),
    fork(ContentSaga)
  ]);
}
