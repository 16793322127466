import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_TRANSACTIONTAX,
  DELETE_TRANSACTIONTAX,
  UPDATE_TRANSACTIONTAX,
  ADD_NEW_TRANSACTIONTAX,
} from "./actionType";

import {
  getTransactionTaxSuccess,
  getTransactionTaxFail,
  deleteTransactionTaxSuccess,
  deleteTransactionTaxFail,
  updateTransactionTaxSuccess,
  updateTransactionTaxFail,
  addTransactionTaxSuccess,
  addTransactionTaxFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveTransactionTaxApi,
  getTransactionTaxApi,
  updateTransactionTaxApi,
  deleteTransactionTaxApi,
  addNewTransactionTaxApi
} from "../../helpers/backend_helper";

function* getTransactionTax({ payload: transactionTax }) {
  try {
    const response = yield call(getTransactionTaxApi, transactionTax);
    yield put(getTransactionTaxSuccess(GET_TRANSACTIONTAX, response.data));
  } catch (error) {
    yield put(getTransactionTaxFail(GET_TRANSACTIONTAX, error));
  }
}

function* onUpdateTransactionTax({ payload: transactionTax }) {
  try {
    if (transactionTax.setApprove) {
      const response = yield call(approveTransactionTaxApi, transactionTax);
      yield put(updateTransactionTaxSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateTransactionTaxApi, transactionTax);
      yield put(updateTransactionTaxSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateTransactionTaxFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteTransactionTax({ payload: transactionTax }) {
  try {
    const response = yield call(deleteTransactionTaxApi, transactionTax);
    yield put(deleteTransactionTaxSuccess({ transactionTax, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteTransactionTaxFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewTransactionTax({ payload: transactionTax }) {
  try {
    const response = yield call(addNewTransactionTaxApi, transactionTax);
    yield put(addTransactionTaxSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addTransactionTaxFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetTransactionTax() {
  yield takeEvery(GET_TRANSACTIONTAX, getTransactionTax);
}

export function* watchUpdateTransactionTax() {
  yield takeEvery(UPDATE_TRANSACTIONTAX, onUpdateTransactionTax);
}

export function* watchDeleteTransactionTax() {
  yield takeEvery(DELETE_TRANSACTIONTAX, onDeleteTransactionTax);
}

export function* watchAddNewTransactionTax() {
  yield takeEvery(ADD_NEW_TRANSACTIONTAX, onAddNewTransactionTax);
}

function* transactionTaxSaga() {
  yield all([
    fork(watchGetTransactionTax),
    fork(watchDeleteTransactionTax),
    fork(watchUpdateTransactionTax),
    fork(watchAddNewTransactionTax)
  ]);
}

export default transactionTaxSaga;
