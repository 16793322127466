import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  ADD_NEW_CUSTOMER,
} from "./actionType";

import {
  getCustomerSuccess,
  getCustomerFail,
  deleteCustomerSuccess,
  deleteCustomerFail,
  updateCustomerSuccess,
  updateCustomerFail,
  addCustomerSuccess,
  addCustomerFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveCustomerApi,
  getCustomerApi,
  updateCustomerApi,
  deleteCustomerApi,
  addNewCustomerApi
} from "../../helpers/backend_helper";

function* getCustomer({ payload: customer }) {
  try {
    const response = yield call(getCustomerApi, customer);
    yield put(getCustomerSuccess(GET_CUSTOMER, response.data));
  } catch (error) {
    yield put(getCustomerFail(GET_CUSTOMER, error));
  }
}

function* onUpdateCustomer({ payload: customer }) {
  try {
    if (customer.setApprove) {
      const response = yield call(approveCustomerApi, customer);
      yield put(updateCustomerSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateCustomerApi, customer);
      yield put(updateCustomerSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateCustomerFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomerApi, customer);
    yield put(deleteCustomerSuccess({ customer, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteCustomerFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewCustomer({ payload: customer }) {
  try {
    const response = yield call(addNewCustomerApi, customer);
    yield put(addCustomerSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addCustomerFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetCustomer() {
  yield takeEvery(GET_CUSTOMER, getCustomer);
}

export function* watchUpdateCustomer() {
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
}

export function* watchDeleteCustomer() {
  yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer);
}

export function* watchAddNewCustomer() {
  yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
}

function* customerSaga() {
  yield all([
    fork(watchGetCustomer),
    fork(watchDeleteCustomer),
    fork(watchUpdateCustomer),
    fork(watchAddNewCustomer)
  ]);
}

export default customerSaga;
