import {
  GET_ASSETGROUP,
  GET_ASSETGROUP_SUCCESS,
  GET_ASSETGROUP_FAIL,
  DELETE_ASSETGROUP,
  DELETE_ASSETGROUP_SUCCESS,
  DELETE_ASSETGROUP_FAIL,
  UPDATE_ASSETGROUP,
  UPDATE_ASSETGROUP_SUCCESS,
  UPDATE_ASSETGROUP_FAIL,
  ADD_NEW_ASSETGROUP,
  ADD_ASSETGROUP_SUCCESS,
  ADD_ASSETGROUP_FAIL,
  ASSETGROUP_LOADING,
} from "./actionType";

export const getAssetGroupSuccess = (actionType, data) => ({
  type: GET_ASSETGROUP_SUCCESS,
  payload: { actionType, data },
});

export const getAssetGroupFail = (actionType, error) => ({
  type: GET_ASSETGROUP_FAIL,
  payload: { actionType, error },
});

export const getAssetGroup = data => ({
  type: GET_ASSETGROUP,
  payload: data,
});

export const getAssetGroupLoading = () => ({
  type: ASSETGROUP_LOADING
});

export const deleteAssetGroup = data => ({
  type: DELETE_ASSETGROUP,
  payload: data,
});

export const deleteAssetGroupSuccess = data => ({
  type: DELETE_ASSETGROUP_SUCCESS,
  payload: data,
});

export const deleteAssetGroupFail = error => ({
  type: DELETE_ASSETGROUP_FAIL,
  payload: error,
});

export const updateAssetGroup = data => ({
  type: UPDATE_ASSETGROUP,
  payload: data,
});

export const updateAssetGroupFail = error => ({
  type: UPDATE_ASSETGROUP_FAIL,
  payload: error,
});

export const updateAssetGroupSuccess = data => ({
  type: UPDATE_ASSETGROUP_SUCCESS,
  payload: data,
});

export const addNewAssetGroup = data => ({
  type: ADD_NEW_ASSETGROUP,
  payload: data,
});

export const addAssetGroupSuccess = data => ({
  type: ADD_ASSETGROUP_SUCCESS,
  payload: data,
});

export const addAssetGroupFail = error => ({
  type: ADD_ASSETGROUP_FAIL,
  payload: error,
});