import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_PAYMENT,
  DELETE_PAYMENT,
  UPDATE_PAYMENT,
  ADD_NEW_PAYMENT,
} from "./actionType";

import {
  getPaymentSuccess,
  getPaymentFail,
  deletePaymentSuccess,
  deletePaymentFail,
  updatePaymentSuccess,
  updatePaymentFail,
  addPaymentSuccess,
  addPaymentFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approvePaymentApi,
  getPaymentApi,
  updatePaymentApi,
  deletePaymentApi,
  addNewPaymentApi
} from "../../helpers/backend_helper";

function* getPayment({ payload: payment }) {
  try {
    const response = yield call(getPaymentApi, payment);
    yield put(getPaymentSuccess(GET_PAYMENT, response.data));
  } catch (error) {
    yield put(getPaymentFail(GET_PAYMENT, error));
  }
}

function* onUpdatePayment({ payload: payment }) {
  try {
    if (payment.setApprove) {
      const response = yield call(approvePaymentApi, payment);
      yield put(updatePaymentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updatePaymentApi, payment);
      yield put(updatePaymentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updatePaymentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeletePayment({ payload: payment }) {
  try {
    const response = yield call(deletePaymentApi, payment);
    yield put(deletePaymentSuccess({ payment, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePaymentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewPayment({ payload: payment }) {
  try {
    const response = yield call(addNewPaymentApi, payment);
    yield put(addPaymentSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addPaymentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetPayment() {
  yield takeEvery(GET_PAYMENT, getPayment);
}

export function* watchUpdatePayment() {
  yield takeEvery(UPDATE_PAYMENT, onUpdatePayment);
}

export function* watchDeletePayment() {
  yield takeEvery(DELETE_PAYMENT, onDeletePayment);
}

export function* watchAddNewPayment() {
  yield takeEvery(ADD_NEW_PAYMENT, onAddNewPayment);
}

function* paymentSaga() {
  yield all([
    fork(watchGetPayment),
    fork(watchDeletePayment),
    fork(watchUpdatePayment),
    fork(watchAddNewPayment)
  ]);
}

export default paymentSaga;
