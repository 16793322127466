import {
  ADDRESS_LOADING,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAIL,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAIL,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAIL,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAIL
} from "./actionType";

const INIT_STATE = {
  addresses: [],
  error: {},
};

const Address = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ADDRESS:
          return {
            ...state,
            addresses: action.payload.data,
            isAddressCreated: false,
            isAddressSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ADDRESS_FAIL:
      switch (action.payload.actionType) {
        case GET_ADDRESS_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAddressCreated: false,
            isAddressSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ADDRESS: {
      return {
        ...state,
        isAddressCreated: false,
        loading: true,
      };
    }

    case ADDRESS_LOADING: {
      return {
        ...state,
        isAddressCreated: false,
        loading: true,
      };
    }

    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        isAddressCreated: true,
        loading: false,
        addresses: [...state.addresses, action.payload.data],
      };

    case ADD_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: state.addresses.filter(
          address => address.id.toString() !== action.payload.address.id.toString()
        ),
      };

    case DELETE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: state.addresses.map(address =>
          address.id.toString() === action.payload.data.id.toString() ? { ...address, ...action.payload.data } : address
        ),
      };

    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Address;