import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_MAIL,
  DELETE_MAIL,
  UPDATE_MAIL,
  ADD_NEW_MAIL,
} from "./actionType";

import {
  getMailSuccess,
  getMailFail,
  deleteMailSuccess,
  deleteMailFail,
  updateMailSuccess,
  updateMailFail,
  addMailSuccess,
  addMailFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveMailApi,
  getMailApi,
  updateMailApi,
  deleteMailApi,
  addNewMailApi
} from "../../helpers/backend_helper";

function* getMail({ payload: mail }) {
  try {
    const response = yield call(getMailApi, mail);
    yield put(getMailSuccess(GET_MAIL, response.data));
  } catch (error) {
    yield put(getMailFail(GET_MAIL, error));
  }
}

function* onUpdateMail({ payload: mail }) {
  try {
    if (mail.setApprove) {
      const response = yield call(approveMailApi, mail);
      yield put(updateMailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateMailApi, mail);
      yield put(updateMailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateMailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteMail({ payload: mail }) {
  try {
    const response = yield call(deleteMailApi, mail);
    yield put(deleteMailSuccess({ mail, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteMailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewMail({ payload: mail }) {
  try {
    const response = yield call(addNewMailApi, mail);
    yield put(addMailSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addMailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetMail() {
  yield takeEvery(GET_MAIL, getMail);
}

export function* watchUpdateMail() {
  yield takeEvery(UPDATE_MAIL, onUpdateMail);
}

export function* watchDeleteMail() {
  yield takeEvery(DELETE_MAIL, onDeleteMail);
}

export function* watchAddNewMail() {
  yield takeEvery(ADD_NEW_MAIL, onAddNewMail);
}

function* mailSaga() {
  yield all([
    fork(watchGetMail),
    fork(watchDeleteMail),
    fork(watchUpdateMail),
    fork(watchAddNewMail)
  ]);
}

export default mailSaga;
