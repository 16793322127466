import {
  GET_PROVINCE,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAIL,
  DELETE_PROVINCE,
  DELETE_PROVINCE_SUCCESS,
  DELETE_PROVINCE_FAIL,
  UPDATE_PROVINCE,
  UPDATE_PROVINCE_SUCCESS,
  UPDATE_PROVINCE_FAIL,
  ADD_NEW_PROVINCE,
  ADD_PROVINCE_SUCCESS,
  ADD_PROVINCE_FAIL,
  PROVINCE_LOADING,
} from "./actionType";

export const getProvinceSuccess = (actionType, data) => ({
  type: GET_PROVINCE_SUCCESS,
  payload: { actionType, data },
});

export const getProvinceFail = (actionType, error) => ({
  type: GET_PROVINCE_FAIL,
  payload: { actionType, error },
});

export const getProvince = data => ({
  type: GET_PROVINCE,
  payload: data,
});

export const getProvinceLoading = () => ({
  type: PROVINCE_LOADING
});

export const deleteProvince = data => ({
  type: DELETE_PROVINCE,
  payload: data,
});

export const deleteProvinceSuccess = data => ({
  type: DELETE_PROVINCE_SUCCESS,
  payload: data,
});

export const deleteProvinceFail = error => ({
  type: DELETE_PROVINCE_FAIL,
  payload: error,
});

export const updateProvince = data => ({
  type: UPDATE_PROVINCE,
  payload: data,
});

export const updateProvinceFail = error => ({
  type: UPDATE_PROVINCE_FAIL,
  payload: error,
});

export const updateProvinceSuccess = data => ({
  type: UPDATE_PROVINCE_SUCCESS,
  payload: data,
});

export const addNewProvince = data => ({
  type: ADD_NEW_PROVINCE,
  payload: data,
});

export const addProvinceSuccess = data => ({
  type: ADD_PROVINCE_SUCCESS,
  payload: data,
});

export const addProvinceFail = error => ({
  type: ADD_PROVINCE_FAIL,
  payload: error,
});