import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ASSETGROUP,
  DELETE_ASSETGROUP,
  UPDATE_ASSETGROUP,
  ADD_NEW_ASSETGROUP,
} from "./actionType";

import {
  getAssetGroupSuccess,
  getAssetGroupFail,
  deleteAssetGroupSuccess,
  deleteAssetGroupFail,
  updateAssetGroupSuccess,
  updateAssetGroupFail,
  addAssetGroupSuccess,
  addAssetGroupFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getAssetGroupApi,
  updateAssetGroupApi,
  deleteAssetGroupApi,
  addNewAssetGroupApi
} from "../../helpers/backend_helper";

function* getAssetGroup({ payload: assetGroup }) {
  try {
    const response = yield call(getAssetGroupApi, assetGroup);
    yield put(getAssetGroupSuccess(GET_ASSETGROUP, response.data));
  } catch (error) {
    yield put(getAssetGroupFail(GET_ASSETGROUP, error));
  }
}

function* onUpdateAssetGroup({ payload: assetGroup }) {
  try {
    const response = yield call(updateAssetGroupApi, assetGroup);
    yield put(updateAssetGroupSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateAssetGroupFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAssetGroup({ payload: assetGroup }) {
  try {
    const response = yield call(deleteAssetGroupApi, assetGroup);
    yield put(deleteAssetGroupSuccess({ assetGroup, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAssetGroupFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAssetGroup({ payload: assetGroup }) {
  try {
    const response = yield call(addNewAssetGroupApi, assetGroup);
    yield put(addAssetGroupSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAssetGroupFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAssetGroup() {
  yield takeEvery(GET_ASSETGROUP, getAssetGroup);
}

export function* watchUpdateAssetGroup() {
  yield takeEvery(UPDATE_ASSETGROUP, onUpdateAssetGroup);
}

export function* watchDeleteAssetGroup() {
  yield takeEvery(DELETE_ASSETGROUP, onDeleteAssetGroup);
}

export function* watchAddNewAssetGroup() {
  yield takeEvery(ADD_NEW_ASSETGROUP, onAddNewAssetGroup);
}

function* assetGroupSaga() {
  yield all([
    fork(watchGetAssetGroup),
    fork(watchDeleteAssetGroup),
    fork(watchUpdateAssetGroup),
    fork(watchAddNewAssetGroup)
  ]);
}

export default assetGroupSaga;
