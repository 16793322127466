import {
  GET_PAYMENTTERM,
  GET_PAYMENTTERM_SUCCESS,
  GET_PAYMENTTERM_FAIL,
  DELETE_PAYMENTTERM,
  DELETE_PAYMENTTERM_SUCCESS,
  DELETE_PAYMENTTERM_FAIL,
  UPDATE_PAYMENTTERM,
  UPDATE_PAYMENTTERM_SUCCESS,
  UPDATE_PAYMENTTERM_FAIL,
  ADD_NEW_PAYMENTTERM,
  ADD_PAYMENTTERM_SUCCESS,
  ADD_PAYMENTTERM_FAIL,
  PAYMENTTERM_LOADING,
  PAYMENTTERM_RESET,
} from "./actionType";

export const resetPaymentTermState = (actionType) => ({
  type: PAYMENTTERM_RESET,
  payload: { actionType },
});

export const getPaymentTermSuccess = (actionType, data) => ({
  type: GET_PAYMENTTERM_SUCCESS,
  payload: { actionType, data },
});

export const getPaymentTermFail = (actionType, error) => ({
  type: GET_PAYMENTTERM_FAIL,
  payload: { actionType, error },
});

export const getPaymentTerm = data => ({
  type: GET_PAYMENTTERM,
  payload: data,
});

export const getPaymentTermLoading = () => ({
  type: PAYMENTTERM_LOADING
});

export const deletePaymentTerm = data => ({
  type: DELETE_PAYMENTTERM,
  payload: data,
});

export const deletePaymentTermSuccess = data => ({
  type: DELETE_PAYMENTTERM_SUCCESS,
  payload: data,
});

export const deletePaymentTermFail = error => ({
  type: DELETE_PAYMENTTERM_FAIL,
  payload: error,
});

export const updatePaymentTerm = data => ({
  type: UPDATE_PAYMENTTERM,
  payload: data,
});

export const updatePaymentTermFail = error => ({
  type: UPDATE_PAYMENTTERM_FAIL,
  payload: error,
});

export const updatePaymentTermSuccess = data => ({
  type: UPDATE_PAYMENTTERM_SUCCESS,
  payload: data,
});

export const addNewPaymentTerm = data => ({
  type: ADD_NEW_PAYMENTTERM,
  payload: data,
});

export const addPaymentTermSuccess = data => ({
  type: ADD_PAYMENTTERM_SUCCESS,
  payload: data,
});

export const addPaymentTermFail = error => ({
  type: ADD_PAYMENTTERM_FAIL,
  payload: error,
});