import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_CITY,
  DELETE_CITY,
  UPDATE_CITY,
  ADD_NEW_CITY,
} from "./actionType";

import {
  getCitySuccess,
  getCityFail,
  deleteCitySuccess,
  deleteCityFail,
  updateCitySuccess,
  updateCityFail,
  addCitySuccess,
  addCityFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getCityApi,
  updateCityApi,
  deleteCityApi,
  addNewCityApi
} from "../../helpers/backend_helper";

function* getCity({ payload: city }) {
  try {
    const response = yield call(getCityApi, city);
    yield put(getCitySuccess(GET_CITY, response.data));
  } catch (error) {
    yield put(getCityFail(GET_CITY, error));
  }
}

function* onUpdateCity({ payload: city }) {
  try {
    const response = yield call(updateCityApi, city);
    yield put(updateCitySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateCityFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteCity({ payload: city }) {
  try {
    const response = yield call(deleteCityApi, city);
    yield put(deleteCitySuccess({ city, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteCityFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewCity({ payload: city }) {
  try {
    const response = yield call(addNewCityApi, city);
    yield put(addCitySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addCityFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetCity() {
  yield takeEvery(GET_CITY, getCity);
}

export function* watchUpdateCity() {
  yield takeEvery(UPDATE_CITY, onUpdateCity);
}

export function* watchDeleteCity() {
  yield takeEvery(DELETE_CITY, onDeleteCity);
}

export function* watchAddNewCity() {
  yield takeEvery(ADD_NEW_CITY, onAddNewCity);
}

function* citySaga() {
  yield all([
    fork(watchGetCity),
    fork(watchDeleteCity),
    fork(watchUpdateCity),
    fork(watchAddNewCity)
  ]);
}

export default citySaga;
