import {
  TRANSACTIONTAX_LOADING,
  GET_TRANSACTIONTAX,
  GET_TRANSACTIONTAX_SUCCESS,
  GET_TRANSACTIONTAX_FAIL,
  ADD_TRANSACTIONTAX_SUCCESS,
  ADD_TRANSACTIONTAX_FAIL,
  DELETE_TRANSACTIONTAX_SUCCESS,
  DELETE_TRANSACTIONTAX_FAIL,
  UPDATE_TRANSACTIONTAX_SUCCESS,
  UPDATE_TRANSACTIONTAX_FAIL,
  TRANSACTIONTAX_RESET
} from "./actionType";

const INIT_STATE = {
  transactionTaxs: [],
  error: {},
};

const TransactionTax = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONTAX_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TRANSACTIONTAX:
          return {
            ...state,
            transactionTaxs: action.payload.data,
            isTransactionTaxCreated: false,
            isTransactionTaxSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_TRANSACTIONTAX_FAIL:
      switch (action.payload.actionType) {
        case GET_TRANSACTIONTAX_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isTransactionTaxCreated: false,
            isTransactionTaxSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_TRANSACTIONTAX: {
      return {
        ...state,
        isTransactionTaxCreated: false,
        loading: true,
      };
    }

    case TRANSACTIONTAX_LOADING: {
      return {
        ...state,
        isTransactionTaxCreated: false,
        loading: true,
      };
    }

    case ADD_TRANSACTIONTAX_SUCCESS:
      return {
        ...state,
        isTransactionTaxCreated: true,
        loading: false,
        transactionTaxs: [...state.transactionTaxs, action.payload.data],
      };

    case ADD_TRANSACTIONTAX_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_TRANSACTIONTAX_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionTaxs: state.transactionTaxs.filter(
          transactionTax => transactionTax.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_TRANSACTIONTAX_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_TRANSACTIONTAX_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionTaxs: state.transactionTaxs.map(transactionTax =>
          transactionTax.id.toString() === action.payload.data.id.toString() ? { ...transactionTax, ...action.payload.data } : transactionTax
        ),
      };

    case UPDATE_TRANSACTIONTAX_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TRANSACTIONTAX_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default TransactionTax;