import {
  CUSTOMER_LOADING,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  CUSTOMER_RESET,
  CUSTOMER_RESET_CREATE
} from "./actionType";

const INIT_STATE = {
  customers: [],
  customerAdded: {},
  error: {},
};

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CUSTOMER:
          return {
            ...state,
            customers: action.payload.data,
            isCustomerCreated: false,
            isCustomerSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_CUSTOMER_FAIL:
      switch (action.payload.actionType) {
        case GET_CUSTOMER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isCustomerCreated: false,
            isCustomerSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_CUSTOMER: {
      return {
        ...state,
        isCustomerCreated: false,
        loading: true,
      };
    }

    case CUSTOMER_LOADING: {
      return {
        ...state,
        isCustomerCreated: false,
        loading: true,
      };
    }

    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCustomerCreated: true,
        loading: false,
        customers: [...state.customers, action.payload.data],
        customerAdded: action.payload.data,
      };

    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: state.customers.filter(
          customer => customer.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: state.customers.map(customer =>
          customer.id.toString() === action.payload.data.id.toString() ? { ...customer, ...action.payload.data } : customer
        ),
      };

    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CUSTOMER_RESET_CREATE:
      return {
        ...state,
        isCustomerCreated: false,
      };

    case CUSTOMER_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Customer;