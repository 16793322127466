export const VILLAGE_LOADING = "VILLAGE_LOADING";
export const VILLAGE_RESET = "VILLAGE_RESET";
export const GET_VILLAGE = "GET_VILLAGE";
export const GET_VILLAGE_SUCCESS = "GET_VILLAGE_SUCCESS";
export const GET_VILLAGE_FAIL = "GET_VILLAGE_FAIL";
export const DELETE_VILLAGE = "DELETE_VILLAGE"
export const DELETE_VILLAGE_SUCCESS = "DELETE_VILLAGE_SUCCESS"
export const DELETE_VILLAGE_FAIL = "DELETE_VILLAGE_FAIL"
export const UPDATE_VILLAGE = "UPDATE_VILLAGE"
export const UPDATE_VILLAGE_SUCCESS = "UPDATE_VILLAGE_SUCCESS"
export const UPDATE_VILLAGE_FAIL = "UPDATE_VILLAGE_FAIL"
export const ADD_NEW_VILLAGE = "ADD_NEW_VILLAGE"
export const ADD_VILLAGE_SUCCESS = "ADD_VILLAGE_SUCCESS"
export const ADD_VILLAGE_FAIL = "ADD_VILLAGE_FAIL"