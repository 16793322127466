export const TRANSACTIONTAX_LOADING = "TRANSACTIONTAX_LOADING";
export const TRANSACTIONTAX_RESET = "TRANSACTIONTAX_RESET";
export const GET_TRANSACTIONTAX = "GET_TRANSACTIONTAX";
export const GET_TRANSACTIONTAX_SUCCESS = "GET_TRANSACTIONTAX_SUCCESS";
export const GET_TRANSACTIONTAX_FAIL = "GET_TRANSACTIONTAX_FAIL";
export const DELETE_TRANSACTIONTAX = "DELETE_TRANSACTIONTAX"
export const DELETE_TRANSACTIONTAX_SUCCESS = "DELETE_TRANSACTIONTAX_SUCCESS"
export const DELETE_TRANSACTIONTAX_FAIL = "DELETE_TRANSACTIONTAX_FAIL"
export const UPDATE_TRANSACTIONTAX = "UPDATE_TRANSACTIONTAX"
export const UPDATE_TRANSACTIONTAX_SUCCESS = "UPDATE_TRANSACTIONTAX_SUCCESS"
export const UPDATE_TRANSACTIONTAX_FAIL = "UPDATE_TRANSACTIONTAX_FAIL"
export const ADD_NEW_TRANSACTIONTAX = "ADD_NEW_TRANSACTIONTAX"
export const ADD_TRANSACTIONTAX_SUCCESS = "ADD_TRANSACTIONTAX_SUCCESS"
export const ADD_TRANSACTIONTAX_FAIL = "ADD_TRANSACTIONTAX_FAIL"