import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  ADD_NEW_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  CUSTOMER_LOADING,
  CUSTOMER_RESET,
  CUSTOMER_RESET_CREATE
} from "./actionType";

export const resetCustomerState = (actionType) => ({
  type: CUSTOMER_RESET,
  payload: { actionType },
});


export const resetCustomerCreateState = (actionType) => ({
  type: CUSTOMER_RESET_CREATE,
  payload: { actionType }
});

export const getCustomerSuccess = (actionType, data) => ({
  type: GET_CUSTOMER_SUCCESS,
  payload: { actionType, data },
});

export const getCustomerFail = (actionType, error) => ({
  type: GET_CUSTOMER_FAIL,
  payload: { actionType, error },
});

export const getCustomer = data => ({
  type: GET_CUSTOMER,
  payload: data,
});

export const getCustomerLoading = () => ({
  type: CUSTOMER_LOADING
});

export const deleteCustomer = data => ({
  type: DELETE_CUSTOMER,
  payload: data,
});

export const deleteCustomerSuccess = data => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: data,
});

export const deleteCustomerFail = error => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error,
});

export const updateCustomer = data => ({
  type: UPDATE_CUSTOMER,
  payload: data,
});

export const updateCustomerFail = error => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error,
});

export const updateCustomerSuccess = data => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: data,
});

export const addNewCustomer = data => ({
  type: ADD_NEW_CUSTOMER,
  payload: data,
});

export const addCustomerSuccess = data => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: data,
});

export const addCustomerFail = error => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error,
});