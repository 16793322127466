export const MAIL_LOADING = "MAIL_LOADING";
export const MAIL_RESET = "MAIL_RESET";
export const GET_MAIL = "GET_MAIL";
export const GET_MAIL_SUCCESS = "GET_MAIL_SUCCESS";
export const GET_MAIL_FAIL = "GET_MAIL_FAIL";
export const DELETE_MAIL = "DELETE_MAIL"
export const DELETE_MAIL_SUCCESS = "DELETE_MAIL_SUCCESS"
export const DELETE_MAIL_FAIL = "DELETE_MAIL_FAIL"
export const UPDATE_MAIL = "UPDATE_MAIL"
export const UPDATE_MAIL_SUCCESS = "UPDATE_MAIL_SUCCESS"
export const UPDATE_MAIL_FAIL = "UPDATE_MAIL_FAIL"
export const ADD_NEW_MAIL = "ADD_NEW_MAIL"
export const ADD_MAIL_SUCCESS = "ADD_MAIL_SUCCESS"
export const ADD_MAIL_FAIL = "ADD_MAIL_FAIL"
export const MAIL_RESET_CREATE = "MAIL_RESET_CREATE"